import React, { useEffect } from 'react'
import Buy from './Buy'
import { Card } from 'antd'

function Trade() {

  useEffect(() => {
    // this changes the scrolling behavior to "smooth"
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <div className='mt-5 mb-5'>
        <Card className='fathom-finance-card col-lg-8 mx-auto mt-3'>
          <Buy />
        </Card>

        {/* <div className='col-lg-8 mx-auto mt-5'>
          <MassTokenDistributionChart />
        </div> */}
    </div>
  )
}

export default Trade