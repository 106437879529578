import { getChainNetworkByChainId } from '../../Blockchain/utils/chainList'
import { ethers, utils } from 'ethers'
import { weatherConfigs } from '../configs/weather.configs'
import { commonConfigs } from '../configs/common.configs'

export const getWeb3PRovider = () => {
    const defaultChainId = commonConfigs.chainId
    const web3configs = getChainNetworkByChainId(defaultChainId)
    //initiate the web3 instance
    const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
      name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
    })
    return web3
}

// weather read functions

export const getWeatherPercentage = async () => {
    let percentage = 0
    try {
      const provider = getWeb3PRovider()
      const weatherContractABI = JSON.parse(weatherConfigs.weatherContractABI)
      const weatherContractAddress = weatherConfigs.weatherContractAddress
      const weatherContractInstance = new ethers.Contract(weatherContractAddress, weatherContractABI, provider)
  
      const percentageResponse = await weatherContractInstance.percentage()
      const percentageFormatted = parseInt(percentageResponse.toString())
      percentage = percentageFormatted

      return percentage
    } catch (error) {
      console.log("ERROR while fetching weather percentage : ", error)
      return percentage
    }
}
  
export const getNextBuyBackAt = async () => {
    let nextBuyBackTime = 0.0
    try {
      const provider = getWeb3PRovider()
      const weatherContractABI = JSON.parse(weatherConfigs.weatherContractABI)
      const weatherContractAddress = weatherConfigs.weatherContractAddress
      const weatherContractInstance = new ethers.Contract(weatherContractAddress, weatherContractABI, provider)
  
      const buyBackResponse = await weatherContractInstance.nextBuyBackAt()
      const buyBackFormatted = buyBackResponse.toString()
      nextBuyBackTime = buyBackFormatted

      return nextBuyBackTime
    } catch (error) {
      console.log("ERROR while fetching next buy back time : ", error)
      return nextBuyBackTime
    }
}

export const getTotalFathomPurchased = async () => {
    let totalFathomPurchased = 0.0
    try {
      const provider = getWeb3PRovider()
      const weatherContractABI = JSON.parse(weatherConfigs.weatherContractABI)
      const weatherContractAddress = weatherConfigs.weatherContractAddress
      const weatherContractInstance = new ethers.Contract(weatherContractAddress, weatherContractABI, provider)
  
      const fathomPurchasedResponse = await weatherContractInstance.totalFathomPurchased()
      const fathomPurchasedFormatted = utils.formatEther(fathomPurchasedResponse.toString())
      totalFathomPurchased = fathomPurchasedFormatted

      return totalFathomPurchased
    } catch (error) {
      console.log("ERROR while fetching total fathom purchased : ", error)
      return totalFathomPurchased
    }
}

export const getIsBuyBackFromBnb = async () => {
    let isBuyBackFromBnb = false
    try {
      const provider = getWeb3PRovider()
      const weatherContractABI = JSON.parse(weatherConfigs.weatherContractABI)
      const weatherContractAddress = weatherConfigs.weatherContractAddress
      const weatherContractInstance = new ethers.Contract(weatherContractAddress, weatherContractABI, provider)
  
      const fathomPurchasedResponse = await weatherContractInstance.isBuyBackFromBnb()
      isBuyBackFromBnb = fathomPurchasedResponse

      return isBuyBackFromBnb
    } catch (error) {
      console.log("ERROR while fetching is buy back from bnb : ", error)
      return isBuyBackFromBnb
    }
}