import React from 'react'
import {Routes, Route} from 'react-router-dom'

// Pages
import Home from './Home/Home'
// import Presale from './Presale/Presale'
// import StakingPage from './Staking/StakingPage'
// import ShillingPage from './Shilling/ShillingPage'
import Trade from './Trade/Trade'
import Admin from './Admin/Admin'
import ShillingPageV2 from './Shilling/ShillingPageV2'
import StakingPageV2 from './Staking/StakingPageV2'
import Airdrop from './Airdrop/Airdrop'

function Pages() {
  return (
    <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route path="/presale" exact element={<Presale />} /> */}
        {/* <Route path="/staking" exact element={<StakingPage />} /> */}
        <Route path="/stakingV2" exact element={<StakingPageV2 />} />
        {/* <Route path="/shilling" exact element={<ShillingPage />} /> */}
        <Route path="/shillingV2" exact element={<ShillingPageV2 />} />
        <Route path="/trade" exact element={<Trade />} />
        <Route path="/admin" exact element={<Admin />} />
        <Route path="/airdrop" exact element={<Airdrop />} />
    </Routes>
  )
}

export default Pages