import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import { HashScroll } from "react-hash-scroll";
import { getNumbersOfShillersHoldingWorkTokens, getUserWorkTokenBalance, getTotalWorkTokenAmount } from '../../../Blockchain/services/shilling.service';
import NumberFormat from 'react-number-format';
import { useAccount } from 'wagmi';

function Work() {

    const { address: account } = useAccount()

    const [numbersOfWorkTokenHolders, setNumbersOfWorkTokenHolders] = useState(0.0)
    const [isWorkHoldersLoading, setIsWorkHoldersLoading] = useState(false)

    const [userWorkTokensLoading, setUserWorkTokensLoading] = useState(false)
    const [userWorkTokens, setUserWorkTokens] = useState(0.0)

    const [isTotalSupplyLoading, setIsTotalSupplyLoading] = useState(false)
    const [workTotalSupply, setWorkTotalSupply] = useState(0.0)
    const [workTokenPercentage, setWorkTokenPercentage] = useState(0.0)

    const fetchNumbersOfWorkTokenHolders = async () => {
        setIsWorkHoldersLoading(true)
        const workHoldersCount = await getNumbersOfShillersHoldingWorkTokens()
        setNumbersOfWorkTokenHolders(workHoldersCount)
        setIsWorkHoldersLoading(false)
    }
    const fetchMyCurrentWork = async () => {
        setUserWorkTokensLoading(true)
        const tokenBalance = await getUserWorkTokenBalance(account)
        setUserWorkTokens(tokenBalance)
        setUserWorkTokensLoading(false)
    }

    const fetchTotalSupply = async () => {
        setIsTotalSupplyLoading(true)
        const tokenBalance = await getTotalWorkTokenAmount(account)
        setWorkTotalSupply(tokenBalance)
        setIsTotalSupplyLoading(false)
    }

    useEffect(() => {
        fetchNumbersOfWorkTokenHolders()
        fetchTotalSupply()
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchMyCurrentWork()
        // eslint-disable-next-line
    }, [account])

    useEffect(() => {
        if (workTotalSupply && userWorkTokens) {
            const userTokenPercentage = (parseFloat(userWorkTokens) / parseFloat(workTotalSupply)) * 100
            setWorkTokenPercentage(userTokenPercentage)
        } else {
            setWorkTokenPercentage(0.0)
        }
    }, [workTotalSupply, userWorkTokens])

    return (
        <div>
            <Card className='mt-4 transparent'>
                <HashScroll hash="#work" position='center'>
                    <h3 className='text-center text-uppercase'>Work</h3>
                </HashScroll>
            </Card>

            {/* <Card className='fathom-finance-card col-lg-9 mx-auto mb-3'>
                <div className='fw-bold text-center'>
                    <span className='card-title'>123,456,798 $Fathom Valued at $123,4567 Released in </span>
                    &nbsp;
                    <span className='card-title'>
                        <UpcomingCountdownMini />
                    </span>
                </div>
            </Card> */}

            <Row className='mb-3'>
                <Col lg="6" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Work Holders</span>}>

                        {
                            isWorkHoldersLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <>
                                    <span className='primary-text card-inner-text'>Current: </span>
                                    <span className='card-inner-text'>
                                        <NumberFormat
                                            style={{ fontSize: '18px' }}
                                            value={numbersOfWorkTokenHolders}
                                            thousandSeparator={true}
                                            decimalScale={3}
                                            displayType={'text'}
                                        />
                                    </span>
                                </>
                            )
                        }
                    </Card>
                </Col>

                <Col lg="6" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>My Current Work</span>}>
                        <span className='primary-text card-inner-text'>Current: </span> 
                        <span className='card-inner-text'>
                            {
                                userWorkTokensLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userWorkTokens}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>% of Total: </span> 
                        <span className='card-inner-text'>
                            {
                                isTotalSupplyLoading || userWorkTokensLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={workTokenPercentage}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                {/* <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>My Previous Work</span>}>
                        <span className='primary-text card-inner-text'>Total: </span> <span className='card-inner-text'>123,867</span>
                        <br />
                        <span className='primary-text card-inner-text'>% of Total: </span> <span className='card-inner-text'>44.342%</span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>My Last Shift</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span> <span className='card-inner-text'>123,465</span>
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span> <span className='card-inner-text'>$234,857</span>
                    </Card>
                </Col> */}
            </Row>

            {/* <div className='mt-4'>
                <WorkLoad />
            </div> */}
        </div>
    )
}

export default Work