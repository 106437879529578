import { getChainNetworkByChainId } from '../../Blockchain/utils/chainList'
import { ethers, utils } from 'ethers'
import { commonConfigs } from '../configs/common.configs'
import { shillingConfigs } from '../configs/shilling.configs'
import axios from 'axios'
import { getTokenPriceInUSDByTokenAddress } from './util.service'
import { stakingConfigs } from '../configs/staking.configs'
import { DateTime } from 'luxon'

export const getArchivedWeb3Provider = () => {
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(commonConfigs.archivedRPCUrl)
  return web3
}

export const getWeb3PRovider = () => {
  const defaultChainId = commonConfigs.chainId
  const web3configs = getChainNetworkByChainId(defaultChainId)
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
    name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
  })
  return web3
}


export const approveBUSD = async (spenderAddress, busdTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()

    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractWithSigner = shillingContractInstance.connect(signer)

    //get formatted busd token amount
    const busdTokenAmountFormatted = utils.parseEther(busdTokenAmount.toString())
    const approvalReceipt = await shillingContractWithSigner.approve(spenderAddress, busdTokenAmountFormatted)
    const result = await approvalReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to approve the busd token. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const buyTokens = async (busdAmount, referralAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    let shillingReferralCode = '0x0000000000000000000000000000000000000000'
    if (referralAddress) {
      shillingReferralCode = referralAddress
    }

    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractWithSigner = shillingContractInstance.connect(signer)

    //get formatted busd token amount
    const busdTokenAmountFormatted = utils.parseEther(busdAmount.toString())
    const buyReceipt = await shillingContractWithSigner.buy(busdTokenAmountFormatted, shillingReferralCode)
    const result = await buyReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to buy fathom. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const buyTokensWithBNB = async (bnbAmount, referralAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    let shillingReferralCode = '0x0000000000000000000000000000000000000000'
    if (referralAddress) {
      shillingReferralCode = referralAddress
    }

    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractWithSigner = shillingContractInstance.connect(signer)

    //get formatted busd token amount
    const bnbTokenAmountFormatted = utils.parseEther(bnbAmount.toString())
    const buyReceipt = await shillingContractWithSigner.buyWithBnb(shillingReferralCode, { value: bnbTokenAmountFormatted })
    const result = await buyReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to buy fathom. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const buyWithBNBAndStakeTokens = async (bnbAmount, referralAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    let shillingReferralCode = '0x0000000000000000000000000000000000000000'
    if (referralAddress) {
      shillingReferralCode = referralAddress
    }

    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractWithSigner = shillingContractInstance.connect(signer)

    //get formatted busd token amount
    const bnbTokenAmountFormatted = utils.parseEther(bnbAmount.toString())
    const buyReceipt = await shillingContractWithSigner.buyWithBnbAndStake(shillingReferralCode, { value: bnbTokenAmountFormatted })
    const result = await buyReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to buy and stake fathom. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const buyAndStakeTokens = async (busdAmount, referralAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    let shillingReferralCode = '0x0000000000000000000000000000000000000000'
    if (referralAddress) {
      shillingReferralCode = referralAddress
    }

    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractWithSigner = shillingContractInstance.connect(signer)

    //get formatted busd token amount
    const busdTokenAmountFormatted = utils.parseEther(busdAmount.toString())
    const buyReceipt = await shillingContractWithSigner.buyAndStake(busdTokenAmountFormatted, shillingReferralCode)
    const result = await buyReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to buy and stake fathom. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const registerAsReferralUser = async (signer) => {
  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractWithSigner = shillingContractInstance.connect(signer)
    const registerReferralReceipt = await shillingContractWithSigner.register()
    const result = await registerReferralReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to register as referral user. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getUserReferralRegisterStatus = async (address) => {
  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const response = await shillingContractInstance.isValidRefferal(address)
    return response
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to read referral status of the user. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const saveReferralBuyEventInOffChain = async (result) => {
  try {
    //get the event object if exists
    const eventName = 'WorkMinted'
    const events = result.events
    let eventObject = null
    if (events && events.length > 0) {
      eventObject = events.find(element => element.event === eventName)
    }

    if (eventObject) {
      const data = eventObject.data
      const decodedData = utils.defaultAbiCoder.decode(['address', 'uint256', 'uint256', 'uint256', 'address'], data)


      const referralAddress = decodedData[0];
      const mintedTokenAmount = decodedData[1].toString();
      const mintedTimestamp = parseInt(decodedData[2].toString());
      const amountSpend = decodedData[3].toString();
      const spendCurrencyAddress = decodedData[4];
      const callerAddress = decodedData[5];

      const payload = {
        referralAddress: referralAddress,
        callerAddress: callerAddress,
        transactionHash: eventObject.transactionHash,
        blockNumber: eventObject.blockNumber,
        mintedTokenAmount: mintedTokenAmount,
        mintedTimestamp: mintedTimestamp,
        amountSpend: amountSpend,
        spendCurrencyAddress: spendCurrencyAddress,
      };

      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/referral/create`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload)
      };

      const apiResponse = await axios(config)
      console.log("API RESPONSE ", apiResponse)

    } else {
      console.log("NO matching referral buy event found")
    }
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to save referral buy event data. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getUserWorkTokenBalance = async (walletAddress) => {

  let userWorkTokenBalance = 0.0
  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const workTokenBalanceResponse = await shillingContractInstance.balanceOf(walletAddress)
    const formattedValue = utils.formatEther(workTokenBalanceResponse.toString())
    userWorkTokenBalance = parseFloat(formattedValue)
    return userWorkTokenBalance
  } catch (error) {
    return userWorkTokenBalance
  }
}

export const getTotalWorkTokensMinted = async () => {
  let totalWorkTokensMinted = 0.0
  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const workTokensMintedResponse = await shillingContractInstance.totalWorkMinted()
    const formattedValue = utils.formatEther(workTokensMintedResponse.toString())
    totalWorkTokensMinted = parseFloat(formattedValue)

    return totalWorkTokensMinted
  } catch (error) {
    console.log(error)
    console.log("ERROR while fetching total work tokens minted ", error)
    return totalWorkTokensMinted
  }
}

export const getHoldersDetails = async (walletAddress) => {
  let lastClaimTimestamp = 0
  let unpaidEarning = 0
  let totalReward = 0
  let holderIndex = 0
  try {
    const provider = getWeb3PRovider()
    const shillingOceanContractABI = JSON.parse(shillingConfigs.shillingOceanContractABI)
    const shillingOceanContractAddress = shillingConfigs.shillingOceanContractAddress
    const shillingContractInstance = new ethers.Contract(shillingOceanContractAddress, shillingOceanContractABI, provider)
    const totalFathomTokensMintedResponse = await shillingContractInstance.getHolderDetails(walletAddress)

    lastClaimTimestamp = parseInt(totalFathomTokensMintedResponse[0].toString())
    const unpaidEarningResponse = utils.formatEther(totalFathomTokensMintedResponse[1].toString())
    unpaidEarning = unpaidEarningResponse.toString()

    const totalRewardsResponse = utils.formatEther(totalFathomTokensMintedResponse[2].toString())
    totalReward = totalRewardsResponse.toString()
    holderIndex = parseInt(totalFathomTokensMintedResponse[3].toString())
    return {
      lastClaimTimestamp,
      unpaidEarning,
      totalReward,
      holderIndex
    }
  } catch (error) {
    return {
      lastClaimTimestamp,
      unpaidEarning,
      totalReward,
      holderIndex
    }
  }
}


export const claimRewards = async (signer) => {
  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const shillingContractInstanceWithSigner = shillingContractInstance.connect(signer)
    const claimTokenReceipt = await shillingContractInstanceWithSigner.___claimRewards()
    const result = await claimTokenReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim your tokens. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getNumbersOfShillersHoldingWorkTokens = async () => {
  let numbersOfShillersWithWorkToken = 0.0

  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)

    // method not implemented yest
    const shillersHoldingWorkTokenResponse = await shillingContractInstance.numberOfShillers()
    numbersOfShillersWithWorkToken = shillersHoldingWorkTokenResponse.toString()

    return numbersOfShillersWithWorkToken
  } catch (error) {
    console.log("ERROR while fetching numbers of shillers holding work tokens ", error)
    return numbersOfShillersWithWorkToken
  }
}

export const getUniqueShillersGenerateWorkTokens = async () => {
  let uniqueShillersGenerateWorkTokens = 0.0

  // TODO
  return uniqueShillersGenerateWorkTokens
  // try {
  //   const provider = getWeb3PRovider()
  //   const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
  //   const shillingContractAddress = shillingConfigs.shillingContractAddress
  //   const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)

  //   // method not implemented yest
  //   const uniqueShillersResponse = await shillingContractInstance.methodName()
  //   const formattedValue = utils.formatEther(uniqueShillersResponse.toString())
  //   uniqueShillersGenerateWorkTokens = parseFloat(formattedValue)
  //   return uniqueShillersGenerateWorkTokens
  // } catch (error) {
  //   console.log("ERROR while fetching numbers of unique shillers generate work tokens ", error)
  //   return uniqueShillersGenerateWorkTokens
  // }
}

export const getFathomTokensBuyWithRefCode = async () => {
  let numbersOfFathomTokenBuyWithRefCode = 0.0

  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)

    // method not implemented yest
    const numberOfFathomTokenBuyWithRefResponse = await shillingContractInstance.totalTokensBuyWithCode()
    const formattedValue = utils.formatEther(numberOfFathomTokenBuyWithRefResponse.toString())
    numbersOfFathomTokenBuyWithRefCode = parseFloat(formattedValue)

    return numbersOfFathomTokenBuyWithRefCode
  } catch (error) {
    console.log("ERROR while fetching total numbers of fathom token buy with ref code ", error)
    return numbersOfFathomTokenBuyWithRefCode
  }
}

export const getTotalFathomTokenInShillingSea = async () => {
  let fathomTokensInShillingSea = 0.0
  try {
    const provider = getWeb3PRovider()
    const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)


    const numbersOfTokenInShillingSeaResponse = await fathomTokenContractInstance.balanceOf(shillingContractAddress)
    const formattedValue = utils.formatEther(numbersOfTokenInShillingSeaResponse.toString())
    fathomTokensInShillingSea = parseFloat(formattedValue)
    return fathomTokensInShillingSea
  } catch (error) {
    console.log("ERROR while fetching total numbers of fathom token buy with ref code ", error)
    return fathomTokensInShillingSea
  }
}

export const getTotalFathomTokenInStakingOcean = async () => {
  let fathomTokensInStakingOcean = 0.0
  try {
    const provider = getWeb3PRovider()
    const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const stakingOceanContractAddress = stakingConfigs.oceanContractAddress
    const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)


    const numbersOfTokenInStakingOceanResponse = await fathomTokenContractInstance.balanceOf(stakingOceanContractAddress)
    const formattedValue = utils.formatEther(numbersOfTokenInStakingOceanResponse.toString())
    fathomTokensInStakingOcean = parseFloat(formattedValue)
    return fathomTokensInStakingOcean
  } catch (error) {
    console.log("ERROR while fetching total numbers of fathom token buy with ref code ", error)
    return fathomTokensInStakingOcean
  }
}


export const getDistributedFathomTokenInShillingSea = async () => {
  let fathomTokensInShillingSea = 0.0

  try {
    const provider = getWeb3PRovider()
    const shillingOceanContractABI = JSON.parse(shillingConfigs.shillingOceanContractABI)
    const shillingOceanContractAddress = shillingConfigs.shillingOceanContractAddress
    const shillingOceanContractInstance = new ethers.Contract(shillingOceanContractAddress, shillingOceanContractABI, provider)
    const totalDistributedResponse = await shillingOceanContractInstance.totalDistributed()
    const formattedValue = utils.formatUnits(totalDistributedResponse.toString())
    fathomTokensInShillingSea = parseFloat(formattedValue)

    return fathomTokensInShillingSea
  } catch (error) {
    console.log("ERROR while fetching release percentage for next shift", error)
    return fathomTokensInShillingSea
  }
}


export const getTotalWorkTokenAmount = async () => {
  let totalWorkTokenAmount = 0.0
  try {
    const provider = getWeb3PRovider()
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    const totalWorkTokenResponse = await shillingContractInstance.totalSupply()
    const formattedValue = utils.formatEther(totalWorkTokenResponse.toString())
    totalWorkTokenAmount = parseFloat(formattedValue)
    return totalWorkTokenAmount
  } catch (error) {
    console.log("ERROR while fetching total numbers of work tokens ", error)
    return totalWorkTokenAmount
  }
}

export const getReleasePercentageOfNextShift = async () => {
  let releasePercentage = 0.0
  try {
    const provider = getWeb3PRovider()
    const stakingOceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const stakingOceanContractAddresses = stakingConfigs.oceanContractAddress
    const stakingOceanContractInstance = new ethers.Contract(stakingOceanContractAddresses, stakingOceanContractABI, provider)
    const releasePercentageResponse = await stakingOceanContractInstance.rewardPerSlot()
    const formattedValue = utils.formatUnits(releasePercentageResponse.toString(), 2)
    releasePercentage = parseFloat(formattedValue)
    return releasePercentage
  } catch (error) {
    console.log("ERROR while fetching release percentage for next shift", error)
    return releasePercentage
  }
}

export const getNextWaveTime = async () => {
  let nextWaveTime = 0.0
  try {
    const provider = getWeb3PRovider()
    const stakingOceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const stakingOceanContractAddresses = stakingConfigs.oceanContractAddress
    const stakingOceanContractInstance = new ethers.Contract(stakingOceanContractAddresses, stakingOceanContractABI, provider)
    const lastTransactionTimeResponse = await stakingOceanContractInstance.lastRewardTransactionTime()
    const slotTimeResponse = await stakingOceanContractInstance.slotTime()

    const slotTimeFormatted = slotTimeResponse.toString()
    const lastTransactionTimeFormatted = lastTransactionTimeResponse.toString()

    const nextWaveTimeValue = parseFloat(slotTimeFormatted) + parseFloat(lastTransactionTimeFormatted)
    
    nextWaveTime = parseFloat(nextWaveTimeValue)
    return nextWaveTime
  } catch (error) {
    console.log("ERROR while fetching release percentage for next shift", error)
    return nextWaveTime
  }
}


export const getNumbersOfFathomTokenReleaseForNextCycle = async () => {
  let fathomTokenReleaseForNextCycle = 0.0
  try {
    const releasePercentage = await getReleasePercentageOfNextShift()
    const fathomTokenAmountInThePool = await getTotalFathomTokenInStakingOcean()
    const releaseTokenAmount = (parseFloat(fathomTokenAmountInThePool) / 100) * releasePercentage
    const releaseTokenAmountDivideByHundred = parseFloat(releaseTokenAmount) / 100
    fathomTokenReleaseForNextCycle = releaseTokenAmountDivideByHundred

    return fathomTokenReleaseForNextCycle
  } catch (error) {
    console.log("ERROR while fetching fathom token release for next cycle ", error)
    return fathomTokenReleaseForNextCycle
  }
}

export const getTheValueOfTokenReleaseInEachCycle = async () => {
  let tokeReleaseValueInUSD = 0.0
  try {

    //get fathom token price in USD
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const tokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
    const tokensReleaseInEachCycle = await getNumbersOfFathomTokenReleaseForNextCycle()

    const tokenReleaseAmountInUSD = parseFloat(tokenPriceInUSD) * parseFloat(tokensReleaseInEachCycle)
    tokeReleaseValueInUSD = tokenReleaseAmountInUSD
    return tokeReleaseValueInUSD
  } catch (error) {
    console.log("ERROR while fetching fathom token release value in each cycle in USD ", error)
    return tokeReleaseValueInUSD
  }
}


export const getFathomTokenPoolValueInUSD = async () => {
  let tokenPoolValueInUSD = 0.0
  try {

    //get fathom token price in USD
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const tokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
    const tokenAmount = await getTotalFathomTokenInShillingSea()

    const tokenReleaseAmountInUSD = parseFloat(tokenPriceInUSD) * parseFloat(tokenAmount)
    tokenPoolValueInUSD = tokenReleaseAmountInUSD
    return tokenPoolValueInUSD
  } catch (error) {
    console.log("ERROR while fetching token value in usd ", error)
    return tokenPoolValueInUSD
  }
}

export const getTotalPurchasedFathom = async (walletAddress) => {
  let totalPurchased = 0

  try {
    const provider = getWeb3PRovider()
    if (walletAddress) {
      const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
      const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
      const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
      const totalFathomPurchasedResponse = await fathomTokenContractInstance.totalPurchased(walletAddress)
      const totalPurchasedFormatted = utils.formatEther(totalFathomPurchasedResponse.toString())
      totalPurchased = parseFloat(totalPurchasedFormatted)
      return totalPurchased
    } else {
      return totalPurchased
    }
  } catch (error) {
    let errorMessage = 'Error while fetching total purchased tokens'
    throw errorMessage
  }
}

export const getTotalSoldFathom = async (walletAddress) => {
  let totalSold = 0

  try {
    const provider = getWeb3PRovider()
    if (walletAddress) {
      const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
      const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
      const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
      const totalFathomSoldResponse = await fathomTokenContractInstance.totalSells(walletAddress)
      const totalSoldFormatted = utils.formatEther(totalFathomSoldResponse.toString())

      totalSold = parseFloat(totalSoldFormatted)
      return totalSold
    } else {
      return totalSold
    }
  } catch (error) {
    let errorMessage = 'Error while fetching total purchased tokens'
    throw errorMessage
  }
}

export const getTotalSupply = async () => {
  let totalSupply = 0
  try {
    const provider = getWeb3PRovider()
    const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
    const totalFathomSupplyResponse = await fathomTokenContractInstance.totalSupply()
    const totalSupplyFormatted = utils.formatEther(totalFathomSupplyResponse.toString())

    totalSupply = parseFloat(totalSupplyFormatted)
    return totalSupply
  } catch (error) {
    let errorMessage = 'Error while fetching total sully tokens'
    throw errorMessage
  }
}

export const getMaxTransaction = async () => {
  let maxTransaction = 0
  try {
    const provider = getWeb3PRovider()
    const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
    const maxTxLimitResponse = await fathomTokenContractInstance.maxTxLimit()
    const maxTxFormatted = utils.formatEther(maxTxLimitResponse.toString())
    maxTransaction = parseFloat(maxTxFormatted)
    return maxTransaction
  } catch (error) {
    let errorMessage = 'Error while fetching Max Transaction'
    throw errorMessage
  }
}

export const getSellTax = async () => {
  let sellTax = 0
  let sellTaxCollector = 0

  try {
    const provider = getWeb3PRovider()
    const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
    const sellTaxResponse = await fathomTokenContractInstance.sellTotalCharge()
    const sellTaxCollectorResponse = await fathomTokenContractInstance.totalSellTaxCollected()

    const sellTaxFormatted = sellTaxResponse.toString()
    const sellTaxCollectorFormatted = utils.formatEther(sellTaxCollectorResponse.toString())

    sellTax = parseFloat(sellTaxFormatted)
    sellTaxCollector = parseFloat(sellTaxCollectorFormatted)
    return {
      sellTax,
      sellTaxCollector
    }
  } catch (error) {
    let errorMessage = 'Error while fetching sell tax'
    throw errorMessage
  }
}

export const getBuyTax = async () => {
  let buyTax = 0
  let buyTaxCollector = 0

  try {
    const provider = getWeb3PRovider()
    const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
    const fathomTokenAddress = process.env.REACT_APP_FATHOM_ADDRESS
    const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
    const buyTaxResponse = await fathomTokenContractInstance.buyTotalCharge()
    const buyTaxCollectorResponse = await fathomTokenContractInstance.totalBuyTaxCollected()

    const buyTaxFormatted = buyTaxResponse.toString()
    const buyTaxCollectorFormatted = utils.formatEther(buyTaxCollectorResponse.toString())

    buyTax = parseFloat(buyTaxFormatted)
    buyTaxCollector = parseFloat(buyTaxCollectorFormatted)
    return {
      buyTax,
      buyTaxCollector
    }
  } catch (error) {
    let errorMessage = 'Error while fetching buy tax'
    throw errorMessage
  }
}

export const getWorkLoadForLast7Days = async () => {
  const graphData = []
  const AVG_BLOCK_TIME_IN_SECONDS = 3
  const SECONDS_PER_DAY = 86400
  const AVG_BLOCKS_PER_DAY = parseInt(SECONDS_PER_DAY / AVG_BLOCK_TIME_IN_SECONDS)
  try {
    const provider = getArchivedWeb3Provider()
    //get the current block number 
    const currentBlock = await provider.getBlockNumber()

    //create the instance of the shilling contract
    const shillingContractABI = JSON.parse(shillingConfigs.shillingContractABI)
    const shillingContractAddress = shillingConfigs.shillingContractAddress
    const shillingContractInstance = new ethers.Contract(shillingContractAddress, shillingContractABI, provider)
    for (let i = 0; i < 7; i++) {
      const blockNumber = currentBlock - (AVG_BLOCKS_PER_DAY * i)
      const totalSupply = await shillingContractInstance.totalSupply({ blockTag: blockNumber })
      const currentDate = DateTime.now().toUTC()
      const date = currentDate.minus({ days: i })
      const payload = {
        blockNumber: blockNumber,
        totalSupply: parseFloat(utils.formatEther(totalSupply).toString()).toFixed(2),
        date: date.toFormat('yyyy-LL-dd')
      }
      graphData.push(payload)
    }
    return graphData
  } catch (error) {
    console.log("ERROR while fetching mass existence graph data ", error)
    return graphData
  }
}