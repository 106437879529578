import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Button, message, Upload, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import { shillingConfigs } from '../../Blockchain/configs/shilling.configs'
import { getWeb3PRovider } from '../../Blockchain/services/util.service'
import { ethers, utils } from 'ethers'
import { ChangeStakeTime } from '../../Blockchain/services/admin.service'
import { useAccount, useSigner } from 'wagmi';

export default function ChangeStakeTimeCSVUploader() {

  const [fileList, setFileList] = useState(null)
  const [csvFirstColumnArray, setCsvFirstColumnArray] = useState([])
  // eslint-disable-next-line
  const [csvSecondColumnArray, setCsvSecondColumnArray] = useState([])
  const [csvThirdColumnArray, setCsvThirdColumnArray] = useState([])
  const [csvForthColumnArray, setCsvForthColumnArray] = useState([])
  // eslint-disable-next-line
  const [totalFathomTokenAmount, setTotalFathomTokenAmount] = useState(0.0)
  const [isCsvUploaded, setIsCsvUploaded] = useState(false)
  const [isCSVProcessingLoading, setIsCSVProcessingLoading] = useState(false)

  const { data: signer } = useSigner();
  const { address: account } = useAccount()
  
  const props = {
    beforeUpload: (file) => {
      const isCSV = file.type === 'text/csv'

      if (!isCSV) {
        message.error(`${file.name} is not a CSV file`)
      }
      setFileList(file)
      return false
    },
    onRemove: () => {
      setFileList(null)
      setCsvFirstColumnArray([])
      setCsvSecondColumnArray([])
      setCsvThirdColumnArray({})
      setCsvForthColumnArray({})
    },
    onChange: (info) => {
      console.log(info.fileList)
    },
  }

  useEffect(() => {
    if (fileList) {
      decodeCSVFileContent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const handleExecuteCSVProcessing = async () => {
    try {
      setIsCSVProcessingLoading(true)
      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        })
        setIsCSVProcessingLoading(false)
        return
      }
      const result = await ChangeStakeTime(csvThirdColumnArray, csvForthColumnArray, signer)
      console.log("RESULT CSV PROCESSING SUCCESS : ", result)
      setIsCSVProcessingLoading(false)
      notification['success']({
        message: 'Success',
        description:
          'Transaction has been completed',
      })
    } catch (error) {
      setIsCSVProcessingLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      })
      console.error("ERROR while try to execute CSV file on blockchain ", error)
    }
  }

  const decodeCSVFileContent = async () => {
    try {
      if (fileList) {
        Papa.parse(fileList, {
          header: true,
          skipEmptyLines: true,
          complete: async (results) => {

            const firstColumnArray = []
            const secondColumnArray = []
            const thirdColumnArray = []
            const forthColumnArray = []
            let totalFathomTokens = 0

            const fathomTokenAddress = shillingConfigs.fathomTokenAddress
            const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)

            const provider = getWeb3PRovider()
            const fathomTokenContractInstance = new ethers.Contract(fathomTokenAddress, fathomTokenContractABI, provider)
            const decimalResponse = await fathomTokenContractInstance.decimals()
            const decimal = parseInt(decimalResponse.toString())

            const errorList = []
            if (results && results.data && results.data.length > 0) {
              results.data.forEach((item, index) => {
                if (!JSON.stringify(item).includes('fathom_token_amount')) {
                  const message = `fathom_token_amount not found in CSV idex ${index} file`
                  errorList.push(message)
                } else {
                  const fathomTokenAmount = parseFloat(item.fathom_token_amount)
                  totalFathomTokens = totalFathomTokens + fathomTokenAmount
                  //converted amount into wei
                  const formattedFathomTokenAmount = utils.parseUnits(item.fathom_token_amount, decimal)
                  firstColumnArray.push(formattedFathomTokenAmount)
                }

                if (!JSON.stringify(item).includes('mass_token_amount')) {
                  const message = `mass_token_amount not found in CSV idex ${index} file`
                  errorList.push(message)
                } else {
                  const formattedMassTokenAmount = utils.parseEther(item.mass_token_amount)
                  secondColumnArray.push(formattedMassTokenAmount)
                }
                if (!JSON.stringify(item).includes('user_address')) {
                  const message = `user_address not found in CSV idex ${index} file`
                  errorList.push(message)
                } else {
                  thirdColumnArray.push(item.user_address)
                }
                if (!JSON.stringify(item).includes('lock_time')) {
                  const message = `lock_time not found in CSV idex ${index} file`
                  errorList.push(message)
                } else {
                  forthColumnArray.push(item.lock_time)
                }
              })

              setTotalFathomTokenAmount(totalFathomTokens)
              setCsvFirstColumnArray(firstColumnArray)
              setCsvSecondColumnArray(secondColumnArray)
              setCsvThirdColumnArray(thirdColumnArray)
              setCsvForthColumnArray(forthColumnArray)

              if ((results?.errors && results?.errors.length > 0) || errorList.length > 0) {
                setIsCsvUploaded(false)
                notification['error']({
                  message: 'Error while processing CSV file'
                })
              } else {
                setIsCsvUploaded(true)
                notification['success']({
                  message: 'CSV file uploaded successfully'
                })
              }

            }
          },
        })
      }
    } catch (error) {
      console.log("ERROR while decoding CSV File : ", error)
    }
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Card className='fathom-finance-card mt-3'>
            <div className='csv-upload-container'>
                <span>Change Stake Time</span>
              <Row className='mt-3'>
                <Col span={12}>
                  <div className='upload-actions'>
                    <Upload {...props} maxCount={1}>
                      <Button icon={<UploadOutlined />}>
                        Click Here To Upload CSV File
                      </Button>
                    </Upload>
                  </div>

                  <div className='blockchain-interaction-buttons'>
                    <Button
                      style={{ padding: '0px 20px' }}
                      onClick={handleExecuteCSVProcessing}
                      className='nutgain-primary-button mt-3'
                      disabled={!isCsvUploaded}
                      loading={isCSVProcessingLoading}
                    >
                      Execute CSV Processing
                    </Button>
                  </div>
                </Col>
                <Col span={12}>
                  <div className='metadata-container d-flex justify-content-end'>
                    <div className='metadata-label' style={{ margin: '10px' }}>
                      Total CSV addresses found :
                    </div>
                    <div className='metadata-value' style={{ margin: '10px' }}>
                      {csvFirstColumnArray ? csvFirstColumnArray.length : 0}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
