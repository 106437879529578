import { Card } from "antd";
import React from "react";
import { useSelector } from 'react-redux'

function MultisendStep() {

    const { contractList } = useSelector((state) => state.csvDataList)

    return (
        <div className='mt-4 mb-5'>
            <Card  className='fathom-finance-card col-lg-8 mx-auto mt-3'>
                <h5 className="mb-4">Transactions</h5>
                {contractList.map((item, index) => (
                    <div className='d-flex justify-content-between align-items-start mx-2 approve-token-list' key={index}>
                        <span className='contract-address'>{item.contractAddress}</span>
                        <span className='send-value'>{item.value}</span>
                    </div>
                ))}
            </Card>
        </div>
    )
}

export default MultisendStep