import React from 'react'
import Background from '../../images/PlexusWave.mp4'
import './BackgroundVideo.css'

function BackgroundVideo() {
  return (
    <div>
      <video src={Background} autoPlay muted loop className='bg-video' />
    </div>
  )
}

export default BackgroundVideo