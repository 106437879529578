import React, { useState, useEffect } from 'react'
import { Card, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { getTotalPurchasedFathom, getTotalSoldFathom } from '../../Blockchain/services/shilling.service'
import { 
    getTotalStakedFathom, 
    getTotalMassValues, 
    getClaimableRewards, 
    getMassDividendBalanceByUser, 
    getLastWave, 
    getTotalMassExisting
} from '../../Blockchain/services/staking.service'
import { getTokenPriceInUSDByTokenAddress } from '../../Blockchain/services/util.service'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'
import { useAccount } from 'wagmi';

function MyHistory() {
    
    const { address: account } = useAccount()
    const fathomTokenAddress = stakingConfigs.fathomTokenAddress

    const[isTotalPurchasedLoading, setIsTotalPurchasedLoading] = useState(false)
    const[totalPurchasedFathom, setTotalPurchasedFathom] = useState(0.0)
    const[fathomTokenPriceInUSD, setFathomTokenPriceInUSD] = useState(0)
    const[totalPurchasedFathomValueInUSD, setTotalPurchasedFathomValueInUSDValueInUSD] = useState(0)
    const[isFathomTokenPriceLoading, setIsFathomTokenPriceLoading] = useState(false)

    const[isTotalSoldLoading, setIsTotalSoldLoading] = useState(false)
    const[totalSoldFathom, setTotalSoldFathom] = useState(0.0)
    const[totalSoldFathomValueInUSD, setTotalSoldFathomValueInUSDValueInUSD] = useState(0)

    const[isTotalStakedLoading, setIsTotalStakedLoading] = useState(false)
    const[totalStakedFathom, setTotalStakedFathom] = useState(0.0)
    const[totalStakedFathomValueInUSD, setTotalStakedFathomValueInUSDValueInUSD] = useState(0)

    const[isTotalMassMintedLoading, setIsTotalMassMintedLoading] = useState(false)
    const[totalMassMinted, setTotalMassMinted] = useState(0.0)

    const[totalMassReflected, setTotalMassReflected] = useState(0.0)

    const [isUserClaimableAmountLoading, setIsUserClaimableAmountLoading] = useState(false)
    const [userClaimableTokenAmount, setUserClaimableTokenAmount] = useState(0)
    const [myTotalRoi, setMyTotalRoi] = useState(0)
    const [myTotalRoiValue, setMyTotalRoiValue] = useState(0)

    const [isUserTotalMassReceivedLoading, setIsUserTotalMassReceivedLoading] = useState(false)
    const [userTotalReceivedMassTokenAmount, setUserTotalReceivedMassTokenAmount] = useState(0)

    const [lastWaveStakingOcean, setLastWaveStakingOcean] = useState(0.0)
    const [isLastWaveStakingOceanLoading, setIsLastWaveStakingOceanLoading] = useState(false)
    const [lastWaveValueInUSD, setLastWaveValueInUSD] = useState(0)

    const [isMasExistingLoading, setIsMasExistingLoading] = useState(false)
    const [totalMassExisting, setTotalMassExisting] = useState(0.0)

    const [dailyRoi, setDailyRoi] = useState(0)
    // eslint-disable-next-line
    const [twelveMonthApy, setTwelveMonthApy] = useState(0)

    const fetchLastWave = async () => {
        setIsLastWaveStakingOceanLoading(true)
        const lastWaveValue = await getLastWave()
        
        setLastWaveStakingOcean(lastWaveValue)
        setIsLastWaveStakingOceanLoading(false)
    }

    const fetchTotalPurchased = async () => {
        if (account) {
            setIsTotalPurchasedLoading(true)
            const totalPurchased = await getTotalPurchasedFathom(account)
            setTotalPurchasedFathom(totalPurchased)
            setIsTotalPurchasedLoading(false)
        } else {
            setTotalPurchasedFathom(0.0)
            setIsTotalPurchasedLoading(false)
        }
    }

    const fetchMassExisting = async () => {
        setIsMasExistingLoading(true)
        const response = await getTotalMassExisting()
        setTotalMassExisting(response)
        setIsMasExistingLoading(false)
    }

    const fetchTotalSoldFathom = async () => {
        if (account) {
            setIsTotalSoldLoading(true)
            const totalSold = await getTotalSoldFathom(account)
            setTotalSoldFathom(totalSold)
            setIsTotalSoldLoading(false)
        } else {
            setTotalSoldFathom(0.0)
            setIsTotalSoldLoading(false)
        }
    }

    const fetchTotalStakedFathom = async () => {
        // if (account) {
            setIsTotalStakedLoading(true)
            const totalSold = await getTotalStakedFathom()
            setTotalStakedFathom(totalSold)
            setIsTotalStakedLoading(false)
        // } else {
        //     setTotalStakedFathom(0.0)
        //     setIsTotalStakedLoading(false)
        // }
    }

    const fetchTotalMass = async () => {
        setIsTotalMassMintedLoading(true)
        const totalMassResponse = await getTotalMassValues()
        setTotalMassMinted(totalMassResponse?.totalMassMinted)
        setTotalMassReflected(totalMassResponse?.totalMassReflected)
        setIsTotalMassMintedLoading(false)
    }

    const fetchFathomTokenPrice = async () => {
        setIsFathomTokenPriceLoading(true)
        const tokenPrice = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
        setFathomTokenPriceInUSD(tokenPrice)
        setIsFathomTokenPriceLoading(false)
    }

    const fetchClaimableTokenAmount = async () => {
        if (account) {
            setIsUserClaimableAmountLoading(true)
            const rewardsValue = await getClaimableRewards(account)
            setUserClaimableTokenAmount(rewardsValue?.claimableRewards)
            setIsUserClaimableAmountLoading(false)
        } else {
            setUserClaimableTokenAmount(0)
        }

    }

    const fetchTotalMassReceivedAmount = async () => {
        if (account) {
            setIsUserTotalMassReceivedLoading(true)
            const totalMassForUser = await getMassDividendBalanceByUser(account)
            setUserTotalReceivedMassTokenAmount(totalMassForUser)
            setIsUserTotalMassReceivedLoading(false)
        } else {
            setUserTotalReceivedMassTokenAmount(0)
        }
    }

    useEffect(() => {
        fetchFathomTokenPrice()
        fetchLastWave()
        fetchMassExisting()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (account) {
            fetchTotalPurchased()
            fetchTotalSoldFathom()
            fetchTotalStakedFathom()
            fetchTotalMass()
            fetchClaimableTokenAmount()
            fetchTotalMassReceivedAmount()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        if (fathomTokenPriceInUSD && parseFloat(totalPurchasedFathom)) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(totalPurchasedFathom)
            setTotalPurchasedFathomValueInUSDValueInUSD(value)
        } else {
            setTotalPurchasedFathomValueInUSDValueInUSD(0)
        }
        if (fathomTokenPriceInUSD && parseFloat(totalSoldFathom) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(totalSoldFathom)
            setTotalSoldFathomValueInUSDValueInUSD(value)
        } else {
            setTotalSoldFathomValueInUSDValueInUSD(0)
        }
        if (fathomTokenPriceInUSD && parseFloat(totalStakedFathom) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(totalStakedFathom)
            setTotalStakedFathomValueInUSDValueInUSD(value)
        } else {
            setTotalStakedFathomValueInUSDValueInUSD(0)
        }

        if(parseFloat(userClaimableTokenAmount) > 0 && parseFloat(userTotalReceivedMassTokenAmount)) {
            const value = parseFloat(userClaimableTokenAmount) / parseFloat(userTotalReceivedMassTokenAmount)
            setMyTotalRoi(value)
        } else {
            setMyTotalRoi(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(lastWaveStakingOcean)) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(lastWaveStakingOcean)
            setLastWaveValueInUSD(value)
        } else {
            setLastWaveValueInUSD(0)
        }

    }, [fathomTokenPriceInUSD, totalPurchasedFathom, totalSoldFathom, totalStakedFathom, userClaimableTokenAmount, userTotalReceivedMassTokenAmount, lastWaveStakingOcean])

    useEffect(() => {
        if (myTotalRoi && userTotalReceivedMassTokenAmount) {
            const value = parseFloat(myTotalRoi) / parseFloat(userTotalReceivedMassTokenAmount)
            setMyTotalRoiValue(value)
        } else {
            setMyTotalRoiValue(0)
        }
    }, [userTotalReceivedMassTokenAmount, myTotalRoi])

    useEffect(() => {
        if (lastWaveValueInUSD && totalMassExisting) {
            const dailyRoiValue = (lastWaveValueInUSD * 96) / totalMassExisting
            setDailyRoi(dailyRoiValue * 100)
            setTwelveMonthApy(dailyRoiValue * 365 * 100)
        }
    }, [lastWaveValueInUSD, totalMassExisting])

  return (
    <div>
        <Card className='mt-4 transparent'>
            <h3 className='text-center text-uppercase'>My History</h3>
        </Card>

        <Row className='mb-3'>
            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Purchased</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalPurchasedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalPurchasedFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalPurchasedLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalPurchasedFathomValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Sold</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalSoldLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalSoldFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>
                    {
                            isTotalSoldLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalSoldFathomValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Staked</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalStakedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalStakedFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalStakedLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalStakedFathomValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Mass</span>}>
                    <span className='primary-text card-inner-text'>Minted: </span>
                    <span className='card-inner-text'>
                        {
                            isTotalMassMintedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalMassMinted}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Reflections: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalMassMintedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalMassReflected}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>
        </Row>

        <Row className='mb-5'>
            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Treasure</span>}>
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>0</span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>$0</span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Previous Wave</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isLastWaveStakingOceanLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={lastWaveStakingOcean}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>
                        {
                            isLastWaveStakingOceanLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={lastWaveValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Previous Wave ROI</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isLastWaveStakingOceanLoading || isFathomTokenPriceLoading || isMasExistingLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={dailyRoi}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    suffix=" %"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    {/* <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>$0</span> */}
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>My Total ROI</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isUserClaimableAmountLoading || isTotalStakedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={myTotalRoi}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>
                        {
                            isUserTotalMassReceivedLoading || isTotalStakedLoading || isUserClaimableAmountLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={myTotalRoiValue}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default MyHistory