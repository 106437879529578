import React, {useState, useEffect} from 'react'
import { Card, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import { getSellTax, getBuyTax } from '../../Blockchain/services/shilling.service'
import { getStakingTax } from '../../Blockchain/services/staking.service'
import NumberFormat from 'react-number-format'

function Taxes() {

    const[isSellTaxLoading, setIsSellTaxLoading] = useState(false)
    const[sellTaxFathom, setSellTaxFathom] = useState(0.0)
    const[sellTaxCollected, setSellTaxCollected] = useState(0.0)

    const[isBuyTaxLoading, setIsBuyTaxLoading] = useState(false)
    const[buyTaxFathom, setBuyTaxFathom] = useState(0.0)
    const[buyTaxCollected, setBuyTaxCollected] = useState(0.0)

    const[isStakingTaxLoading, setIsStakingTaxLoading] = useState(false)
    const[stakingTaxFathom, setStakingTaxFathom] = useState(0.0)
    const[stakingTaxCollected, setStakingTaxCollected] = useState(0.0)

    const fetchSellTax = async () => {
        setIsSellTaxLoading(true)
        const totalSellTax = await getSellTax()
        setSellTaxFathom(totalSellTax?.sellTax)
        setSellTaxCollected(totalSellTax?.sellTaxCollector)
        setIsSellTaxLoading(false)
    }

    const fetchBuyTax = async () => {
        setIsBuyTaxLoading(true)
        const totalBuyTax = await getBuyTax()
        setBuyTaxFathom(totalBuyTax?.buyTax)
        setBuyTaxCollected(totalBuyTax?.buyTaxCollector)
        setIsBuyTaxLoading(false)
    }

    const fetchStakingTax = async () => {
        setIsStakingTaxLoading(true)
        const totalBuyTax = await getStakingTax()
        setStakingTaxFathom(totalBuyTax?.stakingTax)
        setStakingTaxCollected(totalBuyTax?.stakingTaxCollector)
        setIsStakingTaxLoading(false)
    }

    useEffect(() => {
        fetchSellTax()
        fetchBuyTax()
        fetchStakingTax()
    }, [])

  return (
    <div>
        <Card className='mt-4 transparent'>
            <h3 className='text-center text-uppercase'>Taxes</h3>
        </Card>

        <Row className='mb-5'>
            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Buy Tax</span>}>
                    <span className='primary-text card-inner-text'>Set Rate: </span>
                    <span className='card-inner-text'>
                        {
                            isBuyTaxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={buyTaxFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>$Fathom Collected: </span> 
                    <span className='card-inner-text'>
                        {
                            isBuyTaxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={buyTaxCollected}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Sell Tax</span>}>
                    <span className='primary-text card-inner-text'>Set Rate: </span>
                    <span className='card-inner-text'>
                    {
                            isSellTaxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={sellTaxFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>$Fathom Collected: </span> 
                    <span className='card-inner-text'>
                        {
                            isSellTaxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={sellTaxCollected}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Transfer Tax</span>}>
                    <span className='primary-text card-inner-text'>Set Rate: </span><span className='card-inner-text'>0%</span>
                    {/* <br />
                    <span className='primary-text card-inner-text'>$Fathom Collected: </span> <span className='card-inner-text'>145,412</span> */}
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Staking Tax</span>}>
                    <span className='primary-text card-inner-text'>Set Rate: </span>
                    <span className='card-inner-text'>
                        {
                            isStakingTaxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={stakingTaxFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>$Fathom Collected: </span> 
                    <span className='card-inner-text'>
                        {
                            isSellTaxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={stakingTaxCollected}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default Taxes