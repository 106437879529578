import {
    Card,
    Progress,
    Spin,
    notification
} from 'antd'
import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap'
import {
    getAPY,
    getDepositFee,
    getStakingDetailsByWalletAddress
    // stakeFathomTokens
} from '../../../Blockchain/services/staking.service';
import { getBEP20TokenBalanceByWalletAddress } from '../../../Blockchain/services/util.service';
// import { tokenApproval } from '../../../Blockchain/services/util.service';
import { utils } from 'ethers'
import WithdrawLimits from '../WithdrawLimits/WithdrawLimits';
import Timer from '../../../Components/UpcomingCountdown/UpcomingCountdownDetailed'
import { HashScroll } from "react-hash-scroll";
import { useAccount } from 'wagmi';

function Stakenomics(props) {
    // const { setIsStaticShouldUpdate } = props
    // const [isModalVisible, setIsModalVisible] = useState(false);

    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    // const handleOk = () => {
    //     setIsModalVisible(false);
    // };

    // const handleCancel = () => {
    //     setIsModalVisible(false);
    // };

    const { address: account } = useAccount()

    const [apy, setApy] = useState(0.0)
    const [isStakingAPYLoading, setIsStakingAPYLoading] = useState(false)

    const [depositFee, setDepositFee] = useState(0.0)
    const [isDepositFeeLoading, setIsDepositFeeLoading] = useState(false)

    const [userFathomTokenBalance, setUserFathomTokenBalance] = useState(0.0)
    // eslint-disable-next-line
    const [isUserFathomTokenBalanceLoading, setIsUserFathomTokenBalanceLoading] = useState(false)
    // eslint-disable-next-line
    const [fathomTokensToBeStaked, setFathomTokensToBeStaked] = useState('')
    const [isUserStakedFathomTokenLoading, setIsUserStakedFathomTokenLoading] = useState(false)
    const [userStakerTime, setUserStakerTime] = useState(null)
    // const [isFathomTokenStakingLoading, setIsFathomTokenStakingLoading] = useState(false)

    // const [isStakeButtonDisabled, setIsStakeButtonDisabled] = useState(true)

    // const [isTokenApprovalLoading, setIsTokenApprovalLoading] = useState(false)
    // eslint-disable-next-line
    const [sliderValue, setSliderValue] = useState(0)

    // const handleApproveTokens = async () => {
    //     setIsTokenApprovalLoading(true)
    //     if (!account) {
    //         notification['error']({
    //             message: 'Authentication Error',
    //             description:
    //                 'Please connect your wallet to proceed',
    //         });
    //         setIsTokenApprovalLoading(false)
    //         return
    //     }
    //     if (!fathomTokensToBeStaked || fathomTokensToBeStaked <= 0) {
    //         notification['error']({
    //             message: 'Input Validation',
    //             description:
    //                 'Approval amount can not be empty',
    //         })
    //         setIsTokenApprovalLoading(false)
    //         return
    //     }
    //     try {

    //         const spender = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS
    //         const FATHOM_TOKEN_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
    //         const result = await tokenApproval(spender, FATHOM_TOKEN_ADDRESS, fathomTokensToBeStaked, library.getSigner())
    //         // const result = await approveBUSD(account, userBUSDBuyAmount, library.getSigner())

    //         console.log("APPROVAL SUCCESS ", result)
    //         setIsTokenApprovalLoading(false)
    //         setIsStakeButtonDisabled(false)
    //         notification['success']({
    //             message: 'Success',
    //             description:
    //                 'Token has been approved',
    //         })

    //     } catch (error) {
    //         setIsTokenApprovalLoading(false)
    //         setIsStakeButtonDisabled(true)
    //         notification['error']({
    //             message: 'Transaction Execution Failed',
    //             description: error,
    //         })
    //         console.error("ERROR while trying to approve BUSD for shilling ", error)
    //     }
    // }

    // const handleFathomStake = async () => {
    //     try {
    //         if (!account) {
    //             notification['error']({
    //                 message: 'Authentication Error',
    //                 description:
    //                     'Please connect your wallet to proceed',
    //             });
    //             setIsFathomTokenStakingLoading(false)
    //             return
    //         }
    //         if (!fathomTokensToBeStaked) {
    //             notification['error']({
    //                 message: 'Input Validation Error',
    //                 description:
    //                     'Please enter the valid token amount to stake',
    //             })
    //             setIsFathomTokenStakingLoading(false)
    //             return
    //         }
    //         setIsFathomTokenStakingLoading(true)
    //         const response = await stakeFathomTokens(fathomTokensToBeStaked, library.getSigner())
    //         if (response) {
    //             notification['success']({
    //                 message: 'Success',
    //                 description:
    //                     'Token has been staked',
    //             })
    //         }
    //         fetchUserFathomTokenBalance()
    //         setIsFathomTokenStakingLoading(false)
    //         setFathomTokensToBeStaked('')
    //         setIsStaticShouldUpdate(true)
    //     } catch (error) {
    //         notification['error']({
    //             message: 'Transaction Execution Error',
    //             description: error
    //         })
    //         setIsFathomTokenStakingLoading(false)
    //         console.log("ERROR while trying to stake fathom tokens ", error)
    //     }
    // }

    const fetchAPYAmount = async () => {
        try {
            setIsStakingAPYLoading(true)
            const apyResponse = await getAPY()
            setApy(apyResponse)
            setIsStakingAPYLoading(false)
        } catch (error) {
            setIsStakingAPYLoading(false)
            setApy(0.0)
            console.log("ERROR while fetching staking APY ", error)
        }
    }

    const fetchDepositFeeAmount = async () => {
        try {
            setIsDepositFeeLoading(true)
            const apyResponse = await getDepositFee()
            setDepositFee(apyResponse)
            setIsDepositFeeLoading(false)
        } catch (error) {
            setIsDepositFeeLoading(false)
            setDepositFee(0.0)
            console.log("ERROR while fetching staking APY ", error)
        }
    }


    const fetchUserFathomTokenBalance = async () => {
        try {
            setIsUserFathomTokenBalanceLoading(true)
            const FATHOM_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
            const fathomTokenBalance = await getBEP20TokenBalanceByWalletAddress(FATHOM_ADDRESS, account)
            setUserFathomTokenBalance(fathomTokenBalance)
            setIsUserFathomTokenBalanceLoading(false)
        } catch (error) {
            setIsUserFathomTokenBalanceLoading(false)
            setUserFathomTokenBalance(0.0)
            console.error("ERROR while fetching user busd token balance : ", error)
        }
    }
    
    // eslint-disable-next-line
    const handleStakeFathomInput = (e) => {
        const value = e.target.value
        if (value) {
            if (parseInt(value) <= parseInt(userFathomTokenBalance)) {
                setFathomTokensToBeStaked(value)
            } else {
                notification['error']({
                    message: 'Input Validation Error',
                    description:
                        'Stake amount can not exceed current user fathom token balance amount',
                })
                setFathomTokensToBeStaked(userFathomTokenBalance)
            }
        } else {
            setFathomTokensToBeStaked('')
        }

    }
    const fetchUserStakingDetails = async () => {
        if (account) {
            setIsUserStakedFathomTokenLoading(true)
            const response = await getStakingDetailsByWalletAddress(account)
            setUserStakerTime(parseFloat(response.lockTimeTimestamp) * 1000)
            setIsUserStakedFathomTokenLoading(false)
        } else {
            setUserStakerTime('')
        }
    }

    useEffect(() => {
        fetchDepositFeeAmount()
        fetchAPYAmount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (account) {
            fetchUserFathomTokenBalance()
            fetchUserStakingDetails()
        } else {
            setUserFathomTokenBalance(0.0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        const userFathomTokenInWei = utils.parseEther(userFathomTokenBalance.toString())
        // const userMassTokenDividendAmountBN = BigNumber.from(userMassTokenDividendAmount.toString())
        const sliderValuePercentage = parseFloat(sliderValue) / 100
        const sliderValueInWei = utils.parseEther(sliderValuePercentage.toString())
        const userContributionBN = userFathomTokenInWei.mul(sliderValueInWei)
        const formattedUserContributionAmount = utils.formatUnits(userContributionBN.toString(), 36)
        setFathomTokensToBeStaked(formattedUserContributionAmount.toString())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderValue, userFathomTokenBalance])

    return (
        <Card className='fathom-finance-card'>
            <HashScroll hash="#stakenomics" position='center'>
                <h5 className='primary-text text-center'>Stakenomics</h5>
            </HashScroll>
            <hr />

            <div className='text-center'>
                <h6>- Mass is minted upon staking for the $USD value of the stake.</h6>
                <h6>- Waves from the Ocean are allocated according to existing Mass.</h6>
                <h6>- Mass is forfeited upon unstaking at the ratio being unstaked.</h6>
                <h6>- 75% of forfeited Mass is burned forever.</h6>
            </div>

            <div className='table'>
                <div className='table-responsive'>
                    <div className='d-flex'>

                        <Col xxl="4" xl="4" lg="4" md="6" sm="6" xs="6">
                            <Card className='fathom-finance-card text-center h-100'>
                                <h6 className='small mb-3'>APY Rate</h6>
                                {
                                    isStakingAPYLoading ? (
                                        <div className='d-flex justify-content-center'>
                                            <Spin size='small' />
                                        </div>
                                    ) : (
                                        <Progress
                                            type="circle"
                                            strokeColor={{
                                                '0%': '#bae637',
                                                '100%': '#52c41a',
                                            }}
                                            percent={apy}
                                            width={70}
                                        />
                                    )
                                }

                            </Card>
                        </Col>

                        <Col xxl="4" xl="4" lg="4" md="6" sm="6" xs="6">
                            <Card className='fathom-finance-card text-center h-100'>
                                <h6 className='small mb-3'>Reward Time</h6>
                                <Progress
                                    type="circle"
                                    strokeColor={{
                                        '0%': '#91d5ff',
                                        '100%': '#13c2c2',
                                    }}
                                    percent={25}
                                    format={(percent) => <span className='small'>15 mins</span>}
                                    width={70}
                                />
                            </Card>
                        </Col>

                        <Col xxl="4" xl="4" lg="4" md="6" sm="6" xs="6">
                            <Card className='fathom-finance-card text-center h-100'>
                                <h6 className='small mb-3'>Deposit Fee</h6>
                                {
                                    isDepositFeeLoading ? (
                                        <div className='d-flex justify-content-center'>
                                            <Spin size='small' />
                                        </div>
                                    ) : (
                                        <Progress
                                            type="circle"
                                            strokeColor={{
                                                '0%': '#ffa940',
                                                '100%': '#fa541c',
                                            }}
                                            percent={depositFee}
                                            width={70}
                                        />
                                    )
                                }

                            </Card>
                        </Col>

                    </div>
                </div>
            </div>
            
            <Card className='fathom-finance-card claim-card'>
                
                <h6 className='primary-text text-center mb-4'>Ripples: Withdraw Limits When Unstaking</h6>
                
                <WithdrawLimits />
                
                <div className='mt-4 col-lg-10 mx-auto'>
                    {/* <h6>- Time since Last Stake or Compound = <UpcomingCountdownDetailed /></h6> */}
                    {
                        isUserStakedFathomTokenLoading ? (
                            <div className='d-flex justify-content-center'>
                                <Spin size='small' />
                            </div>
                        ) : (
                            <div className='d-flex'>
                                <h6>- Time since Last Stake or Compound =&nbsp;</h6>
                                <h6><Timer deadline={userStakerTime} /></h6>
                                {/* <h6>- Time since Last Stake or Compound = <Timer deadline={userStakerTime} /></h6> */}
                            </div>
                        )
                    }
                    
                    <h6>- All collected $Fathom from Ripple Taxes are return to Fathom's Ocean</h6>
                </div>
            </Card>
        </Card>
    )
}

export default Stakenomics