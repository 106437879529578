import React, { useEffect, useState } from 'react'
import { Card, Slider, Input, Button, Form, notification, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import {
    getStakingDetailsByWalletAddress,
    unstakeRewardTokensBigNumber,
    getClaimableRewards,
    getMassBalanceByWalletAddressInStaking
} from '../../../Blockchain/services/staking.service'
import { BigNumber, utils } from 'ethers'
import Timer from '../../../Components/UpcomingCountdown/UpcomingCountdownDetailed'
import { DateTime } from 'luxon'
import TwentyFiveRippleTaxImg from '../../../images/25-rippleTax.jpg'
import FiftyRippleTaxImg from '../../../images/50-rippleTax.jpg'
import SeventyFiveRippleTaxImg from '../../../images/75-rippleTax.jpg'
import TokensLockedImg from '../../../images/tokensLocked.jpg'
import TokenUnlockedImg from '../../../images/tokenUnlocked.jpg'
import { useAccount, useSigner } from 'wagmi';
import fflogo from '../../../images/fflogo.png'

function Unstake() {

    const { address: account } = useAccount()
    const { data: signer } = useSigner();

    const [userStakingDetails, setUserStakingDetails] = useState(null)
    const [isUserStakedFathomTokenLoading, setIsUserStakedFathomTokenLoading] = useState(false)

    const [userUnstakeAmount, setUserUnstakeAmount] = useState('')
    const [userTotalUnstakeMass, setUserTotalUnstakeMass] = useState(0)
    const [userTotalUnstakeFathom, setUserTotalUnstakeFathom] = useState('')
    const [sliderValue, setSliderValue] = useState(0)

    const [isUnstakeTokenLoading, setIsUnstakeTokenLoading] = useState(false)
    const [unstakeAmountHelper, setUnstakeAmountHelper] = useState('')
    const [unstakeAmountValidationStatus, setUnstakeAmountValidationStatus] = useState('')

    const [fathomMassRation, setFathomMassRation] = useState(0)

    const [isUserRewardsLoading, setIsUserRewardsLoading] = useState(false)
    // eslint-disable-next-line
    const [userRewardsDetails, setUserRewardsDetails] = useState(null)
    const [fathomTokenAmountForUnstake, setFathomTokenAmountForUnstake] = useState('')
    const [userMassTokenBalance, setUserMassTokenBalance] = useState(0.0)
    const [userStakerTime, setUserStakerTime] = useState(null)
    const [unStakingTax, setUnStakingTax] = useState(0)

    const fetchUserClaimableRewardsAmount = async () => {
        if (account) {
            setIsUserRewardsLoading(true)
            const rewardsData = await getClaimableRewards(account)
            setUserRewardsDetails(rewardsData)
            setIsUserRewardsLoading(false)
        } else {
            setUserRewardsDetails(null)
        }
    }

    const fetchUserStakingDetails = async () => {
        setUnstakeAmountValidationStatus('validating')
        if (account) {
            setIsUserStakedFathomTokenLoading(true)
            const response = await getStakingDetailsByWalletAddress(account)
            const userMassTokenAmount = await getMassBalanceByWalletAddressInStaking(account)
            setUserMassTokenBalance(userMassTokenAmount)
            setUserStakingDetails(response)
            setUserStakerTime(parseFloat(response.lockTimeTimestamp) * 1000)
            if (userMassTokenAmount) {
                const massBN = utils.parseEther(userMassTokenAmount.toString())
                setUserUnstakeAmount(massBN)
                setUserTotalUnstakeMass(massBN)
            }
            setIsUserStakedFathomTokenLoading(false)
            setUnstakeAmountValidationStatus('')
        } else {
            setUserStakingDetails(null)
            setIsUserStakedFathomTokenLoading(false)
            setUnstakeAmountValidationStatus('')
            setUserMassTokenBalance(0.0)
        }
    }

    const handleUnstakeInput = (e) => {
        const inputValue = e.target.value
        if (inputValue) {
            const stakedAmount = userMassTokenBalance
            if (parseFloat(inputValue) > parseFloat(stakedAmount)) {
                const inputBN = utils.parseEther(stakedAmount.toString())
                setUserUnstakeAmount(inputBN)
                setUnstakeAmountHelper('Unstake amount can not exceeds total mass amount invested')
                setUnstakeAmountValidationStatus('error')
            } else {
                const inputBN = utils.parseEther(inputValue.toString())
                setUserUnstakeAmount(inputBN)
                setUnstakeAmountHelper('')
                setUnstakeAmountValidationStatus('')
            }
        } else {
            setUserUnstakeAmount('')
            setUnstakeAmountHelper('')
            setUnstakeAmountValidationStatus('')
        }
    }

    const handleStakingTokenPercentage = (percentage) => {
        const massBN = utils.parseEther(userMassTokenBalance.toString())
        switch (percentage) {
            case 25:
                const output25 = massBN
                    .mul(BigNumber.from("25"))
                    .div(BigNumber.from("100"))
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               setSliderValue(25)
                setUserUnstakeAmount(output25)
                break;
            case 50:
                const output50 = massBN
                    .mul(BigNumber.from("50"))
                    .div(BigNumber.from("100"))
                setSliderValue(50)
                setUserUnstakeAmount(output50)
                break;
            case 75:
                const output75 = massBN
                    .mul(BigNumber.from("75"))
                    .div(BigNumber.from("100"))
                setSliderValue(75)
                setUserUnstakeAmount(output75)
                break;
            case 100:
                const output100 = massBN      
                    .mul(BigNumber.from("100"))
                    .div(BigNumber.from("100"))
                setSliderValue(100)
                setUserUnstakeAmount(output100)
                break;
            default:
                setSliderValue(0)
                setUserUnstakeAmount(massBN)
        }
    }

    const handleSliderChange = (value) => {
        if (value && userUnstakeAmount) {
            setSliderValue(value)
            const valueBN = BigNumber.from(value.toString())
            const massBN = utils.parseEther(userMassTokenBalance.toString())
            const response = massBN.mul(valueBN).div(BigNumber.from("100"))
            setUserUnstakeAmount(response)
        } else {
            setSliderValue(0)
            setUserUnstakeAmount('')
        }
    }


    const handleUnstake = async () => {
        setIsUnstakeTokenLoading(true)
        try {
            if (!account) {
                notification['error']({
                    message: 'Authentication Error',
                    description:
                        'Please connect your wallet to proceed',
                })
                setIsUnstakeTokenLoading(false)
                return
            }
            if (!(parseFloat(userUnstakeAmount.toString()) > 0)) {
                notification['info']({
                    message: 'Input Validation Error',
                    description:
                        'Staking amount must be greater than zero',
                })
                setIsUnstakeTokenLoading(false)
                return
            }
            const result = await unstakeRewardTokensBigNumber(userUnstakeAmount, signer)
            console.log("UNSTAKE SUCCESS ", result)
            setIsUnstakeTokenLoading(false)
            notification['success']({
                message: 'Unstaking Successes',
                description: 'Token has been unstaked'
            })
        } catch (error) {
            console.log("ERROR while trying to unstake fathom tokens ", error)
            notification['error']({
                message: 'Transaction Execute Error',
                description: error
            })
            setIsUnstakeTokenLoading(false)
        }
    }

    useEffect(() => {
        fetchUserStakingDetails()
        fetchUserClaimableRewardsAmount()
        if (!account) {
            setUnstakeAmountHelper('Please connect your wallet')
            setUnstakeAmountValidationStatus('error')
        } else {
            setUnstakeAmountHelper('')
            setUnstakeAmountValidationStatus('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        if (userStakingDetails) {
            if (userStakingDetails?.totalMassReceived > 0) {
                const ratio = parseFloat(userStakingDetails?.totalFathomTokenInvested) / parseFloat(userStakingDetails?.totalMassReceived)
                setFathomMassRation(ratio)
            } else {
                setFathomMassRation(0)
            }
        }
    }, [userStakingDetails])

    useEffect(() => {
        if (fathomMassRation && userUnstakeAmount && parseFloat(utils.formatEther(userUnstakeAmount.toString()).toString())) {
            const ratioInWei = utils.parseEther(fathomMassRation.toString())
            const epauletFathomTokenBN = userUnstakeAmount.mul(ratioInWei)
            setFathomTokenAmountForUnstake(epauletFathomTokenBN)
        } else {
            setFathomTokenAmountForUnstake('')
        }

    }, [userUnstakeAmount, fathomMassRation])

    useEffect(() => {
        if (userTotalUnstakeMass && fathomMassRation) {
            const ratioInWei = utils.parseEther(fathomMassRation.toString())
            const epauletFathomTokenBN = userUnstakeAmount.mul(ratioInWei)
            setUserTotalUnstakeFathom(epauletFathomTokenBN)
        } else {
            setUserTotalUnstakeFathom('')
        }
        // eslint-disable-next-line
    }, [userTotalUnstakeMass, fathomMassRation])

    useEffect(() => {
        const currentTime = DateTime.now().ts
        if (userStakerTime) {
            const timeDeference = parseFloat(currentTime) - parseFloat(userStakerTime)
            const stakedDays = timeDeference / 86400000
            
            if (stakedDays < 2) {
                setUnStakingTax(TokensLockedImg)
            } else if ( stakedDays > 2 && stakedDays < 4) {
                // setUnStakingTax('75% current unstaking tax')
                setUnStakingTax(SeventyFiveRippleTaxImg)
            }
            else if (stakedDays > 4 && stakedDays < 6) {
                // setUnStakingTax('50% current unstaking tax')
                setUnStakingTax(FiftyRippleTaxImg)
            } 
            else if (stakedDays > 6 && stakedDays < 8) {
                // setUnStakingTax('25% current unstaking tax')
                setUnStakingTax(TwentyFiveRippleTaxImg)
            } 
            else {
                // setUnStakingTax('0% current unstaking tax')
                setUnStakingTax(TokenUnlockedImg)
            }        
        }
        
    }, [userStakerTime])

    return (
        <Card className='fathom-finance-card'>
            <h5 className='primary-text text-center'>Unstake</h5>
            <hr />

            <Row className='col-lg-6 mx-auto'>

                {/* <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3" className='my-auto'>
                Balance:
            </Col> */}

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                    <h6 className='text-center'>$Fathom</h6>
                    <Form.Item
                        hasFeedback
                        validateStatus={isUserStakedFathomTokenLoading ? 'validating' : ''}
                        help={account ? '' : 'Please connect your wallet'}
                    >
                        <Input
                            readOnly={true}
                            className='stake-fathom-input'
                            value={userTotalUnstakeFathom ? utils.formatUnits(userTotalUnstakeFathom, 36) : ''}
                            size="large"
                            type="number"
                        />
                    </Form.Item>
                </Col>

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                    <h6 className='text-center'>Mass</h6>
                    <Form.Item
                        hasFeedback
                        validateStatus={isUserStakedFathomTokenLoading ? 'validating' : ''}
                        help={account ? '' : 'Please connect your wallet'}
                    >
                        <Input
                            className='stake-fathom-input'
                            disabled={isUserStakedFathomTokenLoading}
                            size="large"
                            value={userMassTokenBalance ? userMassTokenBalance : 0}
                            type="number"
                            readOnly={true} />
                    </Form.Item>
                </Col>

            </Row>

            <Row>
                <Col className='mt-2'>
                    {
                        isUserStakedFathomTokenLoading ? (
                            <div className='d-flex justify-content-center'>
                                <Spin size='small' />
                            </div>
                        ) : (
                            <h4 className='text-center m-0'>
                                {/* <img alt='fathom-logo' src={unStakingTax} style={{ width: '330px' }} /> */}
                                <img alt='fathom-logo' src={fflogo} style={{ width: '50px', borderRadius: '50%' }} />
                            </h4>
                        )
                    }
                   
                </Col>
            </Row>

            <Row>
                <Col className='mt-3'>
                    <h5 className='text-center'>To Unstake</h5>
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='mx-auto mt-3'>
                    <h6 className='text-center'>$Fathom Received</h6>
                    <Form.Item
                        hasFeedback
                        validateStatus={isUserRewardsLoading ? 'validating' : ''}
                        help={account ? '' : 'Please connect your wallet'}
                    >
                        <Input
                            disabled={isUserRewardsLoading}
                            className='stake-fathom-input'
                            size="large"
                            value={fathomTokenAmountForUnstake ? utils.formatUnits(fathomTokenAmountForUnstake, 36) : ''}
                            type="number" readOnly={true} />
                    </Form.Item>
                </Col>

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='mx-auto mt-3'>
                    <h6 className='text-center'>Mass Forfeited</h6>
                    <Form.Item
                        hasFeedback
                        validateStatus={unstakeAmountValidationStatus}
                        help={unstakeAmountHelper}
                    >
                        <Input
                            disabled={isUserStakedFathomTokenLoading}
                            name="unstakeAmount"
                            onChange={handleUnstakeInput}
                            className='stake-fathom-input'
                            value={userUnstakeAmount && userUnstakeAmount !== '' ? parseFloat(utils.formatEther(userUnstakeAmount.toString())) : ''}
                            size="large"
                            type="number"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col>
                    <Slider value={sliderValue} onChange={handleSliderChange} />
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(25) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >25%</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(50) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >50%</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(75) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >75%</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(100) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >MAX</Button>
                </Col>
            </Row>

            <div className='d-flex justify-content-center my-4'>
                {
                    isUserStakedFathomTokenLoading ? (
                        <div className='d-flex justify-content-center'>
                            <Spin size='small' />
                        </div>
                    ) : (
                        <div className='d-flex'>
                            <h6>- Time since Last Stake or Compound =&nbsp;</h6>
                            <h6><Timer deadline={userStakerTime} /></h6>
                            {/* <h6>- Time since Last Stake or Compound = <Timer deadline={userStakerTime} /></h6> */}
                        </div>
                    )
                }
            </div>

            <Row>
                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" className='mt-3'>
                    <Button
                        loading={isUnstakeTokenLoading}
                        onClick={handleUnstake}
                        className='col-12 nutgain-primary-button'>Unstake</Button>
                </Col>
            </Row>
        </Card>
    )
}

export default Unstake