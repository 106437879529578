import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    menu_opened: false
};

export const MobileMenuSlice = createSlice({
    name: "MobileMenuSlice",
    initialState,
    reducers: {
        open_menu: (state) => {
            state.menu_opened = true
        },
        close_menu: (state) => {
            state.menu_opened = false
        }
    }
})

// Action creators are generated for each case reducer function
export const { open_menu, close_menu } = MobileMenuSlice.actions;

export default MobileMenuSlice.reducer;