import React from 'react'
import { Drawer } from 'antd';
import './MobileMenu.css'
import fflogo from '../../images/fflogo.png'
import MenuItems from '../Menu/MenuItems';
import { useSelector, useDispatch } from "react-redux";
import { close_menu } from '../../Redux/MobileMenuSlice';

// social icons
import discord from '../../images/social/discord.png'
import facebook from '../../images/social/facebook.png'
import telegram from '../../images/social/telegram.png'
import twitter from '../../images/social/twitter.png'

function MobileMenu() {

    const { menu_opened } = useSelector((state) => state.mobileMenu)
    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(close_menu())
    }

    return (
        <Drawer
            placement="left"
            onClose={onClose}
            visible={menu_opened}
            width="270px"
            closable={false}
            style={{zIndex: 9999}}
        >

            <div className='d-flex justify-content-between mb-4'>
                <div className=''>
                    <img src={fflogo} alt="main_logo" style={{ width: '40px', borderRadius: '50%' }} />
                </div>
                
                <div className='my-auto'>
                
                    <img src={discord} alt="email" style={{ width: '30px' }} className="social-link" />
                    <img src={facebook} alt="facebook" style={{ width: '30px' }} className="social-link" />
                    <img src={telegram} alt="telegram" style={{ width: '30px' }} className="social-link" />
                    <img src={twitter} alt="twitter" style={{ width: '30px' }} className="social-link" />

                </div>
            </div>

                <MenuItems />

        </Drawer>
    )
}

export default MobileMenu