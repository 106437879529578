import { getChainNetworkByChainId } from '../../Blockchain/utils/chainList'
import { ethers, utils } from 'ethers'
import { commonConfigs } from '../configs/common.configs'


export const getWeb3PRovider = () => {
  const defaultChainId = commonConfigs.chainId
  const web3configs = getChainNetworkByChainId(defaultChainId)
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
    name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
  })
  return web3
}

//get user native bnb balance
export const getUserBNBBalance = async (address) => {
  let bnbBalance = 0.0
  try {
    if (address) {
      const provider = getWeb3PRovider()
      const userBNBBalance = await provider.getBalance(address)
      const bnbBalanceFormatted = utils.formatEther(userBNBBalance)
      bnbBalance = bnbBalanceFormatted.toString()
      return bnbBalance
    } else {
      return bnbBalance
    }

  } catch (error) {
    console.log("ERROR while fetching user bnb balance : ", error)
    return bnbBalance
  }
}

//get BEP20 token balance by user wallet address
export const getBEP20TokenBalanceByWalletAddress = async (tokenAddress, walletAddress) => {
  let userTokenBalance = 0
  try {
    const provider = getWeb3PRovider()
    if (tokenAddress && walletAddress) {
      const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
      const tokenDecimalsResponse = await contractInstance.decimals()
      const tokenDecimals = parseInt(tokenDecimalsResponse.toString())
      const tokenBalanceResponse = await contractInstance.balanceOf(walletAddress)
      const tokenBalanceUnformatted = tokenBalanceResponse.toString()
      userTokenBalance = utils.formatUnits(tokenBalanceUnformatted, tokenDecimals)
      return userTokenBalance
    } else {
      return userTokenBalance
    }
  } catch (error) {
    let errorMessage = 'Error while fetching user token balance'
    throw errorMessage
  }
}

export const getPCSRouterCurrencyConversion = async (tokenAddress, tokenAmountToSell) => {
  let estimatedConversionAmount = 0.0
  try {
    if (tokenAddress) {

      //get token price in USD
      const tokenPrice = await getTokenPriceInUSDByTokenAddress(tokenAddress)
      //USD ~= BUSD 
      const estimatedTokenReceive = parseFloat(tokenAmountToSell) / parseFloat(tokenPrice)
      estimatedConversionAmount = estimatedTokenReceive
      return estimatedConversionAmount
    } else {
      return estimatedConversionAmount
    }
  } catch (error) {
    console.log("ERROR while fetching pcs currency conversion : ", error)
    return estimatedConversionAmount
  }
}

export const getEstimatedTokenAmountForBNB = async (tokenAddress, tokenAmountToSell) => {
  let estimatedConversionAmount = 0.0
  try {
    if (tokenAddress) {

      //get token price in USD
      const tokenPrice = await getTokenPriceInUSDByTokenAddress(tokenAddress)
      const bnbPRice = await getBNBPrice()
      const usdAmountForBNB = parseFloat(tokenAmountToSell) * parseFloat(bnbPRice)
      const estimatedTokenReceive = parseFloat(usdAmountForBNB) / parseFloat(tokenPrice)
      // estimatedConversionAmount = (estimatedTokenReceive / 100)
      estimatedConversionAmount = (estimatedTokenReceive)
      return estimatedConversionAmount
    } else {
      return estimatedConversionAmount
    }
  } catch (error) {
    console.log("ERROR while fetching pcs currency conversion : ", error)
    return estimatedConversionAmount
  }
}

export const getBNBPrice = async () => {
  let bnbPrice = 0.0
  try {
    const provider = getWeb3PRovider()
    const pcsRouterABI = JSON.parse(commonConfigs.pancakeSwap.pcsRouterABI)
    const pcsRouterAddress = commonConfigs.pancakeSwap.routerAddress
    const pcsRouterContractInstance = new ethers.Contract(pcsRouterAddress, pcsRouterABI, provider)

    const bnbToSell = utils.parseEther("1")
    const wBNBAddress = process.env.REACT_APP_WBNB_ADDRESS
    const usdtAddress = process.env.REACT_APP_BUSD_ADDRESS

    const BNBPriceResponse = await pcsRouterContractInstance.getAmountsOut(bnbToSell, [wBNBAddress, usdtAddress])
    const bnbPriceFormatted = utils.formatEther(BNBPriceResponse[1])
    bnbPrice = bnbPriceFormatted.toString()
    return bnbPrice
  } catch (error) {
    console.log("ERROR while calculating BNB Price : ", error)
    return bnbPrice
  }
}

export const getTokenPriceInUSDByTokenAddress = async (tokenAddress) => {
  let tokenPrice = 0.0
  try {
    const provider = getWeb3PRovider()
    if (tokenAddress) {
      const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
      const tokenDecimalString = await contractInstance.decimals()
      const tokenDecimals = parseInt(tokenDecimalString)

      const tokensToSell = utils.parseUnits("1", tokenDecimals)

      //create pcs router instance 
      const pcsRouterABI = JSON.parse(commonConfigs.pancakeSwap.pcsRouterABI)
      const pcsRouterAddress = commonConfigs.pancakeSwap.routerAddress
      const pcsRouterContractInstance = new ethers.Contract(pcsRouterAddress, pcsRouterABI, provider)

      const wBNBAddress = process.env.REACT_APP_WBNB_ADDRESS
      const priceOfTokenResponse = await pcsRouterContractInstance.getAmountsOut(tokensToSell, [tokenAddress, wBNBAddress])
      const formattedTokenPrice = utils.formatEther(priceOfTokenResponse[1])
      const tokenPriceInBNB = formattedTokenPrice.toString()
      const bnbPrice = await getBNBPrice()
      tokenPrice = parseFloat(tokenPriceInBNB) * parseFloat(bnbPrice)
      return tokenPrice
    } else {
      return tokenPrice
    }
  } catch (error) {
    console.error("ERROR while fetching token price : ", error)
    return tokenPrice
  }
}

export const tokenApproval = async (spenderAddress, approvalTokenAddress, approvalTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()

    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const contractInstance = new ethers.Contract(approvalTokenAddress, eRC20ContractABI, provider)
    const contractInstanceWithSigner = contractInstance.connect(signer)
    const tokenDecimalString = await contractInstanceWithSigner.decimals()
    const tokenDecimals = parseInt(tokenDecimalString)

    //set maximum token decimals before passing to the approval function
    const formattedApprovalAmount = parseFloat(approvalTokenAmount.toString()).toFixed(tokenDecimals)
    const approvalTokenAmountFormatted = utils.parseUnits(formattedApprovalAmount.toString(), tokenDecimals)

    const approvalReceipt = await contractInstanceWithSigner.approve(spenderAddress, approvalTokenAmountFormatted)
    const result = await approvalReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to approve the token. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const tokenApprovalBigNumber = async (spenderAddress, approvalTokenAddress, approvalTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()

    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const contractInstance = new ethers.Contract(approvalTokenAddress, eRC20ContractABI, provider)
    const contractInstanceWithSigner = contractInstance.connect(signer)
    const approvalReceipt = await contractInstanceWithSigner.approve(spenderAddress, approvalTokenAmount)
    const result = await approvalReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to approve the token. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const generateEventSignatureHash = (eventSignature) => {
  try {
    const eventSignatureInBytes = utils.toUtf8Bytes(eventSignature)
    const eventSignatureHash = utils.keccak256(eventSignatureInBytes)
    return eventSignatureHash
  } catch (error) {
    console.log("ERROR while generating event signature hash")
    return null
  }
}

export const getCirculationSupplyOfAToken = async (tokenAddress) => {
  try {
    const provider = getWeb3PRovider()

    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
    const totalSupply = await contractInstance.totalSupply()

    const burnAddressBalance1 = await contractInstance.balanceOf('0x000000000000000000000000000000000000dead')
    const burnAddressBalance2 = await contractInstance.balanceOf('0x0000000000000000000000000000000000000000')

    const totalBurnedValue = burnAddressBalance1.add(burnAddressBalance2)
    const circulationSupply = totalSupply.sub(totalBurnedValue)

    const tokenDecimals = await contractInstance.decimals()

    const circulationSupplyFormatted = utils.formatUnits(circulationSupply, parseInt(tokenDecimals.toString()))
    const result = circulationSupplyFormatted.toString()
    return parseFloat(result)

  } catch (error) {
    console.log('error while calculating circulation supply ', error)
    return 0
  }
}

export const getMaxWalletCount = async () => {

  let maxWalletCount = 0

  try {
    const provider = getWeb3PRovider()
    
    const airDropContractAddress = commonConfigs.airDrop.contractAddress
    const airDropContractABI = JSON.parse(commonConfigs.airDrop.contractABI)
    const airDropContractInstance = new ethers.Contract(airDropContractAddress, airDropContractABI, provider)
    
    const maxWalletCountResponse = await airDropContractInstance.maxWalletsPerTransaction()
     // eslint-disable-next-line
    const maxWalletCountFormatted = maxWalletCountResponse.toString()
    
    maxWalletCount = maxWalletCountResponse

    return maxWalletCount
  } catch (error) {

    maxWalletCount = 0
    return maxWalletCount
  }
}

export const airDropTokens = async (contractList, tokenAddress, signer) => {

  try {

    const provider = getWeb3PRovider()
    const eRC20ContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
    const contractInstanceWithSigner = contractInstance.connect(signer)
    const tokenDecimalString = await contractInstanceWithSigner.decimals()
    const tokenDecimals = parseInt(tokenDecimalString)
    
    const airDropContractAddress = commonConfigs.airDrop.contractAddress
    const airDropContractABI = JSON.parse(commonConfigs.airDrop.contractABI)
    const airDropContractInstance = new ethers.Contract(airDropContractAddress, airDropContractABI, provider)
    const airDropContractInstanceWithSigner = airDropContractInstance.connect(signer)
    
    const feeAmountResponse = await airDropContractInstanceWithSigner.feeAmount()
    const payableAmount = utils.formatEther(feeAmountResponse)
    
    let amountArray = []
    let walletAddressArray = []
    contractList.forEach((item) => {
      
      // let amountWithDecimals = parseFloat(item.value) * (10 ** tokenDecimals)
      let amountWithDecimals = utils.parseUnits(item.value.toString(), tokenDecimals)
      amountArray.push(amountWithDecimals.toString())
      walletAddressArray.push(item.contractAddress)
    })
    
    const airDropReceipt = await airDropContractInstanceWithSigner.airDropTokens(
      tokenAddress, 
      walletAddressArray, 
      amountArray, {
        value: utils.parseEther(payableAmount.toString()),
        // gasLimit: String(6000000)
      })
    const result = await airDropReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to airdrop the tokens. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    console.log('error', error);
    throw errorMessage
    // return errorMessage
  }

}