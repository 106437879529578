import { commonConfigs } from '../configs/common.configs'
import { shillingConfigs } from '../configs/shilling.configs'
import { stakingConfigs } from '../configs/staking.configs'
import { weatherConfigs } from '../configs/weather.configs'
import { getWeb3PRovider } from './util.service'
import { ethers } from 'ethers'

export const getCommonABI = () => {
  try {
    const commonContractABI = JSON.parse(commonConfigs.commonABI.contractABI)

    return commonContractABI
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to read commonContractABI. Please try again'

    throw errorMessage
  }
}

export const getFathomTokenContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(shillingConfigs.fathomContractABI)
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}


export const getFathomStakingContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(stakingConfigs.stakingContractABI)
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}

export const getFathomStakingOceanContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(stakingConfigs.oceanContractABI)
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}

export const getFathomShillingContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(shillingConfigs.shillingContractABI)
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}


export const getFathomShillingSeaContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(shillingConfigs.shillingOceanContractABI)
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}

export const getFathomWeatherContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(weatherConfigs.weatherContractABI)
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}

export const getFathomAirdropContractABIFunctions = () => {
  let readFunctionsList = []
  let writeFunctionList = []
  const fathomTokenContractABI = JSON.parse(commonConfigs.airDrop.contractABI)
  console.log('fathomTokenContractABI', fathomTokenContractABI);
  readFunctionsList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability === 'view')
  writeFunctionList = fathomTokenContractABI.filter(item => item.type === 'function' && item.stateMutability !== 'view')
  return {
    readFunctionsList,
    writeFunctionList

  }
}

export const executeInitialStakeCSVProcessing = async (
  csvFirstColumnArray,
  csvSecondColumnArray,
  csvThirdColumnArray,
  csvForthColumnArray,
  signer
) => {
  try {

    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress

    const contractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const contractInstanceWithSigner = contractInstance.connect(signer)
    const writeReceipt = await contractInstanceWithSigner.initializeStakers(csvFirstColumnArray, csvSecondColumnArray, csvThirdColumnArray, csvForthColumnArray)
    const result = await writeReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to execute CSV file on blockchain. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const ChangeStakeTime = async (
  csvThirdColumnArray,
  csvForthColumnArray,
  signer
) => {
  try {

    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress

    const contractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const contractInstanceWithSigner = contractInstance.connect(signer)
    const writeReceipt = await contractInstanceWithSigner.changeStakeTime(csvThirdColumnArray, csvForthColumnArray)
    const result = await writeReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to execute CSV file on blockchain. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}
