import React, { useEffect, useState } from 'react'
import { Card, Tooltip, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import DataChart from '../../Components/DataChart/DataChart';
import { HashScroll } from "react-hash-scroll";
import { 
    getWeatherPercentage, 
    getNextBuyBackAt,
    getTotalFathomPurchased,
    getIsBuyBackFromBnb
} from '../../Blockchain/services/weather.service'
import NumberFormat from 'react-number-format'
import Countdown from 'react-countdown'
import { DateTime } from 'luxon'
import { 
    getTokenPriceInUSDByTokenAddress,
    getUserBNBBalance,
    getEstimatedTokenAmountForBNB,
    getBEP20TokenBalanceByWalletAddress
 } from '../../Blockchain/services/util.service'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'
import { weatherConfigs } from '../../Blockchain/configs/weather.configs';

function Weather() {

    const fathomTokenAddress = stakingConfigs.fathomTokenAddress
    const weatherContractAddress = weatherConfigs.weatherContractAddress

    const [isPercentageLoading, setIsPercentageLoading] = useState(false)
    const [weatherPercentage, setWeatherPercentage] = useState(0.0)

    const [isNextBuyBackTimeLoading, setIsNextBuyBackTimeLoading] = useState(false)
    const [nextBuyBackTime, setNextBuyBackTime] = useState(DateTime.now())

    const [isFathomPurchasedLoading, setIsFathomPurchasedLoading] = useState(false)
    const [totalFathomPurchased, setTotalFathomPurchased] = useState(0.0)

    const [isFathomTokenPriceLoading, setIsFathomTokenPriceLoading] = useState(false)
    const [fathomTokenPriceInUSD, setFathomTokenPriceInUSD] = useState(0)
    const [totalPurchasedValueInUSD, setTotalPurchasedValueInUSD] = useState(0)

    // eslint-disable-next-line
    const [isBuyBackFromBnbLoading, setIsBuyBackFromBnbLoading] = useState(false)
    const [isBuyBackFromBnb, setIsBuyBackFromBnb] = useState(false)

    // eslint-disable-next-line
    const [isUserTokenBalanceLoading, setIsUserTokenBalanceLoading] = useState(false)

    const [bnbBalance, setBnbBalance] = useState(0.0)
    const [busdTokenBalance, setBusdTokenBalance] = useState(0.0)
    const [sizeOfNextBuyBack, setSizeOfNextBuyBack] = useState(0.0)
    const [isTokenAmountEstimationLoading, setIsTokenAmountEstimationLoading] = useState(0.0)

    const [sizeOfNextBuyBackUSDValue, setSizeOfNextBuyBackUSDValue] = useState(0.0)

    const fetchPercentage = async () => {
        setIsPercentageLoading(true)
        const percentage = await getWeatherPercentage()

        if (percentage) {
            const percentageValue = parseFloat(percentage) / 100
            setWeatherPercentage(percentageValue)
        }
        
        setIsPercentageLoading(false)
    }

    const fetchNextBuyBackTime = async () => {
        setIsNextBuyBackTimeLoading(true)
        const nextBuyBackTimeResponse = await getNextBuyBackAt()

        if (nextBuyBackTimeResponse) {
            const buyBackTime = DateTime.fromSeconds(parseInt(nextBuyBackTimeResponse))
            setNextBuyBackTime(buyBackTime)
        } else {
            setNextBuyBackTime(DateTime.now())
        }
        
        setIsNextBuyBackTimeLoading(false)
    }

    const fetchTotalFathomPurchased = async () => {
        setIsFathomPurchasedLoading(true)
        const fathomPurchased = await getTotalFathomPurchased()
        setTotalFathomPurchased(fathomPurchased)
        setIsFathomPurchasedLoading(false)
    }

    const fetchFathomTokenPrice = async () => {
        setIsFathomTokenPriceLoading(true)
        const tokenPrice = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
        console.log('tokenPrice', tokenPrice);
        setFathomTokenPriceInUSD(tokenPrice)
        setIsFathomTokenPriceLoading(false)
    }

    const fetchIsBuyBackFromBnb = async () => {
        setIsBuyBackFromBnbLoading(true)
        const buyBackFromBnbResponse = await getIsBuyBackFromBnb()
        setIsBuyBackFromBnb(buyBackFromBnbResponse)
        setIsBuyBackFromBnbLoading(false)
    }

    const fetchBnbBalance = async () => {
        setIsUserTokenBalanceLoading(true)

        try {
            const bnbBalance = await getUserBNBBalance(weatherContractAddress)
            setBnbBalance(bnbBalance)

            setIsUserTokenBalanceLoading(false)
        } catch (error) {
            setBnbBalance(0.0)
            setIsUserTokenBalanceLoading(false)
            console.error("ERROR while fetching user bnb balance : ", error)
        }
        

    }

    const fetchBusdBalance = async () => {

        const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDRESS
        setIsUserTokenBalanceLoading(true)
        try {
            const busdTokenBalance = await getBEP20TokenBalanceByWalletAddress(BUSD_ADDRESS, weatherContractAddress)
            setBusdTokenBalance(busdTokenBalance)
            setIsUserTokenBalanceLoading(false)
        } catch (error) {
            setBusdTokenBalance(0.0)
            setIsUserTokenBalanceLoading(false)
            console.error("ERROR while fetching user busd token balance : ", error)
        }

    }

    useEffect(() => {

        if (isBuyBackFromBnb) {
            if (weatherPercentage && bnbBalance) {
                const buyBackBnbPercentage = parseFloat(bnbBalance) * parseFloat(weatherPercentage)
    
                const fetchData = async () => {
                    setIsTokenAmountEstimationLoading(true)
                    const currencyQuoteResponse = await getEstimatedTokenAmountForBNB(fathomTokenAddress, buyBackBnbPercentage)
                    setSizeOfNextBuyBack(currencyQuoteResponse)
                    setIsTokenAmountEstimationLoading(false)
                }
    
                if (buyBackBnbPercentage > 0) {
                    fetchData()
                } else {
                    setSizeOfNextBuyBack(0.0)
                }
                  
            } else {
                setSizeOfNextBuyBack(0.0)
            }
        } else {
            if (weatherPercentage && busdTokenBalance) {
                const buyBackBusdPercentage = parseFloat(busdTokenBalance) * parseFloat(weatherPercentage)
    
                const fetchData = async () => {
                    setIsTokenAmountEstimationLoading(true)
                    const currencyQuoteResponse = await getEstimatedTokenAmountForBNB(fathomTokenAddress, buyBackBusdPercentage)
                    setSizeOfNextBuyBack(currencyQuoteResponse)
                    setIsTokenAmountEstimationLoading(false)
                }
    
                if (buyBackBusdPercentage > 0) {
                    fetchData()
                } else {
                    setSizeOfNextBuyBack(0.0)
                }
            } else {
                setSizeOfNextBuyBack(0.0)
            }
        }
        // eslint-disable-next-line
    }, [bnbBalance, weatherPercentage, busdTokenBalance, isBuyBackFromBnb])

    useEffect(() => {
        fetchPercentage()
        fetchNextBuyBackTime()
        fetchTotalFathomPurchased()
        fetchFathomTokenPrice()
        fetchIsBuyBackFromBnb()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (fathomTokenPriceInUSD && totalFathomPurchased) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(totalFathomPurchased)
            setTotalPurchasedValueInUSD(value)
        } else {
            setTotalPurchasedValueInUSD(0)
        }

        if (fathomTokenPriceInUSD && sizeOfNextBuyBack) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(sizeOfNextBuyBack)
            setSizeOfNextBuyBackUSDValue(value)
        } else {
            setSizeOfNextBuyBackUSDValue(0)
        }
    }, [fathomTokenPriceInUSD, totalFathomPurchased, sizeOfNextBuyBack])

    useEffect(() => {
        if (isBuyBackFromBnb) {
            fetchBnbBalance()
        } else {
            fetchBusdBalance()
        }
        // eslint-disable-next-line
    }, [isBuyBackFromBnb])

    const graphData = [
        {
            'Date': '2022-09-21',
            '$USD': 82.2820
        },
        {
            'Date': '2022-09-22',
            '$USD': 92.2820
        },
        {
            'Date': '2022-09-23',
            '$USD': 102.2820
        },
        {
            'Date': '2022-09-26',
            '$USD': 122.2820
        },
    ]

  return (
    <div className='mb-5'>
        <Card className='mt-5 transparent'>
            <HashScroll hash="#weather" position='center'>
                <h3 className='text-center text-uppercase'>Weather</h3>
            </HashScroll>
        </Card>

        <Card className='fathom-finance-card col-lg-9 col-md-12 mx-auto mb-3'>
            <Row>
                <div className='fw-bold text-center'>
                    <span className="card-title">
                        {
                            isPercentageLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={weatherPercentage}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            )
                        }
                        &nbsp;of the Weather Used in Next Buy Back </span>
                    &nbsp;
                    <span className="card-title">
                        {
                            isNextBuyBackTimeLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <Countdown
                                    key={nextBuyBackTime.toString()}
                                    date={nextBuyBackTime.toString()}
                                    renderer={props => (
                                        < >
                                            <span >
                                                {props.formatted.days}
                                            </span>
                                            :
                                            <span>
                                                {props.formatted.hours}
                                            </span>
                                            :
                                            <span>
                                                {props.formatted.minutes}

                                            </span>
                                            :
                                            <span>
                                                {props.formatted.seconds}
                                            </span>
                                        </>
                                    )}
                                    zeroPadTime={2}
                                />    
                            )
                        }    
                    </span>
                </div>  
            </Row>
        </Card>

        <Row className='mb-3'>
            <Col lg="4" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<Tooltip title="Value Sent to the Jetstream"><span className="card-title">Value Sent to the Jetstream</span></Tooltip>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isFathomPurchasedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalFathomPurchased}
                                    thousandSeparator={true}
                                    decimalScale={4}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Total: </span>
                    <span className='card-inner-text'>
                        {
                            isFathomPurchasedLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalPurchasedValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            {/* <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<Tooltip title="Value Used by the Weather"><span className="card-title">Value Used by the Weather</span></Tooltip>}>
                    <span className='primary-text card-inner-text'>24hr: </span> <span className='card-inner-text'>$23,867</span>
                    <br />
                    <span className='primary-text card-inner-text'>Total: </span> <span className='card-inner-text'>$45,412</span>
                </Card>
            </Col> */}

            <Col lg="4" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<Tooltip title="$Fathom Bought by the Weather"><span className="card-title">$Fathom Bought by the Weather</span></Tooltip>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>
                        {
                            isFathomPurchasedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalFathomPurchased}
                                    thousandSeparator={true}
                                    decimalScale={4}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>
                    <span className='card-inner-text'>
                        {
                            isFathomPurchasedLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalPurchasedValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    </span>
                </Card>
            </Col>

            <Col lg="4" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<Tooltip title="Size of Next Buy Back"><span className="card-title">Size of Next Buy Back</span></Tooltip>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span>
                    <span className='card-inner-text'>
                        {
                            isTokenAmountEstimationLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={sizeOfNextBuyBack}
                                    thousandSeparator={true}
                                    decimalScale={4}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>
                        {
                            isTokenAmountEstimationLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={sizeOfNextBuyBackUSDValue}
                                    thousandSeparator={true}
                                    decimalScale={4}
                                    displayType={'text'}
                                    prefix="$"
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>
        </Row>

        <Card className='fathom-finance-card mt-3'>
            <h6 className='text-center card-title'>Timeline of the Value Contained by the Weather</h6>
            <hr />

            <DataChart data={graphData} xAxis="Time Range" yAxis="$USD Value of the Weather" xField="Date" yField="$USD" />
        </Card>
    </div>
  )
}

export default Weather