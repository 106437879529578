import React from 'react';
import './CandleStick.css';

function CandleStickChart() {
  return (
    <div>
      <div className="dexscreener-embed">
        <iframe title='candlestick-cart' src="https://dexscreener.com/bsc/0x2adcda5be92f4763f37f733ba9afee2a4c04ca52?embed=1&theme=dark&trades=1&info=0"></iframe>
      </div>
    </div>
  );
}

export default CandleStickChart;
