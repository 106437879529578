import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import Logo from '../../images/fflogo.png';
import { Menu } from 'react-feather';
import MobileMenu from '../MobileMenu/MobileMenu';
import { open_menu } from '../../Redux/MobileMenuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { change } from '../../Redux/SideMenuSlice';
import WalletConnectWidget from '../WalletConnectWidget';
import { useAccount } from 'wagmi';

function NavBar() {
  const adminAccounts = process.env.REACT_APP_ADMINS_ACCOUNT;
  const [isAdmin, setIsAdmin] = useState(false);
  const [hamburgerClass, setHamburgerClass] = useState('hamburger');
  const { address: account } = useAccount();

  const [hamburgerMenuRightMargin, setHamburgerMenuRightMargin] = useState(
    'hamburger-menu-right-margin-default'
  );

  const dispatch = useDispatch();
  const { menu_opened } = useSelector((state) => state.mobileMenu);

  // check is admin
  useEffect(() => {
    let admins = adminAccounts ? adminAccounts.split(',') : null;
    if (account && admins?.length > 0) {
      const result = admins.find(
        (item) => item.toLowerCase() === account.toLowerCase()
      );
      if (result) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, [account, adminAccounts]);

  const handleSideMenu = () => {
    // when hamburger menu is closed
    if (hamburgerClass === 'hamburger') {
      setHamburgerClass('hamburger is-active');
      setHamburgerMenuRightMargin('hamburger-menu-right-margin-extended');
    } else {
      // when hamburger menu is opened
      setHamburgerClass('hamburger');
      setHamburgerMenuRightMargin('hamburger-menu-right-margin-default');
    }
    dispatch(change());
  };

  const handleShowMobileMenu = () => {
    dispatch(open_menu());
  };

  return (
    <nav className="navbar d-flex justify-content-between fixed-top navbar-light pt-3 pb-3">
      <div
        className="container-fluid"
        style={{ marginLeft: '15px', marginRight: '15px' }}
      >
        {/* site logo to show on large devices in left side -> will hidden on small devices */}
        <div className="navbar-brand hide-on-mobile">
          {/* <Menu className='text-light' onClick={() => dispatch(change())} style={{marginRight: '10px'}} /> */}

          <div
            className={`hamburger-menu-margin three hamburger-col hide-on-mobile ${hamburgerMenuRightMargin}`}
            style={{ marginTop: '12px' }}
          >
            <div
              className={hamburgerClass}
              id="hamburger-10"
              onClick={() => handleSideMenu()}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>

          <Link to="/">
            <img
              src={Logo}
              alt="main_logo"
              style={{ width: '50px', borderRadius: '50%' }}
            />
          </Link>
        </div>

        {/* When clicked, mobile sidemenu will appear */}
        {!menu_opened ? (
          <Menu className="hide-on-pc" onClick={handleShowMobileMenu} />
        ) : (
          <div></div>
        )}

        {/* header menu links to show on large devices in center -> will hide on small devices */}
        <div className="d-flex hide-on-mobile center">
          {isAdmin ? (
            <p className="fs-6">
              <Link to="/admin" className="navbarlink">
                Admin
              </Link>
            </p>
          ) : (
            <></>
          )}
        </div>

        {/* site logo to show on small devices in center -> will hide on large devices */}
        {/* <div className="center hide-on-pc">
          <Link to="/">
            <img
              src={Logo}
              alt="main_logo"
              style={{ width: '40px', borderRadius: '50%' }}
            />
          </Link>
        </div> */}

        {/* wallet connect button & hamburger menu icon to show on large devices in right side -> wallet connect button will hide on small devices */}
        <div className="text-end">
          <WalletConnectWidget />
        </div>

        {/* Mobile side menu will show up when hamburger menu icon clicked */}
        {menu_opened && <MobileMenu />}
      </div>
    </nav>
  );
}

export default NavBar;
