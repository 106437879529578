import React from 'react'
import { Area } from '@ant-design/plots';

function DataChart(props) {

    const { data, xAxis, yAxis, xField, yField } = props

    const config = {
        data,
        xField: xField ? xField : '',
        yField: yField ? yField : '',
        theme: {
            styleSheet: {
                fontFamily: 'PT Sans Caption'
            }
        },
        xAxis: {
            title: {
                text: xAxis ? xAxis : '',
                style: {
                    fontSize: 16,
                    fontWeight: 600,
                }
            },
            range: [0, 1]
        },
        areaStyle: {
            fill: '#00b0f2'
        },
        line: {
            color: '#00b0f2'
        },
        yAxis: {
            title: {
                text: yAxis ? yAxis : '',
                style: {
                    fontSize: 16,
                    fontWeight: 600,
                }
            },
            grid: {
                line: {
                    style: {
                        stroke: 'black',
                        strokeOpacity: 0.1,
                        shadowColor: 'black',
                        shadowBlur: 10,
                        shadowOffsetX: 5,
                        shadowOffsetY: 5,
                        cursor: 'pointer'
                    }
                }
            }
        },
        animation: true,
        slider: {
            start: 0.0,
            end: 1.0,
            trendCfg: {
                isArea: true,
            },
        },
        height: 350,
        smooth: true
    };


    return (
        <Area {...config} />
    )
}

export default DataChart