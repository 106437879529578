import React from 'react'
import { Layout } from 'antd';
import MenuItems from '../Menu/MenuItems';
import { useSelector } from "react-redux";

function SiderComponent() {
    const { Sider } = Layout;
    const { margin } = useSelector((state) => state.sideMenu);

    return (
        // side menu including menu
        <>
            <Sider
                width={250}
                collapsed={!margin}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                className="sider-background"
            >
                <div style={{marginTop: '90px'}}>
                    <MenuItems />
                </div>
            </Sider>
        </>
    )
}

export default SiderComponent