import {
    Card,
    Row,
    Col,
    Collapse,
    Button,
    Input,
    Spin,
    notification,
    Select
} from 'antd'

import { useEffect, useState } from 'react'
import { getWeb3PRovider } from '../../Blockchain/services/util.service'
import { ethers } from 'ethers'
import parse from 'html-react-parser'
import CSVUploader from './CSVUploader'
import ChangeStakeTimeCSVUploader from './ChangeStakeTimeCSVUploader'
import { useAccount, useSigner } from 'wagmi';

function WriteFunctions(props) {

    const { Option } = Select;

    const { writeFunctions, contractABI, contractAddress, tabName } = props

    const { Panel } = Collapse

    const [inputArguments, setInputArguments] = useState([])
    const [inputBoolArguments, setInputBoolArguments] = useState(true)
    const [isBoolFunction, setIsBoolFunction] = useState(false)
    const [isFunctionCallLoading, setIsFunctionCallLoading] = useState([])
    const [resultView, setResultView] = useState([])
    const [isStakingTab, setIsStakingTab] = useState(false)
    const [tokenAddresses, setTokenAddresses] = useState([])
    const [blacklistUnblockTime, setBlacklistUnblockTime] = useState([])

    const { address: account } = useAccount()
    const { data: signer } = useSigner();

    const handleBlacklistUsers = () => async () => {
        if (!account) {
            notification['error']({
                message: 'Authentication Error',
                description:
                    'Please connect your wallet to proceed',
            })
        }
        try {
            const provider = getWeb3PRovider()
            const contractInstance = new ethers.Contract(contractAddress, contractABI, provider)
            const contractInstanceWithSigner = contractInstance.connect(signer)
            const writeReceipt = await contractInstanceWithSigner.manageBlacklistUsers(tokenAddresses, blacklistUnblockTime)

            const result = await writeReceipt.wait()
            console.log("TX SUCCESS ", result)

            notification['success']({
                message: 'Success',
                description:
                    'Transaction has been completed',
            })
        } catch (error) {
            let errorMessage = 'Something went wrong while trying to execute the transaction'
            if (error && error.message) {
                errorMessage = error.message
            }
            if (error && error.reason && error.reason !== '') {
                errorMessage = error.reason
            }
            console.log("WRITE ERROR ", error)
            // setResultView(prev => ({ ...prev, [functionName]: 'error' }))
            // setIsFunctionCallLoading(prev => ({ ...prev, [functionName]: false }))
            notification['error']({
                message: 'Execution Error',
                description: errorMessage
            })
        }
    }

    const handleQuery = abiData => async () => {

        if (!account) {
            notification['error']({
                message: 'Authentication Error',
                description:
                    'Please connect your wallet to proceed',
            })

        }

        //decode input values
        const functionName = abiData.name
        const argumentLength = abiData.inputs.length
        const argumentArray = []
        for (let i = 0; i < argumentLength; i++) {
            const key = functionName + i
            const filteredInput = inputArguments[key]
            argumentArray.push(filteredInput)
        }
        setIsFunctionCallLoading(prev => ({ ...prev, [functionName]: true }))

        try {
            //create web3 instance
            const provider = getWeb3PRovider()
            const contractInstance = new ethers.Contract(contractAddress, contractABI, provider)
            const contractInstanceWithSigner = contractInstance.connect(signer)
            // console.log('functionName', functionName);
            // console.log('argumentArray', argumentArray);
            // console.log('inputBoolArguments', inputBoolArguments);

            let writeReceipt

            if (isBoolFunction) {
                writeReceipt = await contractInstanceWithSigner[functionName](...argumentArray)
            } else {
                writeReceipt = await contractInstanceWithSigner[functionName](inputBoolArguments)
            }

            const result = await writeReceipt.wait()
            console.log("TX SUCCESS ", result)

            const htmlResult = `<a 
                href=${process.env.REACT_APP_BLOCK_EXPLORER}/tx/${result?.transactionHash}
                target="_blank"
                className="view-your-tx"
            >
            View your transaction<a>`
            setResultView(prev => ({ ...prev, [functionName]: htmlResult }))
            setIsFunctionCallLoading(prev => ({ ...prev, [functionName]: false }))
            notification['success']({
                message: 'Success',
                description:
                    'Transaction has been completed',
            })
        } catch (error) {
            let errorMessage = 'Something went wrong while trying to execute the transaction'
            if (error && error.message) {
                errorMessage = error.message
            }
            if (error && error.reason && error.reason !== '') {
                errorMessage = error.reason
            }
            console.log("WRITE ERROR ", error)
            setResultView(prev => ({ ...prev, [functionName]: 'error' }))
            setIsFunctionCallLoading(prev => ({ ...prev, [functionName]: false }))
            notification['error']({
                message: 'Execution Error',
                description: errorMessage
            })
        }
    }

    const handleBlacklistUserChange = (e) => {
        const blacklistArray = []
        if (e) {
            blacklistArray.push(e.target.value)
        }

        setTokenAddresses(blacklistArray)
    }

    const handleBlacklistTimeChange = (e) => {
        const blacklistTimeArray = []
        if (e) {
            blacklistTimeArray.push(e.target.value)
        }

        setBlacklistUnblockTime(blacklistTimeArray)
    }

    const handleChange = (e) => {
        if (e.target) {
            const { value, id } = e.target
            setInputArguments(prev => ({ ...prev, [id]: value }))
            setIsBoolFunction(true)
        } else {
            setInputBoolArguments(e)
            setIsBoolFunction(false)
        }
    }

    const buildInputRows = (itemName, inputArgs) => {
        const inputArray = []
        for (let i = 0; i < inputArgs.length; i++) {

            inputArray.push(
                <div className='mt-1'>
                    <span key={i}>{inputArgs[i].name}</span>
                    {
                        inputArgs[i].type === 'bool' ? (
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                placeholder="select true or false"
                                defaultValue={true}
                                onChange={handleChange}
                                optionLabelProp="label"
                            >
                                <Option value={true} label="true">true</Option>
                                <Option value={false} label="false">false</Option>
                            </Select>
                        ) : (
                            <Input
                                placeholder={inputArgs[i].type}
                                onChange={handleChange} key={itemName + i} id={itemName + i} />
                        )
                    }

                </div>
            )
        }

        return inputArray
    }

    useEffect(() => {
        if (tabName) {
            if (tabName === 'Staking') {
                setIsStakingTab(true)
            } else {
                setIsStakingTab(false)
            }
        }
    }, [tabName])

    return (
        <div>
            {
                isStakingTab ? (
                    <>
                        <CSVUploader />
                        <ChangeStakeTimeCSVUploader />
                    </>
                ) : (
                    <></>
                )
            }

            {
                writeFunctions ? (
                    <Row>

                        <Col span={24}>

                            {
                                writeFunctions.map((item, index) => (
                                    <Card className='fathom-finance-card mt-3'>
                                        <Collapse defaultActiveKey={['1']}>
                                            {
                                                item.name === 'manageBlacklistUsers' ? (
                                                    <Panel header={item.name} key="3">
                                                        <div className='mt-1'>{item.inputs[0].name}</div>
                                                        <Input onChange={handleBlacklistUserChange} placeholder={item.inputs[0].type} />

                                                        <div className='mt-1'>{item.inputs[1].name}</div>
                                                        <Input onChange={handleBlacklistTimeChange} placeholder={item.inputs[1].type} />
                                                        <Button
                                                            size='small'
                                                            loading={isFunctionCallLoading[item.name]}
                                                            style={{ padding: '0px 20px' }}
                                                            onClick={handleBlacklistUsers(item)}
                                                            className='nutgain-primary-button mt-3'>
                                                            Write
                                                        </Button>
                                                    </Panel>


                                                ) : (
                                                    <Panel header={item.name} key="3">
                                                        {
                                                            item.inputs ? (
                                                                <>
                                                                    {
                                                                        buildInputRows(item.name, item.inputs)
                                                                    }
                                                                </>

                                                            ) : (
                                                                <></>
                                                            )
                                                        }

                                                        {
                                                            isFunctionCallLoading[item.name] ? (
                                                                <div className='result-container mt-3'>
                                                                    <Spin size='small' />
                                                                </div>

                                                            ) : (
                                                                <div className='result-container mt-3'>
                                                                    {parse(resultView[item.name] ? resultView[item.name] : '')}
                                                                </div>
                                                            )
                                                        }

                                                        <Button
                                                            size='small'
                                                            loading={isFunctionCallLoading[item.name]}
                                                            style={{ padding: '0px 20px' }}
                                                            onClick={handleQuery(item)}
                                                            className='nutgain-primary-button mt-3'>
                                                            Write
                                                        </Button>

                                                    </Panel>
                                                )
                                            }

                                        </Collapse>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>

                ) : (
                    <></>
                )
            }
        </div>
    )
}

export default WriteFunctions