import React, {useState, useEffect} from 'react'
import { Card, Spin } from 'antd'
import { Row, Col, Spinner } from 'reactstrap'
import {AreaChartOutlined, DollarCircleOutlined, UsergroupAddOutlined} from '@ant-design/icons'
import { getTotalSupply, getMaxTransaction } from '../../Blockchain/services/shilling.service'
import { getTotalStakeChargeCollected } from '../../Blockchain/services/staking.service'
import {
    getTokenPriceInUSDByTokenAddress,
    getCirculationSupplyOfAToken
} from '../../Blockchain/services/util.service'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'
import NumberFormat from 'react-number-format'
import axios from 'axios'

function Metrics() {

    const fathomTokenAddress = stakingConfigs.fathomTokenAddress

    const[isTotalSupplyLoading, setIsTotalSupplyLoading] = useState(false)
    const[totalSupplyFathom, setTotalSupplyFathom] = useState(0.0)
    
    const[isTotalStakeChargeCollectedLoading, setIsTotalStakeChargeCollectedLoading] = useState(false)
    const[totalStakeChargeCollectedFathom, setTotalStakeChargeCollectedFathom] = useState(0.0)

    const[isMaxTxLoading, setIsMaxTxLoading] = useState(false)
    const[maxTransaction, setMaxTransaction] = useState(0.0)

    const[transactionPercentage, setTransactionPercentage] = useState(0.0)

    const [fathomTokenPriceInUSD, setFathomTokenPriceInUSD] = useState(0)
    const [isFathomTokenPriceLoading, setIsFathomTokenPriceLoading] = useState(false)

    const [tokenCirculationSupply, setTokenCirculationSupply] = useState(0)
    const [isTokenCirculationSupplyLoading, setIsTokenCirculationSupplyLoading] = useState(false)

    const [tokenMarketCap, setTokenMarketCap] = useState(0)

    const [isHoldersCountLoading, setIsHoldersCountLoading] = useState(false)
    const [holdersCount, setHoldersCount] = useState(false)

    const fetchFathomTokenPrice = async () => {
        setIsFathomTokenPriceLoading(true)
        const tokenPrice = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
        setFathomTokenPriceInUSD(tokenPrice)
        setIsFathomTokenPriceLoading(false)
    }

    const fetchFathomCirculatingSupply = async () => {
        setIsTokenCirculationSupplyLoading(true)
        const circulatingSupply = await getCirculationSupplyOfAToken(fathomTokenAddress)
        setTokenCirculationSupply(circulatingSupply)
        setIsTokenCirculationSupplyLoading(false)
    }


    const fetchTotalSupply = async () => {
        setIsTotalSupplyLoading(true)
        const totalSupply = await getTotalSupply()
        setTotalSupplyFathom(totalSupply)
        setIsTotalSupplyLoading(false)
    }

    const fetchTotalStakeChargeCollected = async () => {
        setIsTotalStakeChargeCollectedLoading(true)
        const totalSupply = await getTotalStakeChargeCollected()
        setTotalStakeChargeCollectedFathom(totalSupply)
        setIsTotalStakeChargeCollectedLoading(false)
    }

    const fetchMaxTransaction = async () => {
        setIsMaxTxLoading(true)
        const maxTransactionValue = await getMaxTransaction()
        setMaxTransaction(maxTransactionValue)
        setIsMaxTxLoading(false)
    }
    const fetchHoldersCount = async () => {
        setIsHoldersCountLoading(true)
        try {
            const endpoint = `https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0xa84A138E5bc331fceebD2fd1B026D14D1B284b68&page=1&apikey=7TGDABHGSD72DYQFNK3HH7XQ3U86ZVUPXF`
            const response = await axios.get(endpoint)
            
            if (response) {
                const holdersCount = response.data.result.length
                setHoldersCount(holdersCount)
            }
            setIsHoldersCountLoading(false)
        } catch (error) {
            console.log("ERROR while fetching holders count", error)
            setIsHoldersCountLoading(false)
            setHoldersCount(0)
        }
    }

    useEffect(() => {
        fetchTotalSupply()
        fetchTotalStakeChargeCollected()
        fetchMaxTransaction()
        fetchFathomTokenPrice()
        fetchFathomCirculatingSupply()
        fetchHoldersCount()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (parseFloat(fathomTokenPriceInUSD) && parseFloat(tokenCirculationSupply)) {
            const mc = parseFloat(fathomTokenPriceInUSD) * parseFloat(tokenCirculationSupply)
            setTokenMarketCap(mc)
        } else {
            setTokenMarketCap(0)
        }
    }, [tokenCirculationSupply, fathomTokenPriceInUSD])

    useEffect(() => {
        if (maxTransaction && totalSupplyFathom) {
            let transactionPercentageValue = (maxTransaction / totalSupplyFathom) * 100
            setTransactionPercentage(transactionPercentageValue)
        }
    }, [totalSupplyFathom, maxTransaction])
  return (
    <div>
        <Card className='mt-5 transparent'>
            <h3 className='text-center text-uppercase'>Metrics</h3>
        </Card>

        <Card className='fathom-finance-card col-lg-10 col-md-12 mx-auto mb-3'>
            <Row>
                <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12" className='text-center my-auto'>
                    <div className='d-flex'>
                        <div className='card-icon-highlighted-light'>
                            <DollarCircleOutlined style={{fontSize: '30px'}} />
                        </div>

                        <div className='my-auto' style={{marginLeft: '10px'}}>
                            <span className='card-title'>Price: </span><br />
                            <span className='card-title'>
                                {
                                    isFathomTokenPriceLoading ? (
                                        <div className='d-flex justify-content-center'>
                                            <Spinner size='sm' />
                                        </div>
                                    ) : (
                                        <NumberFormat
                                            prefix='$ '
                                            style={{ fontSize: '18px' }}
                                            value={fathomTokenPriceInUSD}
                                            thousandSeparator={true}
                                            decimalScale={6}
                                            displayType={'text'}
                                        />
                                    )
                                }
                            </span>
                        </div>
                    </div>
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12" className='text-center my-auto'>
                    <div className='d-flex'>
                        <div className='card-icon-highlighted-light'>
                            <AreaChartOutlined style={{fontSize: '30px'}} />
                        </div>

                        <div className='my-auto' style={{marginLeft: '10px'}}>
                            <span className='card-title'>Market Cap: </span><br />
                            <span className='card-title'>
                                {
                                    isFathomTokenPriceLoading || isTokenCirculationSupplyLoading ? (
                                        <div className='d-flex justify-content-center'>
                                            <Spinner size='sm' />
                                        </div>
                                    ) : (
                                        <NumberFormat
                                            prefix='$ '
                                            style={{ fontSize: '18px' }}
                                            value={tokenMarketCap}
                                            thousandSeparator={true}
                                            decimalScale={4}
                                            displayType={'text'}
                                        />
                                    )
                                }
                            </span>
                        </div>
                    </div>
                </Col>

                <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12" className='text-center my-auto'>
                    <div className='d-flex'>
                        <div className='card-icon-highlighted-light'>
                            <UsergroupAddOutlined style={{fontSize: '30px'}} />
                        </div>

                        <div className='my-auto' style={{marginLeft: '10px'}}>
                            <span className='card-title'>Holders: </span>
                            <span className='card-title'>
                                {
                                    isHoldersCountLoading ? (
                                        <div className='d-flex justify-content-center'>
                                            <Spin />
                                        </div>
                                    ) : (
                                        <NumberFormat
                                            style={{ fontSize: '18px' }}
                                            value={holdersCount}
                                            thousandSeparator={true}
                                            decimalScale={3}
                                            displayType={'text'}
                                        />
                                    )
                                }
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>

        <Row className='mb-4'>
            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Supply</span>}>
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalSupplyLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalSupplyFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>Circulating: </span>
                     <span className='card-inner-text'>
                        {
                            isTotalStakeChargeCollectedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalStakeChargeCollectedFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                     </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Max Transaction</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span>
                    <span className='card-inner-text'>
                        {
                            isMaxTxLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={maxTransaction}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                    <br />
                    <span className='primary-text card-inner-text'>% of Supply: </span> 
                    <span className='card-inner-text'>
                        {
                            isMaxTxLoading || isTotalSupplyLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={transactionPercentage}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            )
                        }
                        </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>24hr Volume</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>0</span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>$0</span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Volume</span>}>
                    <span className='primary-text card-inner-text'>$Fathom: </span> 
                    <span className='card-inner-text'>0</span>
                    <br />
                    <span className='primary-text card-inner-text'>Value: </span> 
                    <span className='card-inner-text'>$0</span>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default Metrics