import React from 'react'
import { Card } from 'antd'
import DataChart from '../../../Components/DataChart/DataChart'
import { Col, Row } from 'reactstrap'

function CommissionEarned() {

    const graphData = [
        {
            'Date': '2022-09-21',
            '$Fathom in Shifts': 12.2820
        },
        {
            'Date': '2022-09-22',
            '$Fathom in Shifts': 34.5100
        },
        {
            'Date': '2022-09-23',
            '$Fathom in Shifts': 90.4742
        },
        {
            'Date': '2022-09-26',
            '$Fathom in Shifts': 112.2820
        },
    ]

    return (
        <Card className='fathom-finance-card'>

            <h6 className='text-center card-title'>Commission Earned</h6>
            <hr />

            <Row className='mt-2 mb-4'>
                <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className='mt-3 text-center'>
                    <h6 className='primary-text'>24hr: 123,456</h6>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className='mt-3 text-center'>
                    <h6 className='primary-text'>Week: 456,789</h6>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className='mt-3 text-center'>
                    <h6 className='primary-text'>Month: 1,234,567</h6>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="6" sm="12" xs="12" className='mt-3 text-center'>
                    <h6 className='primary-text'>Total: 123,456,789</h6>
                </Col>
            </Row>

            <DataChart data={graphData} xAxis="Time Range" yAxis="$Fathom in Shifts" xField="Date" yField="$Fathom in Shifts" />
        </Card>
    )
}

export default CommissionEarned