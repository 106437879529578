import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import DataChart from '../../Components/DataChart/DataChart';
import { getMassExistenceForLast7Days, getTotalMassMinted, getTotalMassBurned, getTotalMassExisting, getTotalMassReflected } from '../../Blockchain/services/staking.service';
import NumberFormat from 'react-number-format'

function Mass() {

    const [graphData, setGraphData] = useState([])
    const [isGraphDataLoading, setIsGraphDataLoading] = useState(false)

    const [isMassMintedLoading, setIsMassMintedLoading] = useState(false)
    const [totalMassMinted, setTotalMassMinted] = useState(0.0)

    const [isMassBurnedLoading, setIsMassBurnedLoading] = useState(false)
    const [totalMassBurned, setTotalMassBurned] = useState(0.0)

    const [isMassReflectedLoading, setIsMassReflectedLoading] = useState(false)
    const [totalMassReflected, setTotalMassReflected] = useState(0.0)

    const [isMasExistingLoading, setIsMasExistingLoading] = useState(false)
    const [totalMassExisting, setTotalMassExisting] = useState(0.0)


    //fetch the mass data for last 7 days
    useEffect(() => {
        fetchGraphData()
        fetchMassMinted()
        fetchMassBurned()
        fetchMassReflected()
        fetchMassExisting()
    }, [])

    const fetchGraphData = async () => {
        setIsGraphDataLoading(true)
        const response = await getMassExistenceForLast7Days()
        setGraphData(response)
        setIsGraphDataLoading(false)
    }

    const fetchMassMinted = async () => {
        setIsMassMintedLoading(true)
        const response = await getTotalMassMinted()
        setTotalMassMinted(response)
        setIsMassMintedLoading(false)
    }

    const fetchMassBurned = async () => {
        setIsMassBurnedLoading(true)
        const response = await getTotalMassBurned()
        setTotalMassBurned(response)
        setIsMassBurnedLoading(false)
    }

    const fetchMassReflected = async () => {
        setIsMassReflectedLoading(true)
        const response = await getTotalMassReflected()
        setTotalMassReflected(response)
        setIsMassReflectedLoading(false)
    }

    const fetchMassExisting = async () => {
        setIsMasExistingLoading(true)
        const response = await getTotalMassExisting()
        setTotalMassExisting(response)
        setIsMasExistingLoading(false)
    }


    return (
        <div className='mb-5'>

            <Card className='mt-5 transparent'>
                <h3 className='text-center text-uppercase'>Mass</h3>
            </Card>


            <Row className='mb-3'>
                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Mass</span>}>
                        <span className='primary-text card-inner-text'>Existing: </span> <span className='card-inner-text'>
                            {
                                isMasExistingLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={totalMassExisting}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Mass</span>}>
                        <span className='primary-text card-inner-text'>Minted: </span>
                        <span className='card-inner-text'>
                            {
                                isMassMintedLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={totalMassMinted}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>Forfeited: </span>
                        <span className='card-inner-text'>
                            {
                                isMassReflectedLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={totalMassReflected}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Mass Burned</span>}>
                        <span className='primary-text card-inner-text'>Total: </span>
                        <span className='card-inner-text'>
                            {
                                isMassBurnedLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={totalMassBurned}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Total Mass Reflected</span>}>
                        <span className='primary-text card-inner-text'>Total: </span>
                        <span className='card-inner-text'>
                            {
                                isMassReflectedLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={totalMassReflected}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>
            </Row>

            <Card className='fathom-finance-card mt-3'>
                <h6 className='text-center card-title'>Timeline of Mass in Existance</h6>
                <hr />

                {
                    isGraphDataLoading ? (
                        <div className='d-flex justify-content-center'>
                            <Spin />
                        </div>
                    ) : (
                        <DataChart
                            data={graphData}
                            xAxis="Time Range"
                            yAxis="Total existing Mass in (WEI)"
                            xField="date"
                            yField="totalSupply" />
                    )
                }

            </Card>
        </div>
    )
}

export default Mass