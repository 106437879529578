import { getChainNetworkByChainId } from '../../Blockchain/utils/chainList'
import { ethers, utils } from 'ethers'
import { commonConfigs } from '../configs/common.configs'
import { stakingConfigs } from '../configs/staking.configs'
import { getTokenPriceInUSDByTokenAddress } from './util.service'
import { DateTime } from 'luxon'

export const getWeb3PRovider = () => {
  const defaultChainId = commonConfigs.chainId
  const web3configs = getChainNetworkByChainId(defaultChainId)
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
    name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
  })
  return web3
}

export const getArchivedWeb3Provider = () => {
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(commonConfigs.archivedRPCUrl)
  return web3
}

//staking read functions
export const getNumbersOfTotalStake = async () => {
  let totalStakesCount = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    //TODO method not implemented yet in smart contract
    const stakeCountResponse = await stakingContractInstance.numberOfStakers()
    const numbersOfStakesFormatted = parseInt(stakeCountResponse.toString())
    totalStakesCount = numbersOfStakesFormatted
    return totalStakesCount
  } catch (error) {
    console.log("ERROR while fetching total numbers of stakes : ", error)
    return totalStakesCount
  }
}


export const getNumberOfFathomTokenStaked = async () => {
  let totalFathomTokenStaked = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    const fathomTokenAddress = stakingConfigs.fathomTokenAddress
    const bep20TokenContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)

    const bep20ContractInstance = new ethers.Contract(fathomTokenAddress, bep20TokenContractABI, provider)

    const tokenDecimalResponse = await bep20ContractInstance.decimals()
    const decimals = parseInt(tokenDecimalResponse.toString())

    const totalFathomTokenStakedResponse = await stakingContractInstance.totalFathomStaked()
    const totalFathomTokenStakedFormatted = utils.formatUnits(totalFathomTokenStakedResponse.toString(), decimals)
    totalFathomTokenStaked = totalFathomTokenStakedFormatted.toString()
    return totalFathomTokenStaked
  } catch (error) {
    console.log("ERROR while fetching total fathom token staked : ", error)
    return totalFathomTokenStaked
  }
}

export const getTotalMassMinted = async () => {
  let totalMassMinted = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    //TODO method not implemented yet in smart contract
    const totalMassMintedResponse = await stakingContractInstance.totalMassMinted()
    const totalMassMintedFormatted = utils.formatEther(totalMassMintedResponse.toString())
    totalMassMinted = totalMassMintedFormatted.toString()
    return totalMassMinted
  } catch (error) {
    console.log("ERROR while fetching total mass minted : ", error)
    return totalMassMinted
  }
}



export const getTotalMassBurned = async () => {
  let totalMassBurned = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    //TODO method not implemented yet in smart contract
    const totalMassBurnedResponse = await stakingContractInstance.totalMassBurned()
    const totalMassBurnedFormatted = utils.formatEther(totalMassBurnedResponse.toString())
    totalMassBurned = totalMassBurnedFormatted.toString()
    return totalMassBurned
  } catch (error) {
    console.log("ERROR while fetching total mass burned : ", error)
    return totalMassBurned
  }
}


export const getTotalMassExisting = async () => {
  let totalMassExisting = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    //TODO method not implemented yet in smart contract
    const totalMassExistingResponse = await stakingContractInstance.totalSupply()
    const totalMassExistingFormatted = utils.formatEther(totalMassExistingResponse.toString())
    totalMassExisting = totalMassExistingFormatted.toString()
    return totalMassExisting
  } catch (error) {
    console.log("ERROR while fetching total mass existing : ", error)
    return totalMassExisting
  }
}


export const getTotalMassReflected = async () => {
  let totalMassReflected = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    //TODO method not implemented yet in smart contract
    const totalMassReflectedResponse = await stakingContractInstance.totalReflectedMass()
    const totalMassReflectedFormatted = utils.formatEther(totalMassReflectedResponse.toString())
    totalMassReflected = totalMassReflectedFormatted.toString()
    return totalMassReflected
  } catch (error) {
    console.log("ERROR while fetching total mass reflected : ", error)
    return totalMassReflected
  }
}

export const getAPY = async () => {
  let apy = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    //TODO method not implemented yet in smart contract
    const apyResponse = await stakingContractInstance.getRewardRate()
    const apyFormatted = utils.formatUnits(apyResponse.toString(), 2)
    apy = apyFormatted.toString()
    return apy
  } catch (error) {
    console.log("ERROR while fetching APY amount : ", error)
    return apy
  }
}

export const getDepositFee = async () => {
  let depositFee = 0
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    const depositFeeResponse = await stakingContractInstance.stakingCharge()
    const depositFeeFormatted = utils.formatUnits(depositFeeResponse.toString(), 2)
    depositFee = depositFeeFormatted.toString()
    return depositFee
  } catch (error) {
    console.log("ERROR while fetching deposit fee amount : ", error)
    return depositFee
  }
}

export const getFathomTokenInOcean = async () => {
  let fathomTokenBalance = 0
  try {
    const provider = getWeb3PRovider()
    const OCEAN_CONTRACT_ADDRESS = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS
    const FATHOM_TOKEN_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS

    //ocean contract instance 
    const commonContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const oceanContractInstance = new ethers.Contract(OCEAN_CONTRACT_ADDRESS, commonContractABI, provider)
    const fathomTokenContractInstance = new ethers.Contract(FATHOM_TOKEN_ADDRESS, commonContractABI, provider)

    const tokenDecimalResponse = await fathomTokenContractInstance.decimals()
    const decimals = parseInt(tokenDecimalResponse.toString())

    //get the fathom token balance in ocean contract
    const tokenBalanceResponse = await oceanContractInstance.balanceOf(FATHOM_TOKEN_ADDRESS)
    const tokenBalanceFormatted = utils.parseUnits(tokenBalanceResponse.toString(), decimals)
    fathomTokenBalance = tokenBalanceFormatted
    return fathomTokenBalance
  } catch (error) {
    console.log("ERROR while fetching fathom token balance in ocean : ", error)
    return fathomTokenBalance
  }
}

export const getOceanReleasePercentageByWave = async () => {
  let oceanReleasePercentage = 0
  try {
    const provider = getWeb3PRovider()
    const OCEAN_CONTRACT_ADDRESS = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS

    //ocean contract instance 
    const oceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const oceanContractInstance = new ethers.Contract(OCEAN_CONTRACT_ADDRESS, oceanContractABI, provider)


    const oceanReleasePercentageResponse = await oceanContractInstance.rewardPerSlot()
    const oceanReleasePercentageFormatted = oceanReleasePercentageResponse.toString()
    oceanReleasePercentage = oceanReleasePercentageFormatted

    return oceanReleasePercentage
  } catch (error) {
    console.log("ERROR while fetching ocean release percentage for wave : ", error)
    return oceanReleasePercentage
  }
}


export const stakeFathomTokens = async (fathomTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()

    const FATHOM_TOKEN_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
    const commonContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const fathomTokenContractInstance = new ethers.Contract(FATHOM_TOKEN_ADDRESS, commonContractABI, provider)

    const tokenDecimals = await fathomTokenContractInstance.decimals()
    const decimals = parseInt(tokenDecimals.toString())

    const formattedStakedTokenAmount = parseFloat(fathomTokenAmount.toString()).toFixed(tokenDecimals)
    const formattedFathomTokensToBeStaked = utils.parseUnits(formattedStakedTokenAmount, decimals)

    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer)


    const stakingReceipt = await stakingContractInstanceWithSigner.stake(formattedFathomTokensToBeStaked)
    const result = await stakingReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to stake fathom token. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const stakeFathomTokenBigNumber = async (fathomTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer)
    const stakingReceipt = await stakingContractInstanceWithSigner.stake(fathomTokenAmount)
    const result = await stakingReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to stake fathom token. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getMassBalanceByWalletAddressInStaking = async (walletAddress) => {
  let userMassAmount = 0.0
  try {
    if (walletAddress) {
      const provider = getWeb3PRovider()
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
      const stakingContractAddress = stakingConfigs.stakingContractAddress
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
      const userStakingDetailsResponse = await stakingContractInstance.balanceOf(walletAddress)
      const formattedValue = utils.formatEther(userStakingDetailsResponse)
      userMassAmount = formattedValue.toString()
      return userMassAmount
    } else {
      return userMassAmount
    }

  } catch (error) {
    console.log("ERROR while fetching user mass token amount : ", error)
    return userMassAmount
  }
}


export const getStakingDetailsByWalletAddress = async (walletAddress) => {
  let totalFathomTokenInvested = 0.0
  let totalFathomTokenInvestedAmountInUSD = 0.0
  let totalMassReceived = 0.0
  let lockTimeTimestamp = 0
  let userMassReflection = 0
  // let lastRewardClaimedTimestamp = 0
  // let unclaimedRewardsAmount = 0.0
  // let totalTokensReceived = 0.0
  // let lastClaimedAmount = 0.0
  try {
    if (walletAddress) {
      const provider = getWeb3PRovider()
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
      const stakingContractAddress = stakingConfigs.stakingContractAddress
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
      const userStakingDetailsResponse = await stakingContractInstance.userStaked(walletAddress)
      const FATHOM_TOKEN_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
      const bep20TokenContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
      const bep20ContractInstance = new ethers.Contract(FATHOM_TOKEN_ADDRESS, bep20TokenContractABI, provider)

      const decimalResponse = await bep20ContractInstance.decimals()
      const decimals = parseInt(decimalResponse.toString())

      const fathomTokenFormatted = utils.formatUnits(userStakingDetailsResponse[0].toString(), decimals)
      totalFathomTokenInvested = fathomTokenFormatted.toString()

      const fathomTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(FATHOM_TOKEN_ADDRESS)
      const fathomTokenAmountInUSD = parseFloat(fathomTokenPriceInUSD) * parseFloat(totalFathomTokenInvested)
      totalFathomTokenInvestedAmountInUSD = fathomTokenAmountInUSD

      const formattedTotalMassReceived = utils.formatEther(userStakingDetailsResponse[1].toString())

      const userStakedMassValueResponse = await stakingContractInstance.balanceOf(walletAddress)
      const formattedUserStakedMassValue = utils.formatEther(userStakedMassValueResponse.toString())

      userMassReflection = parseFloat(formattedUserStakedMassValue) - parseFloat(formattedTotalMassReceived)
      // const formattedUnclaimedRewardsAmount = utils.formatEther(userStakingDetailsResponse[4].toString())

      totalMassReceived = formattedTotalMassReceived
      lockTimeTimestamp = userStakingDetailsResponse[2].toString()
      // lastRewardClaimedTimestamp = userStakingDetailsResponse[3]
      // unclaimedRewardsAmount = formattedUnclaimedRewardsAmount.toString()

      // const formattedTotalTokenReceived = utils.formatEther(userStakingDetailsResponse[5].toString())
      // totalTokensReceived = formattedTotalTokenReceived.toString()

      // const formattedLastClaimedAmount = utils.formatEther(userStakingDetailsResponse[6].toString())
      // lastClaimedAmount = formattedLastClaimedAmount.toString()
      return {
        totalFathomTokenInvested,
        totalFathomTokenInvestedAmountInUSD,
        totalMassReceived,
        lockTimeTimestamp,
        userMassReflection
        // lastRewardClaimedTimestamp,
        // unclaimedRewardsAmount,
        // totalTokensReceived,
        // lastClaimedAmount

      }
    } else {
      return {
        totalFathomTokenInvested,
        totalFathomTokenInvestedAmountInUSD,
        totalMassReceived,
        lockTimeTimestamp,
        userMassReflection
        // lastRewardClaimedTimestamp,
        // unclaimedRewardsAmount,
        // totalTokensReceived,
        // lastClaimedAmount

      }
    }

  } catch (error) {
    console.log("ERROR while fetching user staked data : ", error)
    return {
      totalFathomTokenInvested,
      totalFathomTokenInvestedAmountInUSD,
      totalMassReceived,
      lockTimeTimestamp,
      userMassReflection
      // lastRewardClaimedTimestamp,
      // unclaimedRewardsAmount

    }
  }
}

export const getMassDividendBalanceByUser = async (walletAddress) => {
  let massTokenBalance = 0
  try {

    if (walletAddress) {
      const provider = getWeb3PRovider()
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
      const stakingContractAddress = stakingConfigs.stakingContractAddress
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
      const massTokenResponse = await stakingContractInstance.balanceOf(walletAddress)
      const massTokenFormatted = utils.formatEther(massTokenResponse.toString())
      massTokenBalance = massTokenFormatted.toString()
      return massTokenBalance
    } else {
      return massTokenBalance
    }

  } catch (error) {
    console.log("ERROR while fetching mass token balance of the user: ", error)
    return massTokenBalance
  }
}

export const getNextRewardsTime = async () => {
  let nextRewardsTimestamp = 0
  try {
    const provider = getWeb3PRovider()
    const stakingOceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const stakingOceanContractAddress = stakingConfigs.oceanContractAddress
    const stakingOceanContractInstance = new ethers.Contract(stakingOceanContractAddress, stakingOceanContractABI, provider)
    const slotTimeResponse = await stakingOceanContractInstance.slotTime()
    const slotTimeFormatted = parseInt(slotTimeResponse.toString())

    const lastRewardsTransactionTimeResponse = await stakingOceanContractInstance.lastRewardTransactionTime()
    const lastTxTimeFormatted = parseInt(lastRewardsTransactionTimeResponse.toString())
    const nextRewardsTimestamp = lastTxTimeFormatted + slotTimeFormatted
    return nextRewardsTimestamp

  } catch (error) {
    console.log("ERROR while calculating next rewards timestamp: ", error)
    return nextRewardsTimestamp
  }
}

export const claimStakingRewards = async (signer) => {
  try {
    const provider = getWeb3PRovider()

    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer)


    const claimRewardsReceipt = await stakingContractInstanceWithSigner.claimReward()
    const result = await claimRewardsReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim your rewards tokens. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const compoundStakingRewards = async (signer) => {
  try {
    const provider = getWeb3PRovider()

    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer)


    const claimAndCompoundRewardsReceipt = await stakingContractInstanceWithSigner.claimAndCompound()
    const result = await claimAndCompoundRewardsReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim and compound your rewards tokens. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const unstakeRewardTokens = async (unstakeTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()
    if (unstakeTokenAmount) {
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
      const stakingContractAddress = stakingConfigs.stakingContractAddress
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
      const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer)

      const formattedDecimalsValue = parseFloat(unstakeTokenAmount).toFixed(18)
      const formattedUnstakeTokenAmount = utils.parseEther(formattedDecimalsValue.toString())
      const unstakeReceipt = await stakingContractInstanceWithSigner.unstake(formattedUnstakeTokenAmount)
      const result = await unstakeReceipt.wait()
      return result
    }

  } catch (error) {
    let errorMessage = 'Something went wrong while trying to unstake tokens. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const unstakeRewardTokensBigNumber = async (unstakeTokenAmount, signer) => {
  try {
    const provider = getWeb3PRovider()
    if (unstakeTokenAmount) {
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
      const stakingContractAddress = stakingConfigs.stakingContractAddress
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
      const stakingContractInstanceWithSigner = stakingContractInstance.connect(signer)
      const unstakeReceipt = await stakingContractInstanceWithSigner.unstake(unstakeTokenAmount)
      const result = await unstakeReceipt.wait()
      return result
    }

  } catch (error) {
    let errorMessage = 'Something went wrong while trying to unstake tokens. Please try again'
    if (error && error.message) {
      errorMessage = error.message
    }
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getFathomBalanceInOcean = async () => {
  let fathomBalance = 0
  try {
    const provider = getWeb3PRovider()

    const FATHOM_TOKEN_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
    const bep20TokenContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const bep20ContractInstance = new ethers.Contract(FATHOM_TOKEN_ADDRESS, bep20TokenContractABI, provider)
    const oceanContractAddress = stakingConfigs.oceanContractAddress

    const fathomTokenBalanceResponse = await bep20ContractInstance.balanceOf(oceanContractAddress)
    const tokenBalanceResponse = utils.formatEther(fathomTokenBalanceResponse.toString())
    fathomBalance = tokenBalanceResponse.toString()
    return fathomBalance

  } catch (error) {
    console.log("ERROR while fathom token balance in ocean contract: ", error)
    return fathomBalance
  }
}

export const getReleasePercentageOfEachWave = async () => {
  let releasePercentage = 0
  try {
    const provider = getWeb3PRovider()
    const stakingOceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const stakingOceanContractAddress = stakingConfigs.oceanContractAddress
    const stakingOceanContractInstance = new ethers.Contract(stakingOceanContractAddress, stakingOceanContractABI, provider)
    const releasePercentageResponse = await stakingOceanContractInstance.rewardPerSlot()
    const releasePercentageFormatted = utils.formatUnits(releasePercentageResponse.toString(), 2)

    releasePercentage = releasePercentageFormatted.toString()
    return releasePercentage

  } catch (error) {
    console.log("ERROR while fetching release percentage for each wave ", error)
    return releasePercentage
  }
}

export const getTokenFathomTokensReleaseInEachWave = async () => {
  let tokenReleaseInEachWave = 0
  try {
    const releasePercentage = await getReleasePercentageOfEachWave()
    const fathomTokenBalanceInOcean = await getFathomBalanceInOcean()
    const fathomTokenReleaseInEachWaveResponse = (parseFloat(fathomTokenBalanceInOcean) / 100) * parseFloat(releasePercentage)

    tokenReleaseInEachWave = fathomTokenReleaseInEachWaveResponse
    return tokenReleaseInEachWave

  } catch (error) {
    console.log("ERROR while calculating fathom token amount release in each wave ", error)
    return tokenReleaseInEachWave
  }
}

export const getTokenFathomTokensValueInUSDReleaseInEachWave = async () => {
  let tokenReleaseInEachWave = 0
  try {
    const fathomTokenAddress = stakingConfigs.fathomTokenAddress
    const fathomTokenAmountReleaseInEachWave = await getTokenFathomTokensReleaseInEachWave()
    const fathomTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
    const tokenValueInUSD = parseFloat(fathomTokenPriceInUSD) * parseFloat(fathomTokenAmountReleaseInEachWave)
    tokenReleaseInEachWave = tokenValueInUSD
    return tokenReleaseInEachWave

  } catch (error) {
    console.log("ERROR while calculating fathom token value in USD release in each wave ", error)
    return tokenReleaseInEachWave
  }
}

export const getTheFathomTokenPercentageHeldInOcean = async () => {
  let fathomTokenPercentageHeldInOcean = 0
  try {
    const provider = getWeb3PRovider()
    const fathomTokenAddress = stakingConfigs.fathomTokenAddress
    const bep20TokenContractABI = JSON.parse(commonConfigs.commonContractABIS.bsc.commonERC20ContractABI)
    const bep20ContractInstance = new ethers.Contract(fathomTokenAddress, bep20TokenContractABI, provider)
    const totalSupplyResponse = await bep20ContractInstance.totalSupply()
    const fathomTotalSupplyFormatted = utils.formatEther(totalSupplyResponse.toString())
    const fathomTokenAmount = parseFloat(fathomTotalSupplyFormatted.toString())

    //get the fathom token amount held in ocean
    const fathomTokenInOcean = await getFathomBalanceInOcean()
    const percentageOfFathomInOcean = (parseFloat(fathomTokenInOcean) / fathomTokenAmount) * 100
    fathomTokenPercentageHeldInOcean = percentageOfFathomInOcean

    return fathomTokenPercentageHeldInOcean

  } catch (error) {
    console.log("ERROR while calculating fathom token amount held in ocean ", error)
    return fathomTokenPercentageHeldInOcean
  }
}

export const getClaimableRewards = async (walletAddress) => {

  let claimableRewards = 0.0
  let rewardsReceived = 0.0
  let lastClaimedRewards = 0.0

  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const distributorAddress = await stakingContractInstance.distributor()

    const distributorContractABI = JSON.parse(stakingConfigs.distributorContractABI)
    const distributorContractInstance = new ethers.Contract(distributorAddress, distributorContractABI, provider)
    const distributorHolderDetails = await distributorContractInstance.getHolderDetails(walletAddress)

    const formattedClaimableRewards = utils.formatEther(distributorHolderDetails[1].toString())
    claimableRewards = formattedClaimableRewards.toString()

    const formattedRewardReceived = utils.formatEther(distributorHolderDetails[2].toString())
    rewardsReceived = formattedRewardReceived.toString()

    lastClaimedRewards = distributorHolderDetails[0].toString()

    return {
      claimableRewards,
      rewardsReceived,
      lastClaimedRewards
    }

  } catch (error) {
    console.log("ERROR while calculating holder details ", error)
    return {
      claimableRewards,
      rewardsReceived,
      lastClaimedRewards
    }
  }
}

export const getMassReflectionAmount = async () => {
  let massReflectionAmount = 0.0
  let totalReflectedMassValue = 0.0

  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)

    const totalReflectedMassResponse = await stakingContractInstance.totalReflectedMass()
    // console.log('totalReflectedMassResponse', totalReflectedMassResponse.toString());
    const totalMassMintedResponse = await stakingContractInstance.totalMassMinted()

    const formattedTotalReflectedMass = utils.formatEther(totalReflectedMassResponse.toString())
    const formattedTotalMassMinted = utils.formatEther(totalMassMintedResponse.toString())

    totalReflectedMassValue = parseFloat(formattedTotalReflectedMass)

    const massReflectionValue = parseFloat(formattedTotalReflectedMass) / parseFloat(formattedTotalMassMinted)

    massReflectionAmount = massReflectionValue

    return {
      massReflectionAmount,
      totalReflectedMassValue
    }

  } catch (error) {
    console.log("ERROR while fetch mass reflecting details ", error)

    return {
      massReflectionAmount,
      totalReflectedMassValue
    }
  }
}
export const getCalculatedDailyROIPercentage = async () => {

  let last24hROIPercentage = 0.0

  try {
    const provider = getWeb3PRovider()
    const stakingOceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const stakingOceanContractAddress = stakingConfigs.oceanContractAddress
    const stakingOceanContractInstance = new ethers.Contract(stakingOceanContractAddress, stakingOceanContractABI, provider)

    const eventFilter = stakingOceanContractInstance.filters.Waved();
    //get the current block number
    const currentBlock = await provider.getBlockNumber()
    const BLOCKS_PER_DAY = 28800
    const NUMBERS_OF_WAVES_PER_DAY = 15
    const BLOCK_RANGE_LIMIT = 5000

    let last24hEvents = []
    let startingBlock = currentBlock - BLOCKS_PER_DAY
    let endingBlock = currentBlock
    for (let i = 0; i < 5; i++) {
      endingBlock = startingBlock + BLOCK_RANGE_LIMIT

      if (i === 4) {
        startingBlock = startingBlock + BLOCK_RANGE_LIMIT
        endingBlock = currentBlock
      }
      const events = await stakingOceanContractInstance.queryFilter(
        eventFilter,
        startingBlock,
        endingBlock,
      );
      startingBlock = startingBlock + BLOCK_RANGE_LIMIT

      last24hEvents = last24hEvents.concat(events)
    }

    let totalRewardsSent = 0
    for (let j = 0; j < last24hEvents.length; j++) {
      const rewardsAmountResponse = last24hEvents[j].args[2]
      const rewardsAmount = parseFloat(utils.formatEther(rewardsAmountResponse.toString()))
      totalRewardsSent = totalRewardsSent + rewardsAmount
    }

    //get fathom token price in USD
    const FATHOM_TOKEN_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
    const tokenPrice = await getTokenPriceInUSDByTokenAddress(FATHOM_TOKEN_ADDRESS)
    const totalRewardsSentUSDValue = parseFloat(tokenPrice) * totalRewardsSent
    const totalMassExisting = await getTotalMassExisting()

    const roiResponse = (totalRewardsSentUSDValue / parseFloat(totalMassExisting)) * NUMBERS_OF_WAVES_PER_DAY
    const ROIPercentage = roiResponse * 100
    last24hROIPercentage = ROIPercentage
    return last24hROIPercentage
  } catch (error) {
    console.log("ERROR while calculating last 24 hours staking ROI percentage", error)
    return last24hROIPercentage
  }
}

export const getTotalStakedFathom = async () => {
  let totalStaked = 0

  try {
    const provider = getWeb3PRovider()
    // if (walletAddress) {
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const totalFathomStakedResponse = await stakingContractInstance.totalFathomStaked()
    const totalStakedFormatted = utils.formatEther(totalFathomStakedResponse.toString())

    totalStaked = parseFloat(totalStakedFormatted)
    return totalStaked
    // } else {
    //   return totalStaked
    // }
  } catch (error) {
    let errorMessage = 'Error while fetching total staked tokens'
    throw errorMessage
  }
}

export const getTotalMassValues = async () => {
  let totalMassMinted = 0
  let totalMassReflected = 0

  try {
    const provider = getWeb3PRovider()
    // if (walletAddress) {
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const totalMassMintedResponse = await stakingContractInstance.totalMassMinted()
    const totalMassReflectedResponse = await stakingContractInstance.totalReflectedMass()

    const totalMassMintedFormatted = utils.formatEther(totalMassMintedResponse.toString())
    const totalMassReflectedFormatted = utils.formatEther(totalMassReflectedResponse.toString())

    totalMassMinted = parseFloat(totalMassMintedFormatted)
    totalMassReflected = parseFloat(totalMassReflectedFormatted)
    return {
      totalMassMinted,
      totalMassReflected
    }
    // } else {
    //   return totalStaked
    // }
  } catch (error) {
    let errorMessage = 'Error while fetching total staked tokens'
    throw errorMessage
  }
}

export const getTotalStakeChargeCollected = async () => {
  let totalStakeChargeCollected = 0

  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const totalMassMintedResponse = await stakingContractInstance.totalStakeChargeCollected()
    const totalMassMintedFormatted = utils.formatEther(totalMassMintedResponse.toString())
    console.log('totalMassMintedFormatted', totalMassMintedFormatted);

    totalStakeChargeCollected = parseFloat(totalMassMintedFormatted)
    return totalStakeChargeCollected

  } catch (error) {
    let errorMessage = 'Error while fetching total staked tokens'
    throw errorMessage
  }
}

export const getStakingTax = async () => {
  let stakingTax = 0
  let stakingTaxCollector = 0

  try {
    const provider = getWeb3PRovider()
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    const stakingTaxResponse = await stakingContractInstance.stakingCharge()
    const stakingTaxCollectorResponse = await stakingContractInstance.totalStakeChargeCollected()

    const stakingTaxFormatted = stakingTaxResponse.toString()
    const stakingTaxCollectorFormatted = utils.formatEther(stakingTaxCollectorResponse.toString())

    stakingTax = parseFloat(stakingTaxFormatted) / 100
    stakingTaxCollector = parseFloat(stakingTaxCollectorFormatted)

    return {
      stakingTax,
      stakingTaxCollector
    }

  } catch (error) {
    let errorMessage = 'Error while fetching staking tax'
    throw errorMessage
  }
}

export const getMassExistenceForLast7Days = async () => {
  const graphData = []
  const AVG_BLOCK_TIME_IN_SECONDS = 3
  const SECONDS_PER_DAY = 86400
  const AVG_BLOCKS_PER_DAY = parseInt(SECONDS_PER_DAY / AVG_BLOCK_TIME_IN_SECONDS)
  try {
    const provider = getArchivedWeb3Provider()
    //get the current block number 
    const currentBlock = await provider.getBlockNumber()

    //create the instance of the staking contract
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractABI)
    const stakingContractAddress = stakingConfigs.stakingContractAddress
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider)
    for (let i = 0; i < 7; i++) {
      const blockNumber = currentBlock - (AVG_BLOCKS_PER_DAY * i)
      const totalSupply = await stakingContractInstance.totalSupply({ blockTag: blockNumber })
      const currentDate = DateTime.now().toUTC()
      const date = currentDate.minus({ days: i })
      const payload = {
        blockNumber: blockNumber,
        totalSupply: parseFloat(utils.formatEther(totalSupply).toString()).toFixed(2),
        date: date.toFormat('yyyy-LL-dd')
      }
      graphData.push(payload)
    }
    return graphData
  } catch (error) {
    console.log("ERROR while fetching mass existence graph data ", error)
    return graphData
  }
}

export const getLastWave = async () => {
  let lastWaveValue = 0
  try {
    const provider = getWeb3PRovider()
    const stakingOceanContractABI = JSON.parse(stakingConfigs.oceanContractABI)
    const stakingOceanContractAddress = stakingConfigs.oceanContractAddress
    const stakingOceanContractInstance = new ethers.Contract(stakingOceanContractAddress, stakingOceanContractABI, provider)
    const lastWaveResponse = await stakingOceanContractInstance.lastWave()
    const lastWaveFormatted = utils.formatEther(lastWaveResponse.toString())

    lastWaveValue = lastWaveFormatted.toString()
    return lastWaveValue

  } catch (error) {
    console.log("ERROR while fetching last wave ", error)
    return lastWaveValue
  }
}