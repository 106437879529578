import React, { useEffect } from 'react'

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

function UpcomingCountdownDetailed(deadline) {
  const parsedDeadline = deadline.deadline
  const [time, setTime] = React.useState(parsedDeadline - Date.now());
  useEffect(() => {
    const interval = setInterval(
      () => setTime( Date.now() - parsedDeadline),
      1000
    );

    return () => clearInterval(interval);
  }, [parsedDeadline])

  return (
    <div className="d-lg-flex">
      {Object.entries({
        Days: (time / DAY),
        Hours: (time / HOUR) % 24,
        Minutes: (time / MINUTE) % 60,
        Seconds: (time / SECOND) % 60
      }).map(([label, value]) => (
          <span key={label} className="d-flex">
            <span>{`${Math.trunc(value)}`.padStart(2, "0")}&nbsp; </span>
            <span className="text"> {label}&nbsp; </span>
          </span>
        // </div>
      ))}
    </div>
  )
}

export default UpcomingCountdownDetailed