import React from 'react'
import { Card } from 'antd'
import { Col } from 'reactstrap'
import './Home.css'
// import TokenTransactionHistory from '../../Components/TokenTransactionHistory/TokenTransactionHistory'
import CandleStickChart from '../../Components/CandleStickChart/CandleStickChart'
import MyStats from './MyStats'
import MyHistory from './MyHistory'
import Metrics from './Metrics'
import Taxes from './Taxes'
import Weather from './Weather'
import SolidStaking from './SolidStaking'
import Ocean from './Ocean'
// import Whirlpool from './Whirlpool'
// import ShillingSea from './ShillingSea'
import Mass from './Mass'
import { HashScroll } from "react-hash-scroll";


function Home() {

  return (
    <div>
  
      <HashScroll hash="#mystats" position='center'>
        <div>
          <MyStats />
        </div>
      </HashScroll>

      <HashScroll hash="#myhistory" position='center'>
        <div>
          <MyHistory />
        </div>
      </HashScroll>
      
      <HashScroll hash="#metrics" position='center'>
        <div>
          <Metrics />      
        </div>
      </HashScroll>
      
      <Col lg="12" md="12" sm="12" className='mt-3'>
        <Card className='fathom-finance-card'>
          <CandleStickChart />
        </Card>
      </Col>

      {/* <Col lg="12" md="12" sm="12" className='mt-3 mb-5'>
        <Card className='fathom-finance-card'>
          <h6 className='text-center card-title'>Token transaction history</h6>
          <hr />
          
          <div>
            <TokenTransactionHistory />
          </div>
        </Card>
      </Col> */}

      <HashScroll hash="#taxes" position='center'>
        <div>
          <Taxes />
        </div>
      </HashScroll>

      <div>
        <Weather />
      </div>

      <HashScroll hash="#solidstaking" position='center'>
        <div>
          <SolidStaking />
        </div>
      </HashScroll>

      <HashScroll hash="#ocean" position='center'>
        <div>
          <Ocean />
        </div>
      </HashScroll>

      {/* <HashScroll hash="#whirlpool" position='center'>
        <div>
          <Whirlpool />
        </div>
      </HashScroll> */}

      {/* <HashScroll hash="#shillingsea" position='center'>
        <div>
          <ShillingSea />
        </div>
      </HashScroll> */}

      
        <div>
          <Mass />
        </div>
    </div>
  )
}

export default Home