import { configureStore } from "@reduxjs/toolkit";
import MobileMenuReducer from "./MobileMenuSlice";
import SideMenuSlice from "./SideMenuSlice";
import CsvDataListReducer from './CsvDataList'
import HomeStepsReducer from './HomeSteps'

export const Store = configureStore({
    reducer: {
        mobileMenu: MobileMenuReducer,
        sideMenu: SideMenuSlice,
        csvDataList: CsvDataListReducer,
        homeSteps: HomeStepsReducer
    }
})