import {
    Button,
    Input,
    Select,
    Slider,
    Spin,
    notification
} from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import busdTokenImage from '../../images/busd-token-image.png'
import bnbTokenImage from '../../images/bnb.png'
import { ArrowDownOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import {
    getBEP20TokenBalanceByWalletAddress,
    getPCSRouterCurrencyConversion,
    getTokenPriceInUSDByTokenAddress,
    getEstimatedTokenAmountForBNB,
    tokenApproval,
    getUserBNBBalance,
    getBNBPrice
} from '../../Blockchain/services/util.service'
import {
    buyTokens,
    buyTokensWithBNB,
    buyAndStakeTokens,
    buyWithBNBAndStakeTokens,
} from '../../Blockchain/services/shilling.service'
import fathom from '../../images/fflogo.png'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { useAccount, useSigner } from 'wagmi';

function Buy() {

    const { Option } = Select;
    const formatter = (value) => `${value}%`;
    const [sliderValue, setSliderValue] = useState(0)
    const [isBNBSelected, setIsBNBSelected] = useState(true)
    const [selectedCurrencyType, setSelectedCurrencyType] = useState(process.env.REACT_APP_WBNB_ADDRESS)

    const { address: account } = useAccount()
    const { data: signer } = useSigner();

    //user specific values
    const [userBUSDTokenBalance, setUserBUSDTokenBalance] = useState(0.0)
    const [userBNBBalance, setUserBNBBalance] = useState(0.0)
    const [isUserTokenBalanceLoading, setIsUserTokenBalanceLoading] = useState(false)
    const [userFathomTokenBalance, setUserFathomTokenBalance] = useState(0.0)
    const [userBuyAmount, setUserBuyAmount] = useState(0.0)
    const [estimatedFathomTokenAmount, setEstimatedFathomTokenAmount] = useState(0.0)
    const [isTokenAmountEstimationLoading, setIsTokenAmountEstimationLoading] = useState(0.0)
    const [tokenPriceInUSD, setTokenPriceInUSD] = useState(0.0)
    const [isTokenApprovalLoading, setIsTokenApprovalLoading] = useState(false)
    const [isBuyButtonsDisabled, setIsBuyButtonsDisabled] = useState(true)
    const [shillingAddress, setShillingAddress] = useState('')
    const [isTokenBuyLoading, setIsTokenBuyLoading] = useState(false)
    const [isBuyAndStakeTokenLoading, setIsBuyAndStakeTokenLoading] = useState(false)
    const [bnbPriceInUSD, setBnbPriceInUSD] = useState(0.0)
    const [userInputFormatted, setUserInputFormatted] = useState(0.0)

    const [searchParams] = useSearchParams()
    const referralAddress = searchParams.get('ref')

    const [debounceInputValue] = useDebounce(userInputFormatted, 500)
    useEffect(() => {
        if (referralAddress) {
            setShillingAddress(referralAddress)
        }
    }, [referralAddress])

    const fetchUserBUSDTokenBalance = async () => {
        const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDRESS
        setIsUserTokenBalanceLoading(true)
        try {
            const busdTokenBalance = await getBEP20TokenBalanceByWalletAddress(BUSD_ADDRESS, account)
            setUserBUSDTokenBalance(busdTokenBalance)
            setIsUserTokenBalanceLoading(false)
        } catch (error) {
            setUserBUSDTokenBalance(0.0)
            setIsUserTokenBalanceLoading(false)
            console.error("ERROR while fetching user busd token balance : ", error)
        }
    }

    const fetchUserBNBTokenBalance = async () => {
        setIsUserTokenBalanceLoading(true)
        try {
            const bnbBalance = await getUserBNBBalance(account)
            setUserBNBBalance(bnbBalance)
            setIsUserTokenBalanceLoading(false)
        } catch (error) {
            setUserBNBBalance(0.0)
            setIsUserTokenBalanceLoading(false)
            console.error("ERROR while fetching user busd token balance : ", error)
        }
    }

    const fetchCurrencyConversionFromPCS = async (inputAmount) => {
        try {
            if (isBNBSelected) {
                setIsTokenAmountEstimationLoading(true)
                setEstimatedFathomTokenAmount('')
                const FATHOM_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
                const currencyQuoteResponse = await getEstimatedTokenAmountForBNB(FATHOM_ADDRESS, inputAmount)
                console.log('currencyQuoteResponse', currencyQuoteResponse);
                setIsTokenAmountEstimationLoading(false)
                setEstimatedFathomTokenAmount(currencyQuoteResponse)
            } else {
                setIsTokenAmountEstimationLoading(true)
                setEstimatedFathomTokenAmount('')
                const FATHOM_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
                const currencyQuoteResponse = await getPCSRouterCurrencyConversion(FATHOM_ADDRESS, inputAmount)
                setIsTokenAmountEstimationLoading(false)
                setEstimatedFathomTokenAmount(currencyQuoteResponse)
            }

        } catch (error) {
            setIsTokenAmountEstimationLoading(false)
            console.log("ERROR while fetching token balance ", error)
        }
    }

    const fetchTokenPriceInUSD = async () => {
        try {
            setIsTokenAmountEstimationLoading(true)
            const FATHOM_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
            const tokenPrice = await getTokenPriceInUSDByTokenAddress(FATHOM_ADDRESS)
            setIsTokenAmountEstimationLoading(false)
            setTokenPriceInUSD(tokenPrice)
        } catch (error) {
            setIsTokenAmountEstimationLoading(false)
            console.log("ERROR while fetching token price in usd ", error)
        }
    }

    const handleFathomTokenEstimatedAmount = (e) => {
        const userInputValue = e.target.value
        setEstimatedFathomTokenAmount(userInputValue)

        if (isBNBSelected) {
            if (bnbPriceInUSD) {
                const totalTokenPrice = parseFloat(tokenPriceInUSD) * parseFloat(userInputValue)
                const estimatedBNBValue = totalTokenPrice / parseFloat(bnbPriceInUSD)
                const formattedAmount = parseFloat(estimatedBNBValue).toFixed(10)
                setUserBuyAmount(formattedAmount)
            }
        } else {
            const estimatedBUSDAmount = parseFloat(tokenPriceInUSD) * parseFloat(userInputValue)
            setUserBuyAmount(estimatedBUSDAmount)
        }
        console.log(e.target.value)
    }

    const fetchFathomTokenBalance = async () => {
        try {
            const FATHOM_ADDRESS = process.env.REACT_APP_FATHOM_ADDRESS
            const fathomTokenBalance = await getBEP20TokenBalanceByWalletAddress(FATHOM_ADDRESS, account)
            setUserFathomTokenBalance(fathomTokenBalance)
        } catch (error) {
            setUserFathomTokenBalance(0.0)
            console.error("ERROR while fetching user busd token balance : ", error)
        }
    }

    const fetchUserTokenBalances = async () => {
        try {
            await fetchFathomTokenBalance()
            await fetchUserBUSDTokenBalance()
        } catch (error) {
            console.error("ERROR while fetching user token balances: ", error)
        }
    }

    const handleBuyBUSDAmountChange = async (e) => {
        const inputValue = e.target.value
        if (inputValue) {
            setUserBuyAmount(inputValue)
            setUserInputFormatted(inputValue)
        } else {
            setUserBuyAmount('')
            setEstimatedFathomTokenAmount('')
        }
    }

    const handleApprove = async () => {
        setIsTokenApprovalLoading(true)
        if (!account) {
            notification['error']({
                message: 'Authentication Error',
                description:
                    'Please connect your wallet to proceed',
            });
            setIsTokenApprovalLoading(false)
            return
        }
        if (!userBuyAmount || userBuyAmount <= 0) {
            notification['error']({
                message: 'Input Validation',
                description:
                    'Approval amount can not be empty',
            })
            setIsTokenApprovalLoading(false)
            return
        }
        try {

            const spender = process.env.REACT_APP_SHILLING_CONTRACT_ADDRESS
            const BUSDAddress = process.env.REACT_APP_BUSD_ADDRESS
            const result = await tokenApproval(spender, BUSDAddress, userBuyAmount, signer)
            // const result = await approveBUSD(account, userBuyAmount, signer)

            console.log("APPROVAL SUCCESS ", result)
            setIsTokenApprovalLoading(false)
            setIsBuyButtonsDisabled(false)
            notification['success']({
                message: 'Success',
                description:
                    'Token has been approved',
            })

        } catch (error) {
            setIsTokenApprovalLoading(false)
            setIsBuyButtonsDisabled(true)
            notification['error']({
                message: 'Transaction Execution Failed',
                description: error,
            })
            console.error("ERROR while trying to approve BUSD for shilling ", error)
        }
    }

    const handleBuy = async () => {
        try {
            setIsTokenBuyLoading(true)
            if (!account) {
                notification['error']({
                    message: 'Authentication Error',
                    description:
                        'Please connect your wallet to proceed',
                })
                setIsTokenBuyLoading(false)
                return
            }
            let result = null
            if (isBNBSelected) {
                result = await buyTokensWithBNB(userBuyAmount, shillingAddress, signer)

            } else {
                result = await buyTokens(userBuyAmount, shillingAddress, signer)
            }
            // await saveReferralBuyEventInOffChain(result)
            console.log("FATHOM BUY RESULT : ", result)
            setIsTokenBuyLoading(false)
            notification['success']({
                message: 'Success',
                description:
                    'Transaction has been completed',
            })
            setUserBuyAmount('')
            setEstimatedFathomTokenAmount('')
            setIsBuyButtonsDisabled(true)
            fetchUserTokenBalances()
            fetchUserBNBTokenBalance()
        } catch (error) {
            setIsTokenBuyLoading(false)
            notification['error']({
                message: 'Transaction Execution Failed',
                description: error,
            })
            console.error("ERROR while try to buy fathom tokens ", error)
        }
    }

    const handleBuyAndStake = async () => {
        try {
            setIsBuyAndStakeTokenLoading(true)
            if (!account) {
                notification['error']({
                    message: 'Authentication Error',
                    description:
                        'Please connect your wallet to proceed',
                })
                setIsBuyAndStakeTokenLoading(false)
                return
            }
            let result = null
            if (isBNBSelected) {
                result = await buyWithBNBAndStakeTokens(userBuyAmount, shillingAddress, signer)
            } else {
                result = await buyAndStakeTokens(userBuyAmount, shillingAddress, signer)
            }
            // await saveReferralBuyEventInOffChain(result)
            console.log("FATHOM BUY AND STAKE RESULT : ", result)
            setIsBuyAndStakeTokenLoading(false)
            setUserBuyAmount('')
            setEstimatedFathomTokenAmount('')
            setIsBuyButtonsDisabled(true)
            fetchUserTokenBalances()
            fetchUserBNBTokenBalance()
            notification['success']({
                message: 'Success',
                description:
                    'Transaction has been completed',
            })
        } catch (error) {
            setIsBuyAndStakeTokenLoading(false)
            notification['error']({
                message: 'Transaction Execution Failed',
                description: error,
            })
            console.error("ERROR while try to buy and stake fathom tokens ", error)
        }
    }

    const fetchBNBPriceInUSD = async () => {
        setIsUserTokenBalanceLoading(true)
        try {
            const bnbBalance = await getBNBPrice()
            setBnbPriceInUSD(bnbBalance)
        } catch (error) {
            setBnbPriceInUSD(0.0)
            console.error("ERROR while fetching BNB token balance : ", error)
        }
        setIsUserTokenBalanceLoading(false)
    }

    useEffect(() => {
        setIsBuyButtonsDisabled(true)
    }, [userBuyAmount])


    useEffect(() => {
        if (isBNBSelected) {
            const userContribution = parseFloat(userBNBBalance) * (parseFloat(sliderValue) / 100)
            setUserBuyAmount(userContribution)
            setUserInputFormatted(userContribution)
        } else {
            const userContribution = parseFloat(userBUSDTokenBalance) * (parseFloat(sliderValue) / 100)
            setUserBuyAmount(userContribution)
            setUserInputFormatted(userContribution)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderValue])


    useEffect(() => {
        if (account) {
            fetchUserTokenBalances()
            fetchUserBNBTokenBalance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        fetchTokenPriceInUSD()
        fetchBNBPriceInUSD()
    }, [account])

    const handleCurrencyTypeChange = (currency) => {
        setUserBuyAmount('')
        setEstimatedFathomTokenAmount('')
        setSelectedCurrencyType(currency)
        if (currency && currency === process.env.REACT_APP_WBNB_ADDRESS) {
            setIsBNBSelected(true)
        } else {
            setIsBNBSelected(false)
        }
    }

    useEffect(() => {
        if (debounceInputValue) {
            fetchCurrencyConversionFromPCS(debounceInputValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceInputValue])

    return (
        <div>
            
                <h6 className='text-center primary-text'>FROM</h6>

                <Row>
                    <Col xxl="9" xl="9" md="8" sm="8" xs="6" className='mt-3'>
                        {/* <p className='text-center'>Available to trade: 0.00</p> */}
                    </Col>

                    <Col xxl="3" xl="3" md="4" sm="4" xs="6" className='mt-3'>
                        <p className='text-center'>
                            {
                                isUserTokenBalanceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin size='small' />
                                    </div>
                                ) : (
                                    isBNBSelected ? (
                                        <NumberFormat
                                            value={userBNBBalance ? userBNBBalance : 0.0}
                                            displayType={'text'}
                                            prefix="Balance: "
                                            decimalScale={3}
                                            suffix=" BNB"
                                            thousandSeparator={true}
                                        />
                                    ) : (
                                        <NumberFormat
                                            value={userBUSDTokenBalance ? userBUSDTokenBalance : 0.0}
                                            displayType={'text'}
                                            prefix="Balance: "
                                            suffix=" BUSD"
                                            decimalScale={3}
                                            thousandSeparator={true}
                                        />
                                    )

                                )
                            }

                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xxl="9" xl="9" md="8" sm="8" xs="6" className='mt-3'>
                        {console.log("userBuyAmount", userBuyAmount)}
                        <Input
                            onChange={handleBuyBUSDAmountChange}
                            value={userBuyAmount && userBuyAmount !== '' ? userBuyAmount : ''}
                            size="large"
                            className='stake-fathom-input'
                            type="number"
                            placeholder='0.0000'
                        />
                    </Col>

                    <Col xxl="3" xl="3" md="4" sm="4" xs="6" className='mt-3'>
                        <Select
                            size="large"
                            onChange={handleCurrencyTypeChange}
                            value={selectedCurrencyType}
                            className='w-100 stake-fathom-input'
                            bordered={false}
                        >
                            <Option value={process.env.REACT_APP_WBNB_ADDRESS}>
                                <img
                                    src={bnbTokenImage}
                                    alt="bnb"
                                    style={{ width: '20px', marginRight: '10px', borderRadius: '50%' }}
                                />
                                BNB
                            </Option>
                            <Option value={process.env.REACT_APP_BUSD_ADDRESS}>
                                <img
                                    src={busdTokenImage}
                                    alt="busd"
                                    style={{ width: '20px', marginRight: '10px', borderRadius: '50%' }}
                                />
                                BUSD
                            </Option>
                        </Select>
                    </Col>
                </Row>

                <Row>
                    <Col xxl="12" xl="12" md="12" sm="12" xs="12" className='mt-3'>
                        <Input
                            value={shillingAddress}
                            onChange={e => setShillingAddress(e.target.value)}
                            className='stake-fathom-input'
                            size="large"
                            placeholder='Shilling Code'
                        />
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                        <Button
                            size="large"
                            disabled={isUserTokenBalanceLoading}
                            className='col-12 nutgain-primary-button'
                            onClick={() => setSliderValue(25)}
                        >25%</Button>
                    </Col>

                    <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                        <Button
                            size="large"
                            disabled={isUserTokenBalanceLoading}
                            className='col-12 nutgain-primary-button'
                            onClick={() => setSliderValue(50)}
                        >50%</Button>
                    </Col>

                    <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                        <Button
                            size="large"
                            disabled={isUserTokenBalanceLoading}
                            className='col-12 nutgain-primary-button'
                            onClick={() => setSliderValue(75)}
                        >75%</Button>
                    </Col>

                    <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                        <Button
                            size="large"
                            disabled={isUserTokenBalanceLoading}
                            className='col-12 nutgain-primary-button'
                            onClick={() => setSliderValue(100)}
                        >MAX</Button>
                    </Col>
                </Row>

                <Row className='col-12 mx-auto mt-5'>
                    <Slider
                        disabled={isUserTokenBalanceLoading}
                        tipFormatter={formatter}
                        value={sliderValue}
                        onChange={(value) => setSliderValue(value)} />
                </Row>

                <Row className='mt-3 text-center'>
                    <div>
                        {
                            isTokenAmountEstimationLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <Button
                                    className='swap-button'>
                                    <ArrowDownOutlined />
                                </Button>
                            )
                        }

                    </div>
                </Row>

                <h6 className='text-center primary-text mt-3'>TO (ESTIMATED)</h6>

                <Row>
                    <Col xxl="9" xl="9" md="8" sm="8" xs="6" className='mt-2'>
                    </Col>

                    <Col xxl="3" xl="3" md="4" sm="4" xs="6" className='mt-2'>
                        <p className='text-center word-break'>
                            <NumberFormat
                                value={userFathomTokenBalance ? userFathomTokenBalance : 0.0}
                                displayType={'text'}
                                prefix="Balance: "
                                decimalScale={3}
                                thousandSeparator={true}
                            />
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xxl="9" xl="9" md="8" sm="8" xs="6" className='mt-3'>
                        <Input
                            onChange={handleFathomTokenEstimatedAmount}
                            disabled={isTokenAmountEstimationLoading}
                            value={estimatedFathomTokenAmount ? estimatedFathomTokenAmount : ''}
                            size="large"
                            className='stake-fathom-input'
                            type="number"
                            placeholder='0.0000'
                        />
                    </Col>

                    <Col xxl="3" xl="3" md="4" sm="4" xs="6" className='mt-3'>
                        <Select
                            size="large"
                            defaultValue="bnb"
                            className='w-100 stake-fathom-input'
                            bordered={false}
                        >
                            <Option value="bnb">
                                <img
                                    src={fathom}
                                    alt="fathom"
                                    style={{ width: '20px', marginRight: '10px', borderRadius: '50%' }} />
                                FATHOM
                            </Option>
                        </Select>
                    </Col>
                </Row>

                {
                    isBNBSelected ? (
                        <Row className='mt-4'>


                            <Col xxl="6" xl="6" md="6" sm="12" xs="12">
                                <Button
                                    size="large"
                                    onClick={handleBuy}
                                    loading={isTokenBuyLoading}
                                    style={{ marginRight: '5px' }}
                                    className='nutgain-primary-button col-12 mt-2'>
                                    BUY
                                </Button>
                            </Col>

                            <Col xxl="6" xl="6" md="6" sm="12" xs="12">
                                <Button
                                    size="large"
                                    loading={isBuyAndStakeTokenLoading}
                                    onClick={handleBuyAndStake}
                                    className='nutgain-primary-button col-12 mt-2'>
                                    BUY & STAKE
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row className='mt-4'>

                            <Col xxl="4" xl="4" md="4" sm="12" xs="12">
                                <Button
                                    size="large"
                                    loading={isTokenApprovalLoading}
                                    disabled={isTokenAmountEstimationLoading}
                                    onClick={handleApprove}
                                    style={{ marginRight: '5px' }}
                                    className='nutgain-primary-button col-12 mt-2'>
                                    APPROVE
                                </Button>
                            </Col>

                            <Col xxl="4" xl="4" md="4" sm="12" xs="12">
                                <Button
                                    size="large"
                                    onClick={handleBuy}
                                    loading={isTokenBuyLoading}
                                    disabled={isBuyButtonsDisabled}
                                    style={{ marginRight: '5px' }}
                                    className='nutgain-primary-button col-12 mt-2'>
                                    BUY
                                </Button>
                            </Col>

                            <Col xxl="4" xl="4" md="4" sm="12" xs="12">
                                <Button
                                    size="large"
                                    loading={isBuyAndStakeTokenLoading}
                                    onClick={handleBuyAndStake}
                                    disabled={isBuyButtonsDisabled}
                                    className='nutgain-primary-button col-12 mt-2'>
                                    BUY & STAKE
                                </Button>
                            </Col>
                        </Row>
                    )
                }

        </div>
    )
}

export default Buy