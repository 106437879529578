import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd'
import { getEllipsisTxt } from '../../Helpers/Formatters';
import UpcomingCountdownMini from '../UpcomingCountdown/UpcomingCountdownMini';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import { DateTime } from 'luxon';
import { utils } from 'ethers';

function MyWorkSheetTable() {

    const [workSheetData, setWorkSheetData] = useState([])
    const [isWorkSheetDataLoading, setIsWorkSheetDataLoading] = useState(false)

    const { Column } = Table;

    useEffect(() => {
        fetchLatestBurnRecords()
    }, [])

    const fetchLatestBurnRecords = async () => {

        try {
            setIsWorkSheetDataLoading(true)
            const endpoint = `${process.env.REACT_APP_BASE_URL}/referral/all-token-minted-events?page=1&limit=10`
            const response = await axios.get(endpoint)
            if (response.status === 200) {
                setWorkSheetData(response.data?.payload?.items)
            }
            setIsWorkSheetDataLoading(false)
        } catch (error) {
            console.log("Error while fetching work sheet ", error)
            setIsWorkSheetDataLoading(false)
            setWorkSheetData([])
        }
    }

    const getActiveStatus = (status) => {
        switch (!status) {
            case false:
                return (<Tag color="red">NO</Tag>)
            case true:
                return (<Tag color="green">YES</Tag>)
            default:
                break;
        }
    }

    return (
        <div className='table-responsive'>
            <div className='table'>
                <Table
                    rowKey="id"
                    loading={isWorkSheetDataLoading}
                    dataSource={workSheetData}
                    pagination={false} // since pagination applied seperately with backend pagination
                >
                    <Column title="BLOCK#" dataIndex="blockNumber" />
                    <Column title="DATE" render={(_, record) => (
                        DateTime.fromSeconds(parseInt(record?.mintedTimestamp)).toFormat('yyyy/LL/dd')
                    )} />
                    {/* TODO: pass time as a prop to 'UpcomingCountdownMini' */}
                    <Column title="TIME" render={(_, record) => <UpcomingCountdownMini key={record?.id} endTime={record?.mintedTimestamp} />} />
                    <Column title="BUYER" dataIndex="to" render={(_, record) => (getEllipsisTxt(record?.callerAddress, 4))} />
                    <Column title="$FATHOM" render={(_, record) =>
                        <NumberFormat
                            value={utils.formatEther(record?.amountSpend).toString()}
                            thousandSeparator={true}
                            decimalScale={3}
                            displayType={'text'}
                        />
                    } />
                    <Column title="WORK" render={(_, record) =>
                        <NumberFormat
                            value={utils.formatEther(record?.mintedTokenAmount).toString()}
                            thousandSeparator={true}
                            decimalScale={3}
                            displayType={'text'}
                        />
                    } />
                    <Column title="ACTIVE?" render={(_, record) => getActiveStatus(record?.isTokenBurned)} />
                    {/* TODO: pass time as a prop to 'UpcomingCountdownMini' */}
                    <Column title="BURN TIME" render={(_, record) => <UpcomingCountdownMini key={record?.id} endTime={record?.mintedTimestamp} />} />
                    <Column title="BURN DATE" render={(_, record) => DateTime.fromISO(record?.updatedAt).toFormat('yyyy/LL/dd')} />
                </Table>
            </div>
        </div>
    )
}

export default MyWorkSheetTable