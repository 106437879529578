import React from 'react'
import Referral from './Referral/Referral'
import Commission from './Commission/Commission'
import Work from './Work/Work'
import ReportCard from './ReportCard/ReportCard'
import { HashScroll } from "react-hash-scroll";

function ShillingPageV2() {
  return (
    <div className='mt-5 mb-5'>

        <HashScroll hash="#schoolId" position='center'>
          <div className='col-lg-10 mx-auto'>
            <Referral />
          </div>
        </HashScroll>

        <HashScroll hash="#commission" position='center'>
          <div className='col-lg-10 mx-auto mt-5'>
              <Commission />
          </div>
        </HashScroll>

        
          <div>
            <Work />
          </div>

        
          <div>
            <ReportCard />
          </div>
    </div>
  )
}

export default ShillingPageV2