import React from 'react'
import Chest from './Chest/Chest'
import Stakenomics from './Stakenomics/Stakenomics'
import Stake from './Stake/Stake'
import Unstake from './Unstake/Unstake'
import { HashScroll } from "react-hash-scroll";

function StakingPageV2() {

  return (
      <div className='col-lg-9 mx-auto mt-5 mb-5'>

          <HashScroll hash="#chest" position='center'>
            <div>
              <Chest />
            </div>
          </HashScroll>

          
          <div className='mt-3'>
            <Stakenomics />
          </div>


          <HashScroll hash="#stake" position='center'>
              <div className='mt-3'>
                <Stake />
              </div>
          </HashScroll>

          <HashScroll hash="#unstake" position='center'>
              <div className='mt-3'>
                <Unstake />
              </div>
          </HashScroll>
      </div>
  )
}

export default StakingPageV2