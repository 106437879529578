import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import ABIFunctions from './ABIFunctions'
import {
    getFathomTokenContractABIFunctions,
    getFathomStakingContractABIFunctions,
    getFathomStakingOceanContractABIFunctions,
    getFathomShillingContractABIFunctions,
    getFathomShillingSeaContractABIFunctions,
    getFathomWeatherContractABIFunctions,
    getFathomAirdropContractABIFunctions
} from '../../Blockchain/services/admin.service'
import { shillingConfigs } from '../../Blockchain/configs/shilling.configs'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'
import { weatherConfigs } from '../../Blockchain/configs/weather.configs'
import { commonConfigs } from '../../Blockchain/configs/common.configs'

function Admin() {

    const { TabPane } = Tabs
    const [fathomTokenReadFunctions, setFathomTokenReadFunctions] = useState([])
    const [fathomTokenWriteFunctions, setFathomTokenWriteFunctions] = useState([])

    const [stakingReadFunctions, setStakingReadFunctions] = useState([])
    const [stakingWriteFunctions, setStakingWriteFunctions] = useState([])

    const [stakingOceanReadFunctions, setStakingOceanReadFunctions] = useState([])
    const [stakingOceanWriteFunctions, setStakingOceanWriteFunctions] = useState([])

    const [shillingReadFunctions, setShillingReadFunctions] = useState([])
    const [shillingWriteFunctions, setShillingWriteFunctions] = useState([])

    const [shillingSeaReadFunctions, setShillingSeaReadFunctions] = useState([])
    const [shillingSeaWriteFunctions, setShillingSeaWriteFunctions] = useState([])

    const [weatherReadFunctions, setWeatherReadFunctions] = useState([])
    const [weatherWriteFunctions, setWeatherWriteFunctions] = useState([])

    const [airdropReadFunctions, setAirdropReadFunctions] = useState([])
    const [airdropWriteFunctions, setAirdropWriteFunctions] = useState([])

    const fetchFathomTokenContractFunctions = () => {
        const response = getFathomTokenContractABIFunctions()
        setFathomTokenReadFunctions(response.readFunctionsList)
        setFathomTokenWriteFunctions(response.writeFunctionList)
    }

    const fetchStakingContractFunctions = () => {
        const response = getFathomStakingContractABIFunctions()
        setStakingReadFunctions(response.readFunctionsList)
        setStakingWriteFunctions(response.writeFunctionList)
    }

    const fetchStakingOceanContractFunctions = () => {
        const response = getFathomStakingOceanContractABIFunctions()
        setStakingOceanReadFunctions(response.readFunctionsList)
        setStakingOceanWriteFunctions(response.writeFunctionList)
    }

    const fetchShillingContractFunctions = () => {
        const response = getFathomShillingContractABIFunctions()
        setShillingReadFunctions(response.readFunctionsList)
        setShillingWriteFunctions(response.writeFunctionList)
    }

    const fetchShillingSeaContractFunctions = () => {
        const response = getFathomShillingSeaContractABIFunctions()
        setShillingSeaReadFunctions(response.readFunctionsList)
        setShillingSeaWriteFunctions(response.writeFunctionList)
    }

    const fetchWeatherContractFunctions = () => {
        const response = getFathomWeatherContractABIFunctions()
        setWeatherReadFunctions(response.readFunctionsList)
        setWeatherWriteFunctions(response.writeFunctionList)
    }

    const fetchAirdropContractFunctions = () => {
        const response = getFathomAirdropContractABIFunctions()
        setAirdropReadFunctions(response.readFunctionsList)
        setAirdropWriteFunctions(response.writeFunctionList)
    }


    useEffect(() => {
        fetchFathomTokenContractFunctions()
        fetchStakingContractFunctions()
        fetchStakingOceanContractFunctions()
        fetchStakingOceanContractFunctions()
        fetchShillingContractFunctions()
        fetchShillingSeaContractFunctions()
        fetchWeatherContractFunctions()
        fetchAirdropContractFunctions()
    }, [])

    return (
        <div>
            <div className='mt-5 mb-5'>
                <Tabs defaultActiveKey="1" type="card" size="large" fixed >
                    <TabPane tab="Fathom Token" key="1">
                        <ABIFunctions
                            contractABI={JSON.parse(shillingConfigs.fathomContractABI)}
                            contractAddress={process.env.REACT_APP_FATHOM_ADDRESS}
                            readFunctions={fathomTokenReadFunctions}
                            writeFunctions={fathomTokenWriteFunctions}
                            tabName={'Token'}
                        />
                    </TabPane>
                    <TabPane tab="Staking" key="2">
                        <ABIFunctions
                            contractABI={JSON.parse(stakingConfigs.stakingContractABI)}
                            contractAddress={process.env.REACT_APP_STAKING_CONTRACT_ADDRESS}
                            readFunctions={stakingReadFunctions}
                            writeFunctions={stakingWriteFunctions}
                            tabName={'Staking'}
                        />
                    </TabPane>
                    <TabPane tab="Staking Ocean" key="3">
                        <ABIFunctions
                            contractABI={JSON.parse(stakingConfigs.oceanContractABI)}
                            contractAddress={process.env.REACT_APP_STAKING_OCEAN_CONTRACT_ADDRESS}
                            readFunctions={stakingOceanReadFunctions}
                            writeFunctions={stakingOceanWriteFunctions}
                            tabName={'StakingOcean'}
                        />
                    </TabPane>
                    <TabPane tab="Shilling" key="4">
                        <ABIFunctions
                            contractABI={JSON.parse(shillingConfigs.shillingContractABI)}
                            contractAddress={process.env.REACT_APP_SHILLING_CONTRACT_ADDRESS}
                            readFunctions={shillingReadFunctions}
                            writeFunctions={shillingWriteFunctions}
                            tabName={'Shilling'}
                        />
                    </TabPane>

                    <TabPane tab="Shilling Sea" key="5">
                        <ABIFunctions
                            contractABI={JSON.parse(shillingConfigs.shillingOceanContractABI)}
                            contractAddress={process.env.REACT_APP_SHILLING_OCEAN_CONTRACT_ADDRESS}
                            readFunctions={shillingSeaReadFunctions}
                            writeFunctions={shillingSeaWriteFunctions}
                            tabName={'Shilling Sea'}
                        />
                    </TabPane>
                    <TabPane tab="Weather" key="6">
                        <ABIFunctions
                            contractABI={JSON.parse(weatherConfigs.weatherContractABI)}
                            contractAddress={process.env.REACT_APP_WEATHER_CONTRACT_ADDRESS}
                            readFunctions={weatherReadFunctions}
                            writeFunctions={weatherWriteFunctions}
                            tabName={'Weather'}
                        />
                    </TabPane>
                    <TabPane tab="Airdrop" key="7">
                        <ABIFunctions
                            contractABI={JSON.parse(commonConfigs.airDrop.contractABI)}
                            contractAddress={process.env.REACT_APP_MULTISENDER_CONTRACT_ADDRESS}
                            readFunctions={airdropReadFunctions}
                            writeFunctions={airdropWriteFunctions}
                            tabName={'Airdrop'}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Admin