import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    margin: true
};

export const SideMenuSlice = createSlice({
    name: "SideMenuSlice",
    initialState,
    reducers: {
        change: (state) => {
            state.margin = !state.margin
        }
    }
})

// Action creators are generated for each case reducer function
export const { change } = SideMenuSlice.actions;

export default SideMenuSlice.reducer;