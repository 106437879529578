import React from 'react';
import { Column } from '@ant-design/plots';

function WithdrawLimits() {

    const data = [
        {
          days: 'Days 1-2',
          tax: 100,
        },
        {
          days: 'Days 3-4',
          tax: 75,
        },
        {
          days: 'Days 5-6',
          tax: 50,
        },
        {
          days: 'Days 7-8',
          tax: 25,
        },
        {
          days: 'After 8 Days',
          tax: 0,
        }
      ];

      const config = {
        data,
        xField: 'days',
        yField: 'tax',
        theme: {
          styleSheet: {
            fontFamily: 'PT Sans Caption'
          }
        },
        xAxis: {
          label: {
            autoHide: false,
            autoRotate: true,
            style: {
              fontWeight: 500,
              fontSize: 14
            },
          },
        },
        yAxis: {
            label: null
        },
        label: {
            style: {
              fill: '#183d99',
              fontWeight: 500,
              fontSize: 14
            },
            content: ({tax}) => {
              if (tax === 100) {
                return ('Time Lock');
              } else {
                return (tax) + '% Tax';
              }
            }
        },
        tooltip: {
            formatter: (data) => {
                if (data.tax === 100) {
                    return { name: data.days, value: 'Time Lock' };
                } else {
                    return { name: data.days, value: data.tax + '% Tax' };
                }
            },
            fontFamily: 'PT Sans Caption',
        },
        color: '#00b0f2',
      };

    return (
        <Column {...config} />
    )
}

export default WithdrawLimits