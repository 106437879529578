import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'

function UpcomingCountdownMini(props) {

  const { endTime } = props
  const [burnAtTime, setBurnAtTime] = useState(DateTime.now())

  useEffect(() => {
    if (endTime) {
      const date = DateTime.fromSeconds(parseInt(endTime))
      const burnAt = date.plus({ hours: 12 })
      setBurnAtTime(burnAt)
    }
  }, [endTime])

  return (
    <Countdown key={burnAtTime.toString()} date={burnAtTime.toString()} />
  )
}

export default UpcomingCountdownMini