import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import { getTotalFathomTokenInStakingOcean, getTotalSupply, getNumbersOfFathomTokenReleaseForNextCycle, getReleasePercentageOfNextShift, getNextWaveTime } from '../../Blockchain/services/shilling.service'
import { getLastWave } from '../../Blockchain/services/staking.service'
import { getTokenPriceInUSDByTokenAddress } from '../../Blockchain/services/util.service'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'
import { DateTime } from 'luxon'
import Countdown from 'react-countdown'

import NumberFormat from 'react-number-format'

function Ocean() {

    const fathomTokenAddress = stakingConfigs.fathomTokenAddress

    const [fathomInStakingOcean, setFathomInStakingOcean] = useState(0.0)
    const [isFathomInStakingOceanLoading, setIsFathomInStakingOceanLoading] = useState(false)

    const [releasePercentage, setReleasePercentage] = useState(0.0)
    const [isReleasePercentageLoading, setIsReleasePercentageLoading] = useState(false)

    const [lastWaveStakingOcean, setLastWaveStakingOcean] = useState(0.0)
    const [isLastWaveStakingOceanLoading, setIsLastWaveStakingOceanLoading] = useState(false)

    const [isTotalSupplyLoading, setIsTotalSupplyLoading] = useState(false)
    const [totalSupplyFathom, setTotalSupplyFathom] = useState(0.0)
    const [totalSupplyPercentage, setTotalSupplyPercentage] = useState(0.0)

    const [isFathomTokenPriceLoading, setIsFathomTokenPriceLoading] = useState(false)
    const [fathomTokenPriceInUSD, setFathomTokenPriceInUSD] = useState(0)
    const [lastWaveValueInUSD, setLastWaveValueInUSD] = useState(0)

    const [numbersOfFathomTokenForNextWave, setNumbersOfFathomTokenForNextWave] = useState(0)
    const [numbersOfFathomTokenForNextWaveInUSD, setNumbersOfFathomTokenForNextWaveInUSD] = useState(0)
    const [isNextWaveFathomTokenAmountLoading, setIsNextWaveFathomTokenAmountLoading] = useState(false)

    const [nextWaveTime, setNextWaveTime] = useState(0)
    const [nextWaveTimeTimeStamp, setNextWaveTimeTimeStamp] = useState(DateTime.now())
    const [isNextWaveTimeLoading, setIsNextWaveTimeLoading] = useState(false)

    useEffect(() => {
        if (nextWaveTime) {
            const waveTime = DateTime.fromSeconds(parseInt(nextWaveTime))
            setNextWaveTimeTimeStamp(waveTime)
        } else {
            setNextWaveTimeTimeStamp(DateTime.now())
        }
    }, [nextWaveTime])

    const fetchTotalStakedFathom = async () => {
        setIsFathomInStakingOceanLoading(true)
        const totalSold = await getTotalFathomTokenInStakingOcean()
        setFathomInStakingOcean(totalSold)
        setIsFathomInStakingOceanLoading(false)
    }

    const fetchReleasePercentage = async () => {
        setIsReleasePercentageLoading(true)
        const releasePercentage = await getReleasePercentageOfNextShift()
        setReleasePercentage(releasePercentage)
        setIsReleasePercentageLoading(false)
    }

    const fetchTotalSupply = async () => {
        setIsTotalSupplyLoading(true)
        const totalSupply = await getTotalSupply()
        setTotalSupplyFathom(totalSupply)
        setIsTotalSupplyLoading(false)
    }

    const fetchLastWave = async () => {
        setIsLastWaveStakingOceanLoading(true)
        const lastWaveValue = await getLastWave()
        setLastWaveStakingOcean(lastWaveValue)
        setIsLastWaveStakingOceanLoading(false)
    }

    const fetchFathomTokenPrice = async () => {
        setIsFathomTokenPriceLoading(true)
        const tokenPrice = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
        setFathomTokenPriceInUSD(tokenPrice)
        setIsFathomTokenPriceLoading(false)
    }

    const fetchNumbersOfFathomTokensForNextWave = async () => {
        setIsNextWaveFathomTokenAmountLoading(true)
        const fathomTokenAmount = await getNumbersOfFathomTokenReleaseForNextCycle()
        setNumbersOfFathomTokenForNextWave(fathomTokenAmount)
        setIsNextWaveFathomTokenAmountLoading(false)
    }

    const fetchNextWaveTime = async () => {
        setIsNextWaveTimeLoading(true)
        const nextWaveTime = await getNextWaveTime()
        setNextWaveTime(nextWaveTime)
        setIsNextWaveTimeLoading(false)
        
    }

    useEffect(() => {
        fetchTotalStakedFathom()
        fetchTotalSupply()
        fetchLastWave()
        fetchFathomTokenPrice()
        fetchNumbersOfFathomTokensForNextWave()
        fetchReleasePercentage()
        fetchNextWaveTime()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (totalSupplyFathom && fathomInStakingOcean) {
            const percentageOfTotalSupply = (fathomInStakingOcean / totalSupplyFathom) * 100
            setTotalSupplyPercentage(percentageOfTotalSupply)
        }

        if (fathomTokenPriceInUSD && parseFloat(lastWaveStakingOcean) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(lastWaveStakingOcean)
            setLastWaveValueInUSD(value)
        } else {
            setLastWaveValueInUSD(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(numbersOfFathomTokenForNextWave) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(numbersOfFathomTokenForNextWave)
            setNumbersOfFathomTokenForNextWaveInUSD(value)
        } else {
            setNumbersOfFathomTokenForNextWaveInUSD(0)
        }
    }, [totalSupplyFathom, fathomInStakingOcean, fathomTokenPriceInUSD, lastWaveStakingOcean, numbersOfFathomTokenForNextWave])

    return (
        <div className='mb-5'>
            <Card className='mt-5 transparent'>
                <h3 className='text-center text-uppercase'>Ocean</h3>
            </Card>

            <Card className='fathom-finance-card col-lg-9 col-md-12 mx-auto mb-3'>
                <Row>
                    <div className='fw-bold text-center'>
                        <span className='card-title'>
                            {
                                isReleasePercentageLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={releasePercentage}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                        suffix={'%'}
                                    />
                                )
                            } 
                            of the Ocean Released in Next Wave &nbsp;
                            {
                                isNextWaveTimeLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <Countdown
                                        key={nextWaveTimeTimeStamp.toString()}
                                        date={nextWaveTimeTimeStamp.toString()}
                                        renderer={props => (
                                            < >
                                                <span >
                                                    {props.formatted.days}
                                                </span>
                                                :
                                                <span>
                                                    {props.formatted.hours}
                                                </span>
                                                :
                                                <span>
                                                    {props.formatted.minutes}

                                                </span>
                                                :
                                                <span>
                                                    {props.formatted.seconds}
                                                </span>
                                            </>
                                        )}
                                        zeroPadTime={2}
                                    />    
                                )
                            }
                            
                        </span>
                    </div>
                </Row>
            </Card>

            <Row className='mb-3'>
                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Contains</span>}>
                        <span className='card-inner-text'>
                            {
                                isFathomInStakingOceanLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={fathomInStakingOcean}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>% of total supply: </span>
                        <span className='card-inner-text'>
                            {
                                isFathomInStakingOceanLoading || isTotalSupplyLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={totalSupplyPercentage}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                        suffix={'%'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>$Fathom Transfers</span>}>
                        <span className='primary-text card-inner-text'>Sent: </span>
                        <span className='card-inner-text'>
                            {
                                isFathomInStakingOceanLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    0
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>Received: </span>
                        <span className='card-inner-text'>
                            {
                                isFathomInStakingOceanLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    0
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Previous Wave</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        <span className='card-inner-text'>
                            {
                                isLastWaveStakingOceanLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={lastWaveStakingOcean}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span>
                        <span className='card-inner-text'>
                            {
                                isLastWaveStakingOceanLoading || isFathomTokenPriceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={lastWaveValueInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Next Wave</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        <span className='card-inner-text'>
                            {
                                isNextWaveFathomTokenAmountLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={numbersOfFathomTokenForNextWave}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span>
                        <span className='card-inner-text'>
                            {
                                isNextWaveFathomTokenAmountLoading || isFathomTokenPriceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={numbersOfFathomTokenForNextWaveInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Ocean