import { Tabs } from 'antd'
import React from 'react'

import ReadFunctions from './ReadFunctions'
import WriteFunctions from './WriteFunctions'

function ABIFunctions(props) {

    const { readFunctions, writeFunctions, contractABI, contractAddress, tabName } = props
    const { TabPane } = Tabs;


    return (
        <div>
            <Tabs defaultActiveKey="1" type="card" size="large" fixed >
                <TabPane tab="Read Functions" key="1">
                    <ReadFunctions
                        contractABI={contractABI}
                        contractAddress={contractAddress}
                        readFunctions={readFunctions}
                    />
                </TabPane>
                <TabPane tab="Write Functions" key="2">
                    <WriteFunctions
                        contractABI={contractABI}
                        contractAddress={contractAddress}
                        writeFunctions={writeFunctions}
                        tabName={tabName} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ABIFunctions