import React from 'react'
import { Card } from 'antd'
import MyWorkSheetTable from '../../../Components/MyWorkSheetTable/MyWorkSheetTable'

function MyWorkSheet() {
  return (
    <Card className='fathom-finance-card'>
        <h6 className='text-center card-title'>My Work Sheet</h6>
        <hr />

        <MyWorkSheetTable />
    </Card>
  )
}

export default MyWorkSheet