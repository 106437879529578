import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import { Row, Col, Spinner } from 'reactstrap'
import {
    AreaChartOutlined,
    DollarCircleOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons'
import {
    getBEP20TokenBalanceByWalletAddress,
    getTokenPriceInUSDByTokenAddress,
    getCirculationSupplyOfAToken
} from '../../Blockchain/services/util.service'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'
import NumberFormat from 'react-number-format'
import {
    getStakingDetailsByWalletAddress,
    getMassDividendBalanceByUser,
    getClaimableRewards,
    getMassReflectionAmount,
    getLastWave,
    getTotalMassExisting
} from '../../Blockchain/services/staking.service'
import { getNumbersOfFathomTokenReleaseForNextCycle, getTotalFathomTokenInStakingOcean } from '../../Blockchain/services/shilling.service'
import { useAccount } from 'wagmi'

function MyStats() {

    const { address: account } = useAccount();

    const fathomTokenAddress = stakingConfigs.fathomTokenAddress
    const [fathomTokenPriceInUSD, setFathomTokenPriceInUSD] = useState(0)
    const [isFathomTokenPriceLoading, setIsFathomTokenPriceLoading] = useState(false)

    const [userFathomTokenHoldings, setUserFathomTokenHoldings] = useState(0)
    const [userFathomTokenHoldingsValueInUSD, setUserFathomTokenHoldingsValueInUSD] = useState(0)
    const [isUserFathomTokenHoldingLoading, setIsUserFathomTokenHoldingLoading] = useState(false)

    const [userStakedFathomTokenAmount, setUserStakedFathomTokenAmount] = useState(0)
    const [userStakedFathomTokenAmountInUSD, setUserStakedFathomTokenAmountInUSD] = useState(0)
    const [isUserStakedTokenAmountLoading, setIsUserStakedTokenAmountLoading] = useState(false)

    const [isUserTotalMassReceivedLoading, setIsUserTotalMassReceivedLoading] = useState(false)
    const [userTotalReceivedMassTokenAmount, setUserTotalReceivedMassTokenAmount] = useState(0)
    // eslint-disable-next-line
    const [userTotalReceivedMassTokenAmountInUSD, setUserTotalReceivedMassTokenAmountInUSD] = useState(0)

    const [massReceivedForUser, setUserMassReceivedForUser] = useState(0)
    const [userMassReflectionAmount, setUserMassReflectionAmount] = useState(0)
    // eslint-disable-next-line
    const [userMassReflectionAmountInUSD, setUserMassReflectionAmountInUSD] = useState(0)
    const [userMassReflectionValue, setUserMassReflectionValue] = useState(0)


    const [isUserClaimableAmountLoading, setIsUserClaimableAmountLoading] = useState(false)
    const [userClaimableTokenAmount, setUserClaimableTokenAmount] = useState(0)
    const [userClaimableTokenAmountInUSD, setUserClaimableTokenAmountInUSD] = useState(0)

    const [numbersOfFathomTokenForNextWave, setNumbersOfFathomTokenForNextWave] = useState(0)
    const [numbersOfFathomTokenForNextWaveInUSD, setNumbersOfFathomTokenForNextWaveInUSD] = useState(0)
    const [isNextWaveFathomTokenAmountLoading, setIsNextWaveFathomTokenAmountLoading] = useState(false)

    const [isMassReflectionLoading, setIsMassReflectionLoading] = useState(false)
    const [massReflectionAmount, setMassReflectionAmount] = useState(0)
    // eslint-disable-next-line
    const [totalReflectedMassValue, setTotalReflectedMassValue] = useState(0)

    const [tokenCirculationSupply, setTokenCirculationSupply] = useState(0)
    const [isTokenCirculationSupplyLoading, setIsTokenCirculationSupplyLoading] = useState(false)

    const [tokenMarketCap, setTokenMarketCap] = useState(0)

    // eslint-disable-next-line
    const [lastWaveValueInUSD, setLastWaveValueInUSD] = useState(0)
    const [lastWaveStakingOcean, setLastWaveStakingOcean] = useState(0.0)
    const [isLastWaveStakingOceanLoading, setIsLastWaveStakingOceanLoading] = useState(false)

    const [dailyRoi, setDailyRoi] = useState(0.0)
    const [twelveMonthApy, setTwelveMonthApy] = useState(0.0)

    const [isMasExistingLoading, setIsMasExistingLoading] = useState(false)
    const [totalMassExisting, setTotalMassExisting] = useState(0.0)
    
    const [fathomInStakingOcean, setFathomInStakingOcean] = useState(0.0)
    const [isFathomInStakingOceanLoading, setIsFathomInStakingOceanLoading] = useState(false)

    const [calculatedCirculatingSupply, setCalculatedCirculatingSupply] = useState(0.0)

    const [userMassPercentage, setUserMassPercentage] = useState(0.0)

    const fetchTotalStakedFathom = async () => {
        setIsFathomInStakingOceanLoading(true)
        const totalSold = await getTotalFathomTokenInStakingOcean()
        setFathomInStakingOcean(totalSold)
        setIsFathomInStakingOceanLoading(false)
    }

    const fetchLastWave = async () => {
        setIsLastWaveStakingOceanLoading(true)
        const lastWaveValue = await getLastWave()
        setLastWaveStakingOcean(lastWaveValue)
        setIsLastWaveStakingOceanLoading(false)
    }

    const fetchNumbersOfFathomTokensForNextWave = async () => {
        setIsNextWaveFathomTokenAmountLoading(true)
        const fathomTokenAmount = await getNumbersOfFathomTokenReleaseForNextCycle()
        setNumbersOfFathomTokenForNextWave(fathomTokenAmount)
        setIsNextWaveFathomTokenAmountLoading(false)
    }

    const fetchFathomCirculatingSupply = async () => {
        setIsTokenCirculationSupplyLoading(true)
        const circulatingSupply = await getCirculationSupplyOfAToken(fathomTokenAddress)
        setTokenCirculationSupply(circulatingSupply)
        setIsTokenCirculationSupplyLoading(false)
    }

    const fetchTotalMassReceivedAmount = async () => {
        if (account) {
            setIsUserTotalMassReceivedLoading(true)
            const totalMassForUser = await getMassDividendBalanceByUser(account)
            setUserTotalReceivedMassTokenAmount(totalMassForUser)
            setIsUserTotalMassReceivedLoading(false)
        } else {
            setUserTotalReceivedMassTokenAmount(0)
        }

    }

    const fetchUserStakingDetails = async () => {
        if (account) {
            setIsUserStakedTokenAmountLoading(true)
            const stakingDetails = await getStakingDetailsByWalletAddress(account)
            setUserStakedFathomTokenAmount(stakingDetails?.totalFathomTokenInvested)
            setUserMassReceivedForUser(stakingDetails?.totalMassReceived)
            setUserMassReflectionValue(stakingDetails?.userMassReflection)
            setIsUserStakedTokenAmountLoading(false)
        } else {
            setUserStakedFathomTokenAmount(0)
            setUserMassReceivedForUser(0)
        }
    }

    const fetchFathomTokenPrice = async () => {
        setIsFathomTokenPriceLoading(true)
        const tokenPrice = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
        setFathomTokenPriceInUSD(tokenPrice)
        setIsFathomTokenPriceLoading(false)
    }

    const fetchUserFathomTokenBalance = async () => {
        if (account) {
            setIsUserFathomTokenHoldingLoading(true)
            const fathomTokenBalance = await getBEP20TokenBalanceByWalletAddress(fathomTokenAddress, account)
            setUserFathomTokenHoldings(fathomTokenBalance)
            setIsUserFathomTokenHoldingLoading(false)
        } else {
            setUserFathomTokenHoldings(0.0)
        }

    }

    const fetchClaimableTokenAmount = async () => {
        if (account) {
            setIsUserClaimableAmountLoading(true)
            const rewardsValue = await getClaimableRewards(account)
            setUserClaimableTokenAmount(rewardsValue?.claimableRewards)
            setIsUserClaimableAmountLoading(false)
        } else {
            setUserClaimableTokenAmount(0)
        }

    }

    const fetchMassReflection = async () => {
        if (account) {
            setIsMassReflectionLoading(true)
            const massReflectionValue = await getMassReflectionAmount(account)
            setMassReflectionAmount(massReflectionValue.massReflectionAmount)
            setTotalReflectedMassValue(massReflectionValue.totalReflectedMassValue)
            setIsMassReflectionLoading(false)
        } else {
            setIsMassReflectionLoading(0)
        }
    }
    const fetchMassExisting = async () => {
        setIsMasExistingLoading(true)
        const response = await getTotalMassExisting()
        setTotalMassExisting(response)
        setIsMasExistingLoading(false)
    }
    

    useEffect(() => {
        fetchUserFathomTokenBalance()
        fetchUserStakingDetails()
        fetchTotalMassReceivedAmount()
        fetchClaimableTokenAmount()
        fetchMassReflection()
        fetchLastWave()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        fetchFathomTokenPrice()
        fetchNumbersOfFathomTokensForNextWave()
        fetchFathomCirculatingSupply()
        fetchMassExisting()
        fetchTotalStakedFathom()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (parseFloat(fathomTokenPriceInUSD) && parseFloat(tokenCirculationSupply)) {
            const mc = parseFloat(fathomTokenPriceInUSD) * parseFloat(tokenCirculationSupply)
            setTokenMarketCap(mc)
        } else {
            setTokenMarketCap(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(lastWaveStakingOcean) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(lastWaveStakingOcean)
            setLastWaveValueInUSD(value)
        } else {
            setLastWaveValueInUSD(0)
        }
    }, [tokenCirculationSupply, fathomTokenPriceInUSD, lastWaveStakingOcean])

    useEffect(() => {
        if (fathomInStakingOcean && tokenCirculationSupply) {
            const calculatedCirculatingSupplyValue = parseFloat(tokenCirculationSupply) - parseFloat(fathomInStakingOcean)
            setCalculatedCirculatingSupply(calculatedCirculatingSupplyValue)
        }
    }, [fathomInStakingOcean, tokenCirculationSupply])

    useEffect(() => {
        if (numbersOfFathomTokenForNextWaveInUSD && parseFloat(totalMassExisting)) {
            const dailyRoiValue = (numbersOfFathomTokenForNextWaveInUSD * 96) / parseFloat(totalMassExisting)

            setDailyRoi(dailyRoiValue * 100)
            setTwelveMonthApy(dailyRoiValue * 365 * 100)
        }
    }, [numbersOfFathomTokenForNextWaveInUSD, totalMassExisting])

    useEffect(() => {
        if (fathomTokenPriceInUSD && parseFloat(userFathomTokenHoldings) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(userFathomTokenHoldings)
            setUserFathomTokenHoldingsValueInUSD(value)
        } else {
            setUserFathomTokenHoldingsValueInUSD(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(userStakedFathomTokenAmount) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(userStakedFathomTokenAmount)
            setUserStakedFathomTokenAmountInUSD(value)
        } else {
            setUserStakedFathomTokenAmountInUSD(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(userTotalReceivedMassTokenAmount) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(userTotalReceivedMassTokenAmount)
            setUserTotalReceivedMassTokenAmountInUSD(value)
        } else {
            setUserTotalReceivedMassTokenAmountInUSD(0)
        }
        if (fathomTokenPriceInUSD && parseFloat(userMassReflectionAmount) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(userMassReflectionAmount)
            setUserMassReflectionAmountInUSD(value)
        } else {
            setUserMassReflectionAmountInUSD(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(userClaimableTokenAmount) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(userClaimableTokenAmount)
            setUserClaimableTokenAmountInUSD(value)
        } else {
            setUserClaimableTokenAmountInUSD(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(numbersOfFathomTokenForNextWave) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(numbersOfFathomTokenForNextWave)
            setNumbersOfFathomTokenForNextWaveInUSD(value)
        } else {
            setNumbersOfFathomTokenForNextWaveInUSD(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fathomTokenPriceInUSD,
        userFathomTokenHoldings,
        userStakedFathomTokenAmount,
        userTotalReceivedMassTokenAmount,
        userMassReflectionAmount,
        userClaimableTokenAmount,
        numbersOfFathomTokenForNextWave
    ])

    useEffect(() => {
        if (userTotalReceivedMassTokenAmount && massReceivedForUser) {
            const value = parseFloat(userTotalReceivedMassTokenAmount) - parseFloat(massReceivedForUser)
            setUserMassReflectionAmount(value)
        } else {
            setUserMassReflectionAmount(0)
        }
    }, [massReceivedForUser, userTotalReceivedMassTokenAmount])

    useEffect(() => {
        if (userTotalReceivedMassTokenAmount && totalMassExisting) {
            const userMassPercentage = (parseFloat(userTotalReceivedMassTokenAmount) / parseFloat(totalMassExisting)) * 100
            setUserMassPercentage(userMassPercentage)
        } else {
            setUserMassPercentage(0.0)
        }
    }, [userTotalReceivedMassTokenAmount, totalMassExisting])

    return (
        <div>
            <Card className='mt-4 transparent'>
                <h3 className='text-center text-uppercase'>My Stats</h3>
            </Card>

            <Card className='fathom-finance-card col-lg-10 col-md-12 mx-auto mb-3'>
                <Row>
                    <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12" className='text-center my-auto'>
                        <div className='d-flex'>
                            <div className='card-icon-highlighted-light'>
                                <DollarCircleOutlined style={{ fontSize: '30px' }} />
                            </div>

                            <div className='my-auto' style={{ marginLeft: '10px' }}>
                                <span className='card-title'>Price: </span> <br />
                                <span className='card-title'>
                                    {
                                        isFathomTokenPriceLoading ? (
                                            <div className='d-flex justify-content-center'>
                                                <Spinner size='sm' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                prefix='$ '
                                                style={{ fontSize: '18px' }}
                                                value={fathomTokenPriceInUSD}
                                                thousandSeparator={true}
                                                decimalScale={6}
                                                displayType={'text'}
                                            />
                                        )
                                    }
                                </span>
                            </div>
                        </div>
                    </Col>

                    <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12" className='text-center my-auto'>
                        <div className='d-flex'>
                            <div className='card-icon-highlighted-light'>
                                <AreaChartOutlined style={{ fontSize: '30px' }} />
                            </div>

                            <div className='my-auto' style={{ marginLeft: '10px' }}>
                                <span className='card-title'>Market Cap: </span> <br />
                                <span className='card-title'>
                                    {
                                        isFathomTokenPriceLoading || isTokenCirculationSupplyLoading ? (
                                            <div className='d-flex justify-content-center'>
                                                <Spinner size='sm' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                prefix='$ '
                                                style={{ fontSize: '18px' }}
                                                value={tokenMarketCap}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                                displayType={'text'}
                                            />
                                        )
                                    }
                                </span>
                            </div>
                        </div>
                    </Col>

                    <Col xxl="4" xl="4" lg="4" md="4" sm="12" xs="12" className='text-center my-auto'>
                        <div className='d-flex'>
                            <div className='card-icon-highlighted-light'>
                                <UsergroupAddOutlined style={{ fontSize: '30px' }} />
                            </div>

                            <div className='my-auto' style={{ marginLeft: '10px' }}>
                                <span className='card-title'>Circulating Supply: </span>
                                <span className='card-title'>
                                    {
                                        isTokenCirculationSupplyLoading || isFathomInStakingOceanLoading ? (
                                            <div className='d-flex justify-content-center'>
                                                <Spinner size='sm' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                suffix=' Fathom'
                                                style={{ fontSize: '18px' }}
                                                value={calculatedCirculatingSupply}
                                                thousandSeparator={true}
                                                decimalScale={4}
                                                displayType={'text'}
                                            />
                                        )
                                    }
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>

            <Row className='mb-3'>
                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Holdings</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        {
                            isUserFathomTokenHoldingLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={userFathomTokenHoldings}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span> <span className='card-inner-text'>
                            {
                                isUserFathomTokenHoldingLoading || isFathomTokenPriceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userFathomTokenHoldingsValueInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Stake</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        <span className='card-inner-text'>
                            {
                                isUserStakedTokenAmountLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userStakedFathomTokenAmount}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span>
                        <span className='card-inner-text'>
                            {
                                isUserStakedTokenAmountLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userStakedFathomTokenAmountInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Mass</span>}>
                        <span className='primary-text card-inner-text'>Mass: </span>
                        {
                            isUserTotalMassReceivedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={userTotalReceivedMassTokenAmount}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                        <br />
                        <span className='primary-text card-inner-text'>% of Total: </span> <span className='card-inner-text'>
                            {
                                isUserTotalMassReceivedLoading || isMasExistingLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userMassPercentage ? userMassPercentage : 0}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        suffix=" %"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Mass Reflections</span>}>
                        <span className='primary-text card-inner-text'>Mass: </span>
                        <span className='card-inner-text'>
                            {
                                isUserStakedTokenAmountLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userMassReflectionValue}
                                        thousandSeparator={true}
                                        decimalScale={6}
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                        <br />
                        <span className='primary-text card-inner-text'>Percentage: </span>
                        {
                            isMassReflectionLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={massReflectionAmount}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            )
                        }
                        {/* <br />
                        <span className='primary-text card-inner-text'>Value: </span> <span className='card-inner-text'>
                            {
                                isUserTotalMassReceivedLoading || isFathomTokenPriceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userMassReflectionAmountInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span> */}
                    </Card>
                </Col>
            </Row>

            <Row className='mb-5'>
                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Treasure</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        {
                            isUserClaimableAmountLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={userClaimableTokenAmount}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span> <span className='card-inner-text'>
                            {
                                isUserClaimableAmountLoading || isFathomTokenPriceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={userClaimableTokenAmountInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Next Wave</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        {
                            isNextWaveFathomTokenAmountLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={numbersOfFathomTokenForNextWave}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                        <br />
                        <span className='primary-text card-inner-text'>Value: </span> <span className='card-inner-text'>
                            {
                                isNextWaveFathomTokenAmountLoading || isFathomTokenPriceLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={numbersOfFathomTokenForNextWaveInUSD}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        prefix="$"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Current Daily ROI</span>}>
                        <span className='primary-text card-inner-text'>$Fathom: </span>
                        <span className='card-inner-text'>
                            {
                                isLastWaveStakingOceanLoading || isFathomTokenPriceLoading || isMasExistingLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={dailyRoi ? dailyRoi : 0}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        suffix=" %"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>

                <Col lg="3" md="6" sm="12" className='mt-3'>
                    <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>12 Month APY</span>}>
                        <span className='primary-text card-inner-text'>APY: </span>
                        <span className='card-inner-text'>
                            {
                                isLastWaveStakingOceanLoading || isFathomTokenPriceLoading || isMasExistingLoading ? (
                                    <div className='d-flex justify-content-center'>
                                        <Spin />
                                    </div>
                                ) : (
                                    <NumberFormat
                                        style={{ fontSize: '18px' }}
                                        value={twelveMonthApy}
                                        thousandSeparator={true}
                                        decimalScale={3}
                                        suffix=" %"
                                        displayType={'text'}
                                    />
                                )
                            }
                        </span>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default MyStats