import { Steps } from "antd";
import React, { useState, useEffect } from "react";
import PrepareStep from './PrepareStep'
import ApproveStep from './ApproveStep'
import MultisendStep from './MultisendStep'
import './Airdrop.css'
import { useSelector } from 'react-redux'

function Airdrop() {

    const { stepNumber } = useSelector((state) => state.homeSteps)

    const [current, setCurrent] = useState(0);
    const steps = [
        {
          title: 'Prepare',
          content: <PrepareStep />,
        },
        {
          title: 'Approve',
          content: <ApproveStep />,
        },
        {
          title: 'Multisend',
          content: <MultisendStep />,
        },
      ];

      const { Step } = Steps;

      useEffect(() => {
        if (stepNumber) {
          setCurrent(stepNumber);
        }
      }, [stepNumber])

    return (
        <div className='mt-5 mb-5'>
            <div className=' col-lg-8 mx-auto mt-3'>
                <Steps current={current} responsive={false}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}
                         />
                    ))}
                </Steps>
            </div>
            <div className="steps-content">{steps[current].content}</div>
    </div>
    )
}

export default Airdrop