import { Card, Button, Input, notification, Tooltip, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, CheckCircle } from 'react-feather'
import { CopyOutlined } from '@ant-design/icons';
import fflogo from '../../../images/fflogo.png'
import { getEllipsisTxt } from '../../../Helpers/Formatters';
import { getUserReferralRegisterStatus, registerAsReferralUser } from '../../../Blockchain/services/shilling.service';
import { useAccount, useSigner } from 'wagmi';

function Referral() {
    const [reflinkCopy, setReflinkCopy] = useState(false)
    const [referralCode, setReferralCode] = useState('')
    const [isValidReferralUser, setIsValidReferralUser] = useState(false)
    const [isUserReferralStatusLoading, setIsUserReferralStatusLoading] = useState(false)
    const [isReferralRegistrationLoading, setIsReferralRegistrationLoading] = useState(false)

    const { address: account } = useAccount()
    const { data: signer } = useSigner();

    const handleReferralLink = async () => {
        try {
            setIsReferralRegistrationLoading(true)
            if (!account) {
                notification['error']({
                    message: 'Authentication Error',
                    description:
                        'Please connect your wallet to proceed',
                });
                setIsReferralRegistrationLoading(false)
                return
            }

            const result = await registerAsReferralUser(signer)
            console.log("REGISTER REFERRAL SUCCESS RESULT ", result)
            setIsReferralRegistrationLoading(false)
            fetchUserReferralStatus()
            notification['success']({
                message: 'Success',
                description:
                    'You have been registered as a referral',
            })
        } catch (error) {
            notification['error']({
                message: 'Transaction Execution Failed',
                description: error,
            })
            setIsReferralRegistrationLoading(false)
            console.log("ERROR while register user as valid referral ", error)
        }
    }

    const handleReflinkCopy = () => {
        navigator.clipboard.writeText(referralCode)
        setReflinkCopy(true)
    }

    const fetchUserReferralStatus = async () => {
        try {
            setIsUserReferralStatusLoading(true)
            const referralStatus = await getUserReferralRegisterStatus(account)
            setIsUserReferralStatusLoading(false)
            setIsValidReferralUser(referralStatus)
            // setIsValidReferralUser(true)
        } catch (error) {
            setIsUserReferralStatusLoading(false)
            setIsValidReferralUser(false)
            console.log("ERROR while fetching user referral status ", error)
        }
    }

    useEffect(() => {
        if (account) {
            fetchUserReferralStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])


    useEffect(() => {
        if (account) {
            if (typeof window !== 'undefined') {
                const path = `${window.location.protocol}//${window.location.host}/trade?ref=${account}`
                setReferralCode(path)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    return (
        <div>
            <Card className='fathom-finance-card mx-auto mt-3'>
                <h5 className='text-center primary-text'>Shill School ID</h5>
                <hr />

                <div className='text-center mt-4'>
                    <img src={fflogo} alt="fflogo" style={{ width: '120px', borderRadius: '50%' }} />
                </div>

                <p className='mt-4 text-muted'>Fathom Finance has built a revolutionary referral system where you are rewarded for your work.
                    Share your unique referral link with your friends on socials: Telegram, Discord, Twitter, YouTube, Facebook,
                    WhatsApp, Instagram, etc. When your ID is used to purchase $Fathom, you receive a dividend token called Work.
                    You will receive rewards every 8 hours in Shifts from the Shilling Sea, which is a contract that stakes 1% of all Fathom purchases. Work operates like Mass but is burned 1 week after minting.</p>

                {
                    !account ? (
                        <Button className='nutgain-primary-button mt-4 col-12' type='primary'>Connect your wallet to get REFERRAL link</Button>
                    )
                        :
                        (
                            isUserReferralStatusLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>) : (
                                <>
                                    {
                                        !isValidReferralUser ?
                                            <Button
                                                className='nutgain-primary-button mt-4 col-12'
                                                loading={isReferralRegistrationLoading}
                                                type='primary'
                                                onClick={() => handleReferralLink()}>
                                                Generate Shill School ID Unique Code
                                            </Button>
                                            :
                                            <div className='text-center'>
                                                <Input.Group compact>

                                                    <Input
                                                        size="large"
                                                        style={{ width: 'calc(100% - 15%)', backgroundColor: 'rgba(100, 101, 118, .2)', color: 'white' }}
                                                        value={referralCode}
                                                        disabled
                                                    />

                                                    {reflinkCopy ?

                                                        <Tooltip title="copied">
                                                            <Button icon={<CheckCircle size={18} />} size="large" style={{ backgroundColor: 'rgba(100, 101, 118, .2)', color: 'white' }} />
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title="copy">
                                                            <Button icon={<CopyOutlined onClick={handleReflinkCopy} />} size="large" style={{ backgroundColor: 'rgba(100, 101, 118, .2)', color: 'white' }} />
                                                        </Tooltip>}

                                                </Input.Group>

                                                <p className="text-muted text-center mt-2">For wallet {getEllipsisTxt(account, 7)}</p>
                                            </div>
                                    }
                                </>
                            )

                        )


                }

                <div className='card-icon'>
                    <Link size="170" color='rgba(67, 67, 67, 0.5)' />
                </div>
            </Card>
        </div>
    )
}

export default Referral