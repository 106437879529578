import { Card, Spin, Select, Modal, Button, Upload, message, notification, Alert } from "antd";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { setContractList, setTotalTokens, setSelectedToken, setSelectedTokenBalance, setSelectedTokenSymbol, setCsvCodeValue, setSelectedTokenDecimals } from '../../Redux/CsvDataList'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { CSVLink } from "react-csv"
import TextAreaWithLineNumber from "text-area-with-line-number";
import WAValidator from 'wallet-address-validator'
import { setStepNumber } from '../../Redux/HomeSteps'
import Papa from 'papaparse';
import { useAccount } from 'wagmi';

function PrepareStep() {

    const { address: account } = useAccount()
    const dispatch = useDispatch()

    const { selectedTokenBalance, totalTokens, selectedToken, selectedTokenSymbol } = useSelector((state) => state.csvDataList)

    const [tokenList, setTokenList] = useState([])
    const [currentNetworkID, setCurrentNetworkID] = useState(0)
    const [isTokenListLoading, setIsTokenListLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [codeValue, setCodeValue] = useState('')
    const [fileList, setFileList] = useState(null)
    const [isContinue, setIsContinue] = useState(false)
    const [duplicatesList, setDuplicatesList] = useState([])
    const [notificationShown, setNotificationShown] = useState(false);
    const [enterDataErrorList, setEnterDataErrorList] = useState('');

    const formattedTokenBalance = (balance, decimal) => {
        let formattedValue = balance / 10 ** decimal
        let formattedFixedValue = formattedValue.toFixed(4)

        return formattedFixedValue
    }

    const formatTokenBalance = (balance) => {
        let formattedFixedValue = balance.toFixed(4)

        return formattedFixedValue
    }

    const handleChange = (value) => {
        const splitValue = value.split(",")
        console.log(splitValue);
        let formattedValue = splitValue[1] / 10 ** splitValue[2]
        dispatch(setSelectedTokenBalance(formattedValue))
        dispatch(setSelectedTokenSymbol(splitValue[0]))
        dispatch(setSelectedToken(splitValue[3]))
        dispatch(setSelectedTokenDecimals(splitValue[2]))
    };

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', event => {
                const chainID = parseInt(event.toString(), 16)
                setCurrentNetworkID(chainID)
            });

            window.ethereum.request({ method: "eth_chainId" })
                .then(chainId => {
                    setCurrentNetworkID(parseInt(chainId, 16))
                })

        }

    }, [])

    useEffect(() => {
        const fetchTokenList = async () => {
            setIsTokenListLoading(true)
            try {
                if (account && currentNetworkID) {

                    let networkIdForApi = ''
                    if (currentNetworkID === 97) {
                        networkIdForApi = '0x61'
                    } else if (currentNetworkID === 56) {
                        networkIdForApi = '0x38'
                    }

                    let config = {
                        method: 'get',
                        url: `${process.env.REACT_APP_API_BASE_URL}/${account}/erc20?chain=${networkIdForApi}`,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': `${process.env.REACT_APP_API_KEY}`
                        }
                    };

                    const response = await axios(config)

                    // const endpoint = `${process.env.REACT_APP_API_BASE_URL}/v1/${currentNetworkID}/address/${account}/balances_v2/?key=${process.env.REACT_APP_API_KEY}`
                    // const response = await axios.get(endpoint)
                    if (response.status === 200) {
                        const payload = response.data
                        if (payload) {
                            setTokenList(payload)
                        } else {
                            setTokenList([])
                        }
                    }
                }

            } catch (error) {
                console.log("ERROR while fetching active pools from API ", error)
            }
            setIsTokenListLoading(false)
        }

        fetchTokenList()
    }, [currentNetworkID, account])

    useEffect(() => {
        let textAreaValues = []
        let firstColumnArray = []
        const addressAndValues = []
        let totalTokens = 0
        let errorList = []
        let duplicates = []
        let frequencyMap = {}

        if (codeValue) {
            setNotificationShown(false)
            textAreaValues = codeValue.split('\n');

            textAreaValues.forEach((item, index) => {

                let separatedValues = item.split(',')

                let valid = false
                
                if (separatedValues[0] !== '') {
                    valid = WAValidator.validate(separatedValues[0], 'ETH')
                    if (valid) {
                        firstColumnArray.push(separatedValues[0])
                        // setTextColor('#dcdcde')
                    } else {
                        const message = `Line ${index + 1}: Given address ${separatedValues[0]} is not a valid Ethereum address.`
                        // notification['error']({
                        //     message: message
                        //   })
                        // setTextColor('#dc3545')
                        errorList.push(message)
                    }
                }
                
                if (separatedValues[1] !== '') {
                    if (isNaN(separatedValues[1])) {
                        const message = `Line ${index + 1}: Given token value ${separatedValues[1]} is not a valid amount.`
                        errorList.push(message)
                    } else {
                        const tokenAmount = parseFloat(separatedValues[1])
                        totalTokens = totalTokens + tokenAmount
                    }
                }

                addressAndValues.push({
                    contractAddress: separatedValues[0],
                    value: separatedValues[1]
                })
            })

            setEnterDataErrorList(errorList)

            for (let i = 0; i < firstColumnArray.length; i++) {
                if (frequencyMap[firstColumnArray[i]] === undefined) { 
                    frequencyMap[firstColumnArray[i]] = 1;
                  } else {
                    if (duplicates.indexOf(firstColumnArray[i]) === -1) {
                      duplicates.push(firstColumnArray[i]);
                    }
                    frequencyMap[firstColumnArray[i]] += 1;
                  }
            }
            setDuplicatesList(duplicates)
            dispatch(setContractList(addressAndValues))
            dispatch(setTotalTokens(totalTokens))
        } else {
            setEnterDataErrorList([])
            setDuplicatesList([])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeValue])

    useEffect(() => {
        if (selectedTokenBalance < totalTokens && !notificationShown) {
            notification['error']({
                message: 'airDrop token balance SUM is greater than your token balance',
            })
            setNotificationShown(true)
        }
        if (codeValue && enterDataErrorList.length === 0 && selectedTokenBalance > totalTokens && duplicatesList.length === 0) {
            setIsContinue(true)
        } else {
            setIsContinue(false)
        }
        // eslint-disable-next-line
    }, [codeValue, enterDataErrorList, selectedTokenBalance, totalTokens, duplicatesList])

    const handleContinue = () => {
        dispatch(setStepNumber(1))
        dispatch(setCsvCodeValue(codeValue))
    }


    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const upload_props = {
        beforeUpload: (file) => {
            const isCSV = file.type === 'text/csv'

            if (!isCSV) {
                message.error(`${file.name} is not a CSV file`)
            }
            setFileList(file)
            return false
        },
        onRemove: () => {
            setFileList(null)
        },
        onChange: (info) => {
            console.log(info.fileList)
        },
    }

    const decodeCSVFileContent = async () => {
        try {
            if (fileList) {
                Papa.parse(fileList, {
                    header: true,
                    skipEmptyLines: true,
                    complete: async (results) => {

                        const addressAndValues = []
                        const secondColumnArray = []
                        let totalTokens = 0
                        const errorList = []
                        if (results && results.data && results.data.length > 0) {
                            let codeEditorAddress = ''
                            results.data.forEach((item, index) => {
                                if (!JSON.stringify(item).includes('user_address')) {
                                    const message = `user_address not found in CSV idex ${index} file`
                                    errorList.push(message)
                                } else {
                                    if (!JSON.stringify(item).includes('token_amount')) {
                                        const message = `token_amount not found in CSV idex ${index} file`
                                        errorList.push(message)
                                    } else {
                                        const tokenAmount = parseFloat(item.token_amount)
                                        totalTokens = totalTokens + tokenAmount
                                        secondColumnArray.push(tokenAmount)
                                        const oneLine = `${item.user_address},${tokenAmount}\n`
                                        codeEditorAddress = codeEditorAddress + oneLine

                                        addressAndValues.push({
                                            contractAddress: item.user_address,
                                            value: tokenAmount
                                        })
                                    }
                                }
                            })
                            dispatch(setContractList(addressAndValues))
                            dispatch(setTotalTokens(totalTokens))
                            setCodeValue(codeEditorAddress.trim())

                            if ((results?.errors && results?.errors.length > 0) || errorList.length > 0) {
                                notification['error']({
                                    message: 'Error while processing CSV file'
                                })
                            } else {
                                notification['success']({
                                    message: 'CSV file uploaded successfully'
                                })
                            }
                        }
                    },
                })
            }
        } catch (error) {
            console.log("ERROR while decoding CSV File : ", error)
        }
    }

    useEffect(() => {
        if (fileList) {
            decodeCSVFileContent()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList])

    const csvData = [
        ["user_address", "token_amount"],
        ["0x457D0aB396f5431dcCa415A52147bDe5EeBBf33B", "1"],
        ["0x9f9a0A7D314EAdd077f2ec5Ab56B4F3a4E3d2191", "2"],
        ["0x742bb929773647d95592B27bfAE67630B45b4d8b", "3"],
        ["0x2Cd94F2127B5b1787DfF561d53f35145dB871B40", "1"],
        ["0x68cF740546Baec262518D3AD57F5871e927de389", "1"]
    ]

    const sampleCode = `0x87145C24F1DB2e9B29FA26eBC4c1545f0E2846AB,0.000056
0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF,13.45
0x7D52422D3A5fE9bC92D3aE8167097eE09F1b347d,2.5
0x64c9525A3c3a65Ea88b06f184F074C2499578A7E,1`;

    return (
        <div className='mt-4 mb-5'>
            <Card className='fathom-finance-card col-lg-8 mx-auto mt-3'>
                <Row>
                    <Col lg="12" md="12" sm="2" className='mt-1'>
                        <div className="d-flex justify-content-between">
                            <span>Token Address</span>
                            <span>
                                {
                                    selectedTokenBalance ? (
                                        `Balance ${formatTokenBalance(selectedTokenBalance)}`
                                    ) : (<></>)
                                }
                            </span>
                        </div>
                        
                        <Select
                            style={{
                                width: '100%',
                            }}
                            onChange={handleChange}
                            placeholder={account ? 'Select your Token' : 'Connect your wallet'}
                            notFoundContent={isTokenListLoading ? <Spin size="small" /> : null}
                            loading={isTokenListLoading}
                            defaultValue={selectedToken ? selectedTokenSymbol + ' - ' + selectedToken : ''}
                            // className='hide-on-mobile'
                            size="large"
                            optionLabelProp="label"
                        >
                            {tokenList.map((item) => (
                                <Select.Option
                                    key={item?.token_address}
                                    label={item?.symbol + ' - ' + item?.token_address} 
                                    value={item?.balance +
                                        ',' + item?.balance +
                                        ',' + item?.decimals +
                                        ',' + item?.token_address}
                                >
                                    {item?.symbol} - {formattedTokenBalance(item?.balance, item?.decimals)} - {item?.token_address}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    {/* <Col lg="2" md="2" sm="2" className="mobile-top-margin">
                        <div>
                            <span>Deflationary</span>
                        </div>
                        <div>
                            <Switch defaultChecked onChange={onChange}  />
                        </div>
                    </Col> */}
                </Row>
                <div className='d-flex justify-content-between align-items-start mt-5 mb-1'>
                    <span className='address-list-text'>List of Addresses</span>
                    <span className='span-button' onClick={showModal}>Show sample CSV</span>
                </div>
                <div>
                    <TextAreaWithLineNumber
                        lineNumberBackground={"#283047"}
                        textAreaBackgroundColor={"#101420"}
                        lineNumberTextColor={"#ffffff"}
                        textAreaTextColor={"#ffffff"}
                        border={"1px solid #EDEDED"}
                        placeholder={"0x5f4C125A2dbC35DcdabF703E4c71443D19d6Bfb9,0.1"}
                        lineNumberFontSize={"12px"}
                        textAreaFontSize={"15px"}
                        onChange={e => setCodeValue(e.target.value)}
                        value={codeValue}
                    />
                </div>
                <div className='d-flex justify-content-end align-items-end'>
                    <Upload {...upload_props} maxCount={1} showUploadList={false}>
                        <Button className='csv-upload-button'>Upload CSV</Button>
                    </Upload>
                </div>
                {
                    enterDataErrorList.length > 0 ? (
                        enterDataErrorList.map((data) => (
                            <div className='mt-1'>
                                <Alert message={data} type="error" />
                            </div>
                        ))
                    ) : (
                        <></>
                    )
                }

                {
                    duplicatesList.length > 0 ? (
                        <div className='mt-3'>
                            <Alert message={`Found ${duplicatesList.length} duplicate addresses`} type="warning" showIcon />
                            {duplicatesList.map((data) => (
                                <div className='mt-1'>
                                        <Alert message={data} type="warning" />
                                </div>
                            ))}
                        </div>
                        
                    ) : (
                        <></>
                    )
                }
                <div className='d-flex justify-content-center align-items-center mt-5'>
                    <Button 
                        block 
                        onClick={handleContinue}
                        className="nutgain-primary-button"
                        disabled={!isContinue}>Continue</Button>
                </div>
            </Card>
            <Modal
                visible={open}
                title="Example CSV"
                onCancel={handleCancel}
                footer={[
                    <CSVLink className='span-button' data={csvData}>Download sample CSV file</CSVLink>,
                ]}
                width={700}
            >
                <TextAreaWithLineNumber
                    lineNumberBackground={"#283047"}
                    textAreaBackgroundColor={"#101420"}
                    lineNumberTextColor={"#ffffff"}
                    textAreaTextColor={"#ffffff"}
                    placeholder={"0x5b9141b2258A45B133b61D32112fC9369D95Af24,0.1"}
                    lineNumberFontSize={"12px"}
                    textAreaFontSize={"15px"}
                    value={sampleCode}
                />
            </Modal>
            
        </div>
    )
}

export default PrepareStep