import React, { useState, useEffect } from 'react'
import { Briefcase, PieChart, ShoppingBag } from 'react-feather'
import { RocketFilled } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons'
import { close_menu } from '../../Redux/MobileMenuSlice';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function MenuItems() {

    const navigate = useNavigate()
    const location = useLocation()
    const [openKeys, setOpenKeys] = useState([location.pathname]);
    const dispatch = useDispatch()
    const { margin } = useSelector((state) => state.sideMenu);
    const [hideChildren, setHideChildren] = useState(false)

    useEffect(() => {
        if (!margin){
          setHideChildren(true)
    
          setTimeout(function() {
            setHideChildren(false)
          }, 500)
        } else {
          setHideChildren(false)
        }
      }, [margin])

    // menu item structure
    const getItem = (label, key, icon, children, theme) => {
        return {
            key,
            icon,
            children,
            label,
            theme,
        };
    }

    // menu items
    const items = [
        getItem('Dashboard', '/', <HomeOutlined style={{fontSize: '18px'}} className="mobile-nav-icon" />, [
            getItem('My Stats', '/#mystats'),
            getItem('My History', '/#myhistory'),
            getItem('Metrics', '/#metrics'),
            getItem('Taxes', '/#taxes'),
            getItem('Weather', '/#weather'),
            getItem('Stable Staking', '/#solidstaking'),
            getItem('Ocean', '/#ocean'),
            // getItem('Whirlpool', '/#whirlpool'),
            // getItem('Shilling Sea', '/#shillingsea'),
            getItem('Mass', '/#mass'),
        ]),
        getItem('Stable Stake', '/stakingV2', <PieChart size={18} className="mobile-nav-icon" />, [
            getItem('Chest', '/stakingV2#chest'),
            getItem('Stakenomics', '/stakingV2#stakenomics'),
            getItem('Stake', '/stakingV2#stake'),
            getItem('Unstake', '/stakingV2#unstake')
        ]),
        getItem('Buy $Fathom', '/trade', <Briefcase size={18} className="mobile-nav-icon" />),
        getItem('Token Multi-send', '/airdrop', <RocketFilled size={18} className="mobile-nav-icon" />),
        getItem('Shill School', '/shillingV2', <ShoppingBag size={18} className="mobile-nav-icon" />, [
            getItem('School ID', '/shillingV2#schoolId'),
            getItem('Commission', '/shillingV2#commission'),
            getItem('Work', '/shillingV2#work'),
            getItem('Report Card', '/shillingV2#reportCard'),
        ])
    ];

    const rootSubmenuKeys = ['/', '/stakingV2', '/shillingV2'];
    
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    // onclick event on a menu item, navigates to relevant route
    const onClick = (e) => {
        dispatch(close_menu())
        navigate(e.key);
    };

    return (
        <Menu
            mode="inline"
            onOpenChange={onOpenChange}
            openKeys={ hideChildren ? null : openKeys }
            selectedKeys={`${location.pathname}${location.hash}`} // relevant navlink will be activated accrding to your route
            theme="dark"
            onClick={onClick}
            style={{ background: '#141a34'}}
            items={items}
        />
    )
}

export default MenuItems