import React from 'react'
import { Spin } from 'antd';
import NumberFormat from 'react-number-format'

function ApproveDetailCard(props) {

    const { 
        topic, 
        value,
        loading 
    } = props

    return (
        <>
            <div className='mx-2'>
                <div className='home-inner-card'>
                    {
                        loading ? 
                            <Spin size="small" /> 
                                : 
                            <>
                                
                                <div className='inner-card-value'>
                                    <NumberFormat
                                        value={value}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={5}
                                    />
                                </div>
                                <div className='inner-card-topic'>
                                    {topic}
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default ApproveDetailCard;