import React, { useEffect, useState } from 'react'
import { Card, Slider, Input, Button, Form, notification, Modal } from 'antd'
import { Row, Col } from 'reactstrap'
import { getBEP20TokenBalanceByWalletAddress, tokenApprovalBigNumber } from '../../../Blockchain/services/util.service'
import { stakingConfigs } from '../../../Blockchain/configs/staking.configs'
import { BigNumber, utils } from 'ethers'
import {
    getMassBalanceByWalletAddressInStaking,
    getStakingDetailsByWalletAddress,
    stakeFathomTokenBigNumber
} from '../../../Blockchain/services/staking.service'
import Buy from '../../Trade/Buy'
import { useAccount, useSigner } from 'wagmi';

function Stake() {

    const { address: account } = useAccount()
    const { data: signer } = useSigner();


    const [userFathomTokenBalance, setUserFathomTokenBalance] = useState(0.0)
    const [userTokenBalanceValidationStatus, setUserTokenBalanceValidationStatus] = useState('')
    const [userTokenBalanceHelper, setUserTokenBalanceHelper] = useState('')

    const [userSelectedStakingTokenAmount, setUserSelectedStakingTokenAmount] = useState(0.0)
    const [selectedStakedTokenAmountForDisplay, setSelectedStakedTokenAmountForDisplay] = useState('')

    const [stakeTokenAmountValidationHelper, setStakeTokenAmountValidationHelper] = useState('')
    const [stakeTokenAmountValidationStatus, setStakeTokenAmountValidationStatus] = useState('')

    const [isFathomTokenStakingLoading, serIsFathomTokenStakingLoading] = useState(false)
    const [sliderValue, setSliderValue] = useState(0.0)
    // eslint-disable-next-line
    const [userStakingDetails, setUserStakingDetails] = useState(null)
    const [userMassTokenBalance, setUserMassTokenBalance] = useState(0.0)
    const [isUserStakedFathomTokenLoading, setIsUserStakedFathomTokenLoading] = useState(false)

    const [isFathomTokenApprovalLoading, setIsFathomTokenApprovalLoading] = useState(false)
    const [isStakingButtonDisabled, setIsStakingButtonDisabled] = useState(true)

    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchUserFathomTokenBalance = async () => {
        setUserTokenBalanceValidationStatus('validating')
        if (account) {
            setUserTokenBalanceHelper('')
            const fathomTokenAddress = stakingConfigs.fathomTokenAddress
            const tokenBalance = await getBEP20TokenBalanceByWalletAddress(fathomTokenAddress, account)
            setUserFathomTokenBalance(tokenBalance)
            setUserTokenBalanceValidationStatus('')
        } else {
            setUserTokenBalanceHelper('Please connect the your wallet')
            setUserTokenBalanceValidationStatus('')
            setUserFathomTokenBalance(0)
        }
    }

    const handleStakingTokenPercentage = (percentage) => {
        const userFathomTokenAmountBN = utils.parseEther(userFathomTokenBalance.toString())
        switch (percentage) {
            case 25:
                const output25 = userFathomTokenAmountBN
                    .mul(BigNumber.from("25"))
                    .div(BigNumber.from("100"))
                setUserSelectedStakingTokenAmount(output25)
                setSelectedStakedTokenAmountForDisplay(utils.formatEther(output25.toString()))
                break;
            case 50:
                const output50 = userFathomTokenAmountBN
                    .mul(BigNumber.from("50"))
                    .div(BigNumber.from("100"))
                setUserSelectedStakingTokenAmount(output50)
                setSelectedStakedTokenAmountForDisplay(utils.formatEther(output50.toString()))
                break;
            case 75:
                const output75 = userFathomTokenAmountBN
                    .mul(BigNumber.from("75"))
                    .div(BigNumber.from("100"))
                setUserSelectedStakingTokenAmount(output75)
                setSelectedStakedTokenAmountForDisplay(utils.formatEther(output75.toString()))
                break;
            case 100:
                const output100 = userFathomTokenAmountBN
                    .mul(BigNumber.from("100"))
                    .div(BigNumber.from("100"))
                setUserSelectedStakingTokenAmount(output100)
                setSelectedStakedTokenAmountForDisplay(utils.formatEther(output100.toString()))
                break;
            default:
                setSelectedStakedTokenAmountForDisplay(utils.formatEther(userFathomTokenAmountBN.toString()))
                setUserSelectedStakingTokenAmount(userFathomTokenAmountBN)
        }
    }

    const handleStakingInputChanges = (e) => {
        const inputValue = e.target.value
        if (inputValue) {
            if (parseFloat(inputValue) > parseFloat(userFathomTokenBalance)) {
                setSelectedStakedTokenAmountForDisplay(userFathomTokenBalance)
                const userFathomTokenAmountBN = utils.parseEther(userFathomTokenBalance)
                setUserSelectedStakingTokenAmount(userFathomTokenAmountBN)
                setStakeTokenAmountValidationStatus('error')
                setStakeTokenAmountValidationHelper('Staking amount can not exceeds your fathom token balance')
            } else {
                setSelectedStakedTokenAmountForDisplay(inputValue)
                const userFathomTokenAmountBN = utils.parseEther(inputValue.toString())
                setUserSelectedStakingTokenAmount(userFathomTokenAmountBN)
                setStakeTokenAmountValidationStatus('success')
                setStakeTokenAmountValidationHelper('')
            }
        } else {
            setSelectedStakedTokenAmountForDisplay('')
            setUserSelectedStakingTokenAmount(BigNumber.from("0"))
        }
        setIsStakingButtonDisabled(true)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const handleSliderChange = (value) => {
        if (value) {
            setSliderValue(value)
            const valueBN = BigNumber.from(value.toString())
            const userFathomTokenAmountBN = utils.parseEther(userFathomTokenBalance.toString())
            const response = userFathomTokenAmountBN.mul(valueBN).div(BigNumber.from("100"))
            setUserSelectedStakingTokenAmount(response)
            setSelectedStakedTokenAmountForDisplay(utils.formatEther(response.toString()))
        } else {
            setSliderValue(0)
            setSelectedStakedTokenAmountForDisplay('')
            setUserSelectedStakingTokenAmount(BigNumber.from("0"))
        }

    }

    const handleStake = async () => {
        serIsFathomTokenStakingLoading(true)
        try {
            if (!account) {
                notification['error']({
                    message: 'Authentication Error',
                    description:
                        'Please connect your wallet to proceed',
                })
                serIsFathomTokenStakingLoading(false)
                return
            }
            if (!(parseFloat(selectedStakedTokenAmountForDisplay) > 0)) {
                notification['info']({
                    message: 'Input Validation Error',
                    description:
                        'Staking amount must be greater than zero',
                })
                serIsFathomTokenStakingLoading(false)
                return
            }
            const result = await stakeFathomTokenBigNumber(userSelectedStakingTokenAmount, signer)
            console.log("STAKING SUCCESS ", result)
            serIsFathomTokenStakingLoading(false)
            notification['success']({
                message: 'Staking Successes',
                description: 'Fathom token has been staked'
            })
        } catch (error) {
            console.log("ERROR while trying to stake fathom tokens ", error)
            notification['error']({
                message: 'Transaction Execute Error',
                description: error
            })
            serIsFathomTokenStakingLoading(false)
        }
    }

    const handleApprove = async () => {
        setIsFathomTokenApprovalLoading(true)
        try {
            if (!account) {
                notification['error']({
                    message: 'Authentication Error',
                    description:
                        'Please connect your wallet to proceed',
                })
                setIsFathomTokenApprovalLoading(false)
                return
            }
            if (!(parseFloat(selectedStakedTokenAmountForDisplay) > 0)) {
                notification['info']({
                    message: 'Input Validation Error',
                    description:
                        'Staking amount must be greater than zero',
                })
                serIsFathomTokenStakingLoading(false)
                return
            }
            const spenderAddress = stakingConfigs.stakingContractAddress
            const fathomTokenAddress = stakingConfigs.fathomTokenAddress

            const result = await tokenApprovalBigNumber(spenderAddress, fathomTokenAddress, userSelectedStakingTokenAmount, signer)
            console.log("APPROVAL SUCCESS ", result)
            setIsFathomTokenApprovalLoading(false)
            setIsStakingButtonDisabled(false)
            notification['success']({
                message: 'Staking Approval Successes',
                description: 'Fathom token has been approved for staking'
            })
        } catch (error) {
            console.log("ERROR while trying to approve fathom token for staking ", error)
            notification['error']({
                message: 'Transaction Execute Error',
                description: error
            })
            setIsFathomTokenApprovalLoading(false)
            setIsStakingButtonDisabled(true)
        }
    }

    const fetchUserStakedTokens = async () => {
        if (account) {
            setIsUserStakedFathomTokenLoading(true)
            const response = await getStakingDetailsByWalletAddress(account)
            const userMassTokenAmount = await getMassBalanceByWalletAddressInStaking(account)
            setUserMassTokenBalance(userMassTokenAmount)
            setUserStakingDetails(response)
            setIsUserStakedFathomTokenLoading(false)
        } else {
            setUserStakingDetails(null)
            setUserMassTokenBalance(0.0)
            setIsUserStakedFathomTokenLoading(false)
        }
    }

    useEffect(() => {
        fetchUserFathomTokenBalance()
        fetchUserStakedTokens()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        const value = (parseFloat(selectedStakedTokenAmountForDisplay) / parseFloat(userFathomTokenBalance)) * 100
        setSliderValue(value)
    }, [selectedStakedTokenAmountForDisplay, userFathomTokenBalance])



    return (
        <Card className='fathom-finance-card'>
            <h5 className='primary-text text-center'>Stake</h5>
            <hr />

            <Row className='col-lg-6 mx-auto'>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3" className='mt-2'>
                    Available:
                </Col>

                <Col xxl="9" xl="9" lg="9" md="9" sm="9" xs="9">
                    <Form.Item
                        hasFeedback
                        validateStatus={userTokenBalanceValidationStatus}
                        help={userTokenBalanceHelper}
                    >
                        <Input
                            className='stake-fathom-input'
                            size="large"
                            value={userFathomTokenBalance}
                            type="number"
                            readOnly={true} />
                    </Form.Item>
                </Col>

            </Row>

            <Row className='mt-4'>
                <Col>
                    <Slider
                        onChange={handleSliderChange}
                        value={sliderValue} />
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(25) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >25%</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(50) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >50%</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(75) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >75%</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <Button
                        onClick={() => { handleStakingTokenPercentage(100) }}
                        size="large"
                        className='col-12 nutgain-primary-button'
                    >MAX</Button>
                </Col>
            </Row>



            <Row className='mt-5'>
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='mx-auto mt-3'>
                    <h6 className='text-center'>Stake:</h6>
                    <Form.Item
                        hasFeedback
                        validateStatus={stakeTokenAmountValidationStatus}
                        help={stakeTokenAmountValidationHelper}
                    >
                        <Input
                            name="stakingAmount"
                            placeholder='Enter token amount to stake'
                            onChange={handleStakingInputChanges}
                            value={selectedStakedTokenAmountForDisplay}
                            className='stake-fathom-input'
                            size="large"
                            type="number"
                            min={0} />
                    </Form.Item>
                </Col>

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='mx-auto mt-3'>
                    <h6 className='text-center'>Mass Minted</h6>
                    <Form.Item
                        hasFeedback
                        validateStatus={isUserStakedFathomTokenLoading ? 'validating' : ''}
                        help={account ? '' : 'Please connect your wallet'}
                    >
                        <Input
                            className='stake-fathom-input'
                            size="large"
                            value={userMassTokenBalance ? userMassTokenBalance : 0.0}
                            type="number"
                            readOnly={true} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className='mt-3'>
                    <Button
                        loading={isFathomTokenApprovalLoading}
                        onClick={handleApprove}
                        className='col-12 nutgain-primary-button'>Approve</Button>
                </Col>

                <Col xxl="3" xl="3" lg="3" md="3" sm="12" xs="12" className='mt-3'>
                    <Button
                        loading={isFathomTokenStakingLoading}
                        disabled={isStakingButtonDisabled}
                        onClick={handleStake}
                        className='col-12 nutgain-primary-button'>Stake</Button>
                </Col>

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='mt-3'>
                    <Button
                        onClick={showModal}
                        className='col-12 nutgain-primary-button'>Buy and Stake</Button>
                </Col>
            </Row>

            <Modal title="Buy Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000} footer={false}>
                <Buy />
            </Modal>
        </Card>
    )
}

export default Stake