import React, { useState, useEffect } from 'react'
import { Card, Spin } from 'antd'
import { Row, Col } from 'reactstrap'
import { getNumbersOfTotalStake, getTotalStakedFathom, getTotalStakeChargeCollected, getLastWave, getTotalMassExisting } from '../../Blockchain/services/staking.service'
import { getTokenPriceInUSDByTokenAddress } from '../../Blockchain/services/util.service'
import NumberFormat from 'react-number-format'
import { stakingConfigs } from '../../Blockchain/configs/staking.configs'

function SolidStaking() {

    const fathomTokenAddress = stakingConfigs.fathomTokenAddress

    const [numbersOfStakers, setNumbersOfStakers] = useState(0.0)
    const [isNumbersOfStakersLoading, setIsNumbersOfStakersLoading] = useState(false)

    const[isTotalStakedLoading, setIsTotalStakedLoading] = useState(false)
    const[totalStakedFathom, setTotalStakedFathom] = useState(0.0)

    const[isFathomTokenPriceLoading, setIsFathomTokenPriceLoading] = useState(false)
    const[fathomTokenPriceInUSD, setFathomTokenPriceInUSD] = useState(0)
    const[totalStakedFathomValueInUSD, setTotalStakedFathomValueInUSDValueInUSD] = useState(0)

    const[isTotalStakeChargeCollectedLoading, setIsTotalStakeChargeCollectedLoading] = useState(false)
    const[totalStakeChargeCollectedFathom, setTotalStakeChargeCollectedFathom] = useState(0.0)

    const [isLastWaveStakingOceanLoading, setIsLastWaveStakingOceanLoading] = useState(false)
    const [lastWaveStakingOcean, setLastWaveStakingOcean] = useState(0.0)

    const [dailyRoi, setDailyRoi] = useState(0)
    const [twelveMonthApy, setTwelveMonthApy] = useState(0)
    const [lastWaveValueInUSD, setLastWaveValueInUSD] = useState(0)
    const [totalMassExisting, setTotalMassExisting] = useState(0.0)
    const [isMasExistingLoading, setIsMasExistingLoading] = useState(false)

    const fetchLastWave = async () => {
        setIsLastWaveStakingOceanLoading(true)
        const lastWaveValue = await getLastWave()
        setLastWaveStakingOcean(lastWaveValue)
        setIsLastWaveStakingOceanLoading(false)
    }

    const fetchNumbersOfStakers = async () => {
        try {
            setIsNumbersOfStakersLoading(true)
            const response = await getNumbersOfTotalStake()
            setNumbersOfStakers(parseFloat(response) + 2500)
            setIsNumbersOfStakersLoading(false)
        } catch (error) {
            setNumbersOfStakers(0)
            console.log("ERROR while fetching numbers of stakers ", error)
            setIsNumbersOfStakersLoading(false)
        }
    }

    const fetchTotalStakedFathom = async () => {
        setIsTotalStakedLoading(true)
        const totalStakedFathom = await getTotalStakedFathom()
        setTotalStakedFathom(totalStakedFathom)
        setIsTotalStakedLoading(false)
    }

    const fetchFathomTokenPrice = async () => {
        setIsFathomTokenPriceLoading(true)
        const tokenPrice = await getTokenPriceInUSDByTokenAddress(fathomTokenAddress)
        setFathomTokenPriceInUSD(tokenPrice)
        setIsFathomTokenPriceLoading(false)
    }

    const fetchTotalStakeChargeCollected = async () => {
        setIsTotalStakeChargeCollectedLoading(true)
        const totalStakeChargeCollected = await getTotalStakeChargeCollected()
        setTotalStakeChargeCollectedFathom(totalStakeChargeCollected)
        setIsTotalStakeChargeCollectedLoading(false)
    }

    const fetchMassExisting = async () => {
        setIsMasExistingLoading(true)
        const response = await getTotalMassExisting()
        setTotalMassExisting(response)
        setIsMasExistingLoading(false)
    }

    useEffect(() => {
        fetchNumbersOfStakers()
        fetchTotalStakedFathom()
        fetchFathomTokenPrice()
        fetchTotalStakeChargeCollected()
        fetchLastWave()
        fetchMassExisting()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (fathomTokenPriceInUSD && parseFloat(totalStakedFathom) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(totalStakedFathom)
            setTotalStakedFathomValueInUSDValueInUSD(value)
        } else {
            setTotalStakedFathomValueInUSDValueInUSD(0)
        }

        if (fathomTokenPriceInUSD && parseFloat(lastWaveStakingOcean) > 0) {
            const value = parseFloat(fathomTokenPriceInUSD) * parseFloat(lastWaveStakingOcean)
            setLastWaveValueInUSD(value)
        } else {
            setLastWaveValueInUSD(0)
        }

    }, [fathomTokenPriceInUSD, totalStakedFathom, lastWaveStakingOcean])

    useEffect(() => {
        if (lastWaveValueInUSD && totalMassExisting) {
            const dailyRoiValue = (lastWaveValueInUSD * 96) / totalMassExisting
            setDailyRoi(dailyRoiValue * 100)
            setTwelveMonthApy(dailyRoiValue * 365 * 100)
        }
    }, [lastWaveValueInUSD, totalMassExisting])

  return (
    <div>
        <Card className='mt-4 transparent'>
            <h3 className='text-center text-uppercase'>Stable Staking</h3>
        </Card>

        <Card className='fathom-finance-card col-lg-9 col-md-12 mx-auto mb-3'>
            <Row>
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='text-center'>
                    <span className='card-title'>Current ROI: </span>
                    <span className='card-title'>
                        {
                            isLastWaveStakingOceanLoading || isFathomTokenPriceLoading || isMasExistingLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={dailyRoi}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    suffix=" %"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Col>

                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='text-center'>
                    <span className='card-title'>Annual ROI: </span>
                    <span className='card-title'>
                        {
                            isLastWaveStakingOceanLoading || isFathomTokenPriceLoading || isMasExistingLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={twelveMonthApy}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    suffix=" %"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Col>
            </Row>
        </Card>

        <Row className='mb-5'>
            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Number of Stakers</span>}>
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>
                        {
                            isNumbersOfStakersLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin size='small' />
                                </div>
                            ) : (
                                <NumberFormat
                                    value={numbersOfStakers ? numbersOfStakers : 0.0}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>$Fathom Staked</span>}>
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalStakedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalStakedFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>Value Staked</span>}>
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalStakedLoading || isFathomTokenPriceLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalStakedFathomValueInUSD}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    prefix="$"
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>

            <Col lg="3" md="6" sm="12" className='mt-3'>
                <Card className='fathom-finance-card text-center h-100' title={<span className='card-title'>$Fathoms from Ripples</span>}>
                    <span className='primary-text card-inner-text'>Total: </span> 
                    <span className='card-inner-text'>
                        {
                            isTotalStakeChargeCollectedLoading ? (
                                <div className='d-flex justify-content-center'>
                                    <Spin />
                                </div>
                            ) : (
                                <NumberFormat
                                    style={{ fontSize: '18px' }}
                                    value={totalStakeChargeCollectedFathom}
                                    thousandSeparator={true}
                                    decimalScale={3}
                                    displayType={'text'}
                                />
                            )
                        }
                    </span>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default SolidStaking