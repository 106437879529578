import React, { createContext, useState } from 'react'

// This handles all the global variables and states
export const GlobalState = createContext()

export const DataProvider = ({children}) =>{
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const state = {
        showMobileMenu: [showMobileMenu, setShowMobileMenu]
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}