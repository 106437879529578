import React, { useEffect, useState } from 'react'
import { Card, Button, Spin, notification } from 'antd'
import { Col, Row } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { DollarSign, Coffee } from 'react-feather'
import { claimRewards, getHoldersDetails } from '../../../Blockchain/services/shilling.service'
import { stakingConfigs } from '../../../Blockchain/configs/staking.configs'
import { getTokenPriceInUSDByTokenAddress } from '../../../Blockchain/services/util.service'
import { useAccount, useSigner } from 'wagmi';

function Commission() {

  const { address: account } = useAccount()
  const { data: signer } = useSigner();

  const [isShillingUserDetailsLoading, setIsShillingUserDetailsLoading] = useState(false)
  const [userShillingDetails, setUserShillingDetails] = useState(null)
  const [userUnpaidCommissionInUSD, setUserUnpaidCommissionInUSD] = useState(0.0)

  const [isClaimCommissionLoading, setIsClaimCommissionLoading] = useState(false)

  const getShillingDetails = async () => {

    setIsShillingUserDetailsLoading(true)
    if (account) {
      const userDetails = await getHoldersDetails(account)
      const unpaidFathomTokens = parseFloat(userDetails?.unpaidEarning)
      setUserShillingDetails(userDetails)

      const fathomTokenAddresses = stakingConfigs.fathomTokenAddress
      const fathomTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(fathomTokenAddresses)
      const usdValue = parseFloat(fathomTokenPriceInUSD) * unpaidFathomTokens
      setUserUnpaidCommissionInUSD(usdValue)
      setIsShillingUserDetailsLoading(false)
    } else {
      setUserShillingDetails(null)
      setIsShillingUserDetailsLoading(false)
    }
  }

  const handleClaimCommission = async () => {
    setIsClaimCommissionLoading(true)
    try {
      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        })
        setIsClaimCommissionLoading(false)
        return
      }
      const result = await claimRewards(signer)
      console.log("CLAIM COMMISSION SUCCESS ", result)
      setIsClaimCommissionLoading(false)
      notification['success']({
        message: 'Claim Successes',
        description: 'Commission rewards has been claimed'
      })
    } catch (error) {
      console.log("ERROR while trying to stake fathom tokens ", error)
      notification['error']({
        message: 'Transaction Execute Error',
        description: error
      })
      setIsClaimCommissionLoading(false)
    }
  }


  useEffect(() => {
    getShillingDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])


  return (
    <Card className='fathom-finance-card'>
      <h5 className='text-center primary-text'>Commission</h5>
      <hr />

      <Row>
        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='text-center mt-2'>
          <Card title={<span className='card-title'>$Fathom</span>} className='claim-card'>
            {
              isShillingUserDetailsLoading ? (
                <div className='d-flex justify-content-center'>
                  <Spin />
                </div>
              ) : (
                <NumberFormat
                  style={{ fontSize: '18px' }}
                  value={userShillingDetails ? userShillingDetails?.unpaidEarning : 0}
                  thousandSeparator={true}
                  decimalScale={3}
                  suffix=" Fathom"
                  displayType={'text'}
                />

              )
            }

            <div className='card-icon'>
              <DollarSign size="60" color='rgba(67, 67, 67, 0.5)' />
            </div>
          </Card>
        </Col>

        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='text-center mt-2'>
          <Card title={<span className='card-title'>Value</span>} className='claim-card'>
            {
              isShillingUserDetailsLoading ? (
                <div className='d-flex justify-content-center'>
                  <Spin />
                </div>
              ) : (
                <NumberFormat
                  style={{ fontSize: '18px' }}
                  value={userUnpaidCommissionInUSD}
                  thousandSeparator={true}
                  suffix=" USD"
                  decimalScale={3}
                  displayType={'text'}
                />

              )
            }
            <div className='card-icon'>
              <Coffee size="60" color='rgba(67, 67, 67, 0.5)' />
            </div>
          </Card>
        </Col>

      </Row>

      <Button
        loading={isClaimCommissionLoading}
        disabled={isShillingUserDetailsLoading}
        onClick={handleClaimCommission}
        className='nutgain-primary-button mt-4 col-12'
        type='primary'>Claim</Button>

    </Card>
  )
}

export default Commission