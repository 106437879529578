import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { DataProvider } from './GlobalState';
import LayoutView from './LayoutView';

// rainbowkit
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { bscTestnet, bsc } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

function App() {
  const { chains, provider } = configureChains(
    [bsc],
    [publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: 'Fathome Finance',
    projectId: 'e54ea40b7ce018b75e330a81de5c636a',
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: '#183d99',
          accentColorForeground: 'white',
          borderRadius: 'large',
          fontStack: 'system',
          overlayBlur: 'small',
        })}
      >
        <DataProvider>
          <Router>
            <div className="App">
              <LayoutView />
            </div>
          </Router>
        </DataProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
