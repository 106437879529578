import React from 'react'
import { Card } from 'antd'
import MyWorkSheet from '../MyWorkSheet/MyWorkSheet'
import CommissionEarned from '../CommissionEarned/CommissionEarned'
import { HashScroll } from "react-hash-scroll";

function ReportCard() {
  return (
    <div>
        <Card className='mt-4 transparent'>
          <HashScroll hash="#reportCard" position='center'>
            <h3 className='text-center text-uppercase'>Report Card</h3>
          </HashScroll>
        </Card>

        <MyWorkSheet />
        
        <div className='mt-3'>
            <CommissionEarned />
        </div>
    </div>
  )
}

export default ReportCard