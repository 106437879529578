import { Button, Card, Radio, notification, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap'
import ApproveDetailCard from './ApproveDetailCard'
import { useSelector, useDispatch } from 'react-redux'
import { tokenApproval, getUserBNBBalance, getMaxWalletCount, airDropTokens } from '../../Blockchain/services/util.service'
import { setStepNumber } from '../../Redux/HomeSteps'
import { useAccount, useSigner } from 'wagmi';

function ApproveStep() {

    const { address: account } = useAccount()
    const { data: signer } = useSigner();
    const dispatch = useDispatch()

    const { contractList, totalTokens, selectedToken, selectedTokenBalance, selectedTokenSymbol } = useSelector((state) => state.csvDataList)

    const [isMaxWalletCountLoading, setIsMaxWalletCountLoading] = useState(false)
    const [isTokenApprovalLoading, setIsTokenApprovalLoading] = useState(false)
    const [tokenApproved, setTokenApproved] = useState(false)
    const [hasToApproveTime, setHasToApproveTime] = useState(0)
    const [maxWalletCountForTransaction, setMaxWalletCountForTransaction] = useState(0)
    const [isBnbBalanceLoading, setIsBnbBalanceLoading] = useState(false)
    const [userBnbBalance, setUserBnbBalance] = useState(0)
    const [isAirDropLoading, setIsAirDropLoading] = useState(false)

    const onChange = (e) => {
        console.log(e);
    }

    const handleApprove = async() => {
        try {
          setIsTokenApprovalLoading(true)
          const tokenAddress = selectedToken
          const spender = process.env.REACT_APP_MULTISENDER_CONTRACT_ADDRESS


        const result = await tokenApproval(spender, tokenAddress, totalTokens, signer)
        if (result) {
            setTokenApproved(true)
            setIsTokenApprovalLoading(false)
            notification['success']({
                message: 'Success',
                description:
                    'Tokens has been approved',
            })
            console.log("APPROVAL SUCCESS ", result)
            // dispatch(setStepNumber(2))
        }
  
          
        } catch (error) {
          setIsTokenApprovalLoading(false)
          setTokenApproved(false)
            notification['error']({
                message: 'Transaction Execution Failed',
                description: error,
            })
            console.error("ERROR while trying to approve ", error)
        }
      }

      const handleAirDrop = async() => {
        try {
          setIsAirDropLoading(true)
          const tokenAddress = selectedToken

            const chunkSize = maxWalletCountForTransaction;
        //   const chunkSize = 2
        let metamaskOpened = 0

        for (let i = 0; i < contractList.length; i += chunkSize) {
            const contractListChunked = contractList.slice(i, i + chunkSize);

            const result = await airDropTokens(contractListChunked, tokenAddress, signer)
            if (result) {
              metamaskOpened = metamaskOpened + 1
            //   setMetamaskOpenTime(metamaskOpenTime - 1)
              notification['success']({
                message: `Set ${metamaskOpened} airdroped Complete`,
                description:
                  `You have successfully airdroped set ${metamaskOpened} `,
              });
              console.log("airDropTokens SUCCESS ", result)
              dispatch(setStepNumber(2))
            }
          }
          setIsAirDropLoading(false)
          
        } catch (error) {
          setIsAirDropLoading(false)
          console.log(error);
        }
      }

      useEffect(() => {
        if (contractList && maxWalletCountForTransaction) {
            let numberOfApprovals = 0
            const result = Math.floor(contractList.length / maxWalletCountForTransaction);
            if (contractList.length % maxWalletCountForTransaction !== 0) {
                numberOfApprovals = result + 1
            } else {
                numberOfApprovals = result
            }
            console.log('numberOfApprovals', numberOfApprovals);
            setHasToApproveTime(numberOfApprovals)
        }
    }, [contractList, maxWalletCountForTransaction])

    const fetchUserBnbBalance = async () => {
        setIsBnbBalanceLoading(true)
        try {
            const bnbBalanceResponse = await getUserBNBBalance(account)
            setUserBnbBalance(bnbBalanceResponse)
            setIsBnbBalanceLoading(false)
        } catch (error) {
            setUserBnbBalance(0)
            setIsBnbBalanceLoading(false)
            console.log(error);
            notification['error']({
                message: 'Error',
                description:
                    'Error while fetching BNB balance',
            })
        }
      }

    const fetchMaxWalletCount = async () => {
        setIsMaxWalletCountLoading(true)
        try {
            const walletCountResponse = await getMaxWalletCount(account)
            setMaxWalletCountForTransaction(parseFloat(walletCountResponse))
            setIsMaxWalletCountLoading(false)
        } catch (error) {
            setMaxWalletCountForTransaction(0)
            setIsMaxWalletCountLoading(false)
            console.log(error);
            notification['error']({
                message: 'Error',
                description:
                    'Error while fetching max wallet count',
            })
        }
      }

      useEffect(() => {
        fetchUserBnbBalance()
        fetchMaxWalletCount()
        // eslint-disable-next-line
      }, [account])

    return (
        <div className='mt-4 mb-5'>
            <Card className='fathom-finance-card col-lg-8 mx-auto mt-3'>
                <Row className="mt-4">
                    <Col lg="6" md="6" sm="12">
                        {
                            isMaxWalletCountLoading ? (
                                <div className='d-flex justify-content-center'>
                                        <Spin size="small" />
                                </div>
                            ) : (
                                <ApproveDetailCard 
                                    topic='times airdrop will run'
                                    value={hasToApproveTime}
                                    symbol=''
                                />
                            )
                        }
                    </Col>
                    <Col lg="6" md="6" sm="12" className='mobile-top-margin'>
                        <ApproveDetailCard 
                            topic='Total number of tokens to send'
                            value={totalTokens}
                            symbol={selectedTokenSymbol}
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="6" md="6" sm="12">
                        <ApproveDetailCard 
                            topic={`Your ${selectedTokenSymbol} balance`}
                            value={selectedTokenBalance}
                            symbol={selectedTokenSymbol}
                        />
                    </Col>
                    <Col lg="6" md="6" sm="12" className='mobile-top-margin'>
                        <ApproveDetailCard 
                            topic='Your BNB balance'
                            value={userBnbBalance}
                            symbol='BNB'
                            loading={isBnbBalanceLoading}
                        />
                    </Col>
                </Row>
                <div className='mt-5 mx-2'>
                    <h5>List of recipients</h5>
                </div>
                <div>
                    {contractList.map((item) => (
                        <div className='d-flex justify-content-between align-items-start mx-2 approve-token-list'>
                            <span className='contract-address'>{item.contractAddress}</span>
                            <span className='send-value'>{item.value}</span>
                        </div>
                    ))}
                </div>
                <div className='mt-5 mx-2'>
                    <h5>Amount to approve</h5>
                </div>
                <Row className="mt-4 mx-2">
                    <Radio.Group onChange={onChange} value={1}>
                        <Col lg="12" md="12" sm="12" >
                            <div className=''>
                                <div className="home-inner-card">
                                    <Radio value={1}>
                                        Exact amount to be sent {totalTokens} {selectedTokenSymbol}
                                    </Radio>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" md="12" sm="12">
                            <div className='mt-2'>
                                <div className="home-inner-card">
                                    <Radio value={2}>
                                        Unlimited amount
                                    </Radio>
                                </div>
                            </div>
                        </Col>
                    </Radio.Group>
                </Row>
                <div className="mt-4 mx-1">
                    {
                        !tokenApproved ? (
                            <Button block className="nutgain-primary-button" size="large" onClick={handleApprove} loading={isTokenApprovalLoading} disabled={isMaxWalletCountLoading}>Approve</Button>
                        ) : (
                            <Button block className="nutgain-primary-button" size="large" onClick={handleAirDrop} loading={isAirDropLoading}>Send</Button>
                        )
                    }
                    
                </div>
            </Card>
        </div>
    )
    
}
export default ApproveStep