import { Layout, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import NavBar from './Components/NavBar/NavBar';
import Pages from './Pages/Pages';
import BackgroundVideo from './Components/BackgroundVideo/BackgroundVideo';
import SiderComponent from './Components/SiderComponent/SiderComponent';
import { useSelector } from "react-redux";

// social icons
import discord from './images/social/discord.png'
import facebook from './images/social/facebook.png'
import telegram from './images/social/telegram.png'
import twitter from './images/social/twitter.png'

function LayoutView() {

    const { Content, Footer } = Layout;
    const { margin } = useSelector((state) => state.sideMenu);
    const [marginClass, setMarginClass] = useState('content-page-margin-extended')

    useEffect(() => {
        if (margin) {
            setMarginClass('content-page-margin-extended')
        } else {
            setMarginClass('content-page-margin')
        }
    }, [margin])

    return (
        <Layout className="layout">
            <NavBar />

            <Layout>
                <Layout className='side-menu'>
                    <SiderComponent />

                    <Card className='mt-4 sider-background' style={{position: 'fixed', bottom: '10px', width: '250px'}}>
                        {
                            margin &&
                            <div style={{marginLeft: '25px'}}>
                                <a href='https://discord.com/invite/QmRc7x8DUX' target={'_blank'} rel="noreferrer">
                                    <img src={discord} alt="email" style={{ width: '30px' }} className="social-link hide-on-mobile" />
                                </a>
                                <a href='https://www.facebook.com/Fathom.Finance' target={'_blank'} rel="noreferrer">
                                    <img src={facebook} alt="facebook" style={{ width: '30px' }} className="social-link hide-on-mobile" />
                                </a>
                                <a href='https://t.me/fathomportal' target={'_blank'} rel="noreferrer">
                                    <img src={telegram} alt="telegram" style={{ width: '30px' }} className="social-link hide-on-mobile" />
                                </a>
                                <a href='https://twitter.com/fathomfinance' target={'_blank'} rel="noreferrer">
                                    <img src={twitter} alt="twitter" style={{ width: '30px' }} className="social-link hide-on-mobile" />
                                </a>
                                
                            </div>
                        }
                     </Card>
                </Layout>

                <Layout className={marginClass}>
                    <Content style={{minHeight: '100vh'}} className="site-layout-background">
                        <BackgroundVideo />

                        <div className='content container'>
                            <Pages />
                        </div>
                    </Content>

                    <Footer className="text-center footer">
                        <span className="text-muted">
                            COPYRIGHT © {new Date().getFullYear()} collaborated by <br />
                            <span style={{color: '#00b0f2'}}><a href='https://www.fathom.finance/' target={'_blank'} rel="noreferrer"> Fathom Finance</a></span> and developed by
                            <span style={{color: '#00b0f2'}}><a href='https://perfectustec.com/' target={'_blank'} rel="noreferrer"> perfectus</a></span>. <br />
                            All rights Reserved
                            <br /> 
                        </span>
                    </Footer>
                </Layout>
            </Layout>

        </Layout>
    )
}

export default LayoutView