import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stepNumber: 0
};

export const homeStepsSlice = createSlice({
    name: "homeSteps",
    initialState,
    reducers: {
        setStepNumber: (state, action) => {
            state.stepNumber = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setStepNumber } = homeStepsSlice.actions;

export default homeStepsSlice.reducer;