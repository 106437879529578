import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contractList: [],
    totalTokens: 0,
    selectedToken: '',
    selectedTokenBalance: 0,
    selectedTokenSymbol: '',
    csvCodeValue: ``,
    timesForAirdrop: 1,
    tokenDecimals: 0
};

export const csvDataListSlice = createSlice({
    name: "csvDataList",
    initialState,
    reducers: {
        setContractList: (state, action) => {
            state.contractList = action.payload
        },
        setTotalTokens: (state, action) => {
            state.totalTokens = action.payload
        },
        setSelectedToken: (state, action) => {
            state.selectedToken = action.payload
        },
        setSelectedTokenBalance: (state, action) => {
            state.selectedTokenBalance = action.payload
        },
        setSelectedTokenSymbol: (state, action) => {
            state.selectedTokenSymbol = action.payload
        },
        setCsvCodeValue: (state, action) => {
            state.csvCodeValue = action.payload
        },
        setTimesForAirdrop: (state, action) => {
            state.timesForAirdrop = action.payload
        },
        setSelectedTokenDecimals: (state, action) => {
            state.tokenDecimals = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setContractList, setTotalTokens, setSelectedToken, setSelectedTokenBalance, setSelectedTokenSymbol, setCsvCodeValue, setTimesForAirdrop, setSelectedTokenDecimals } = csvDataListSlice.actions;

export default csvDataListSlice.reducer;