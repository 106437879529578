import React, { useEffect, useState } from 'react'
import { Card, Button, Spin, notification } from 'antd'
import { Col, Row } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { Package, Coffee } from 'react-feather'
import { claimStakingRewards, getClaimableRewards, compoundStakingRewards } from '../../../Blockchain/services/staking.service'
import { stakingConfigs } from '../../../Blockchain/configs/staking.configs'
import { getTokenPriceInUSDByTokenAddress } from '../../../Blockchain/services/util.service'
import { useAccount, useSigner } from 'wagmi';

function Chest() {

  const [userClaimableRewards, setUserClaimableRewards] = useState(0.0)
  const [isUserRewardsLoading, setIsUserRewardsLoading] = useState(false)
  const [userClaimableRewardsValueInUSD, setUserClaimableRewardsValueInUSD] = useState(0.0)

  const [isClaimingRewardsLoading, setIsClaimingRewardsLoading] = useState(false)
  const [isRewardsCompoundingLoading, setIsRewardsCompoundingLoading] = useState(false)
  // eslint-disable-next-line
  const [haveEnoughBalance, setHaveEnoughBalance] = useState(true)

  const { address: account } = useAccount()
  const { data: signer } = useSigner();

  const fetchUserClaimableRewardsAmount = async () => {
    setIsUserRewardsLoading(true)
    const rewardsData = await getClaimableRewards(account)
    const rewardTokenBalance = parseFloat(rewardsData?.claimableRewards)
    setUserClaimableRewards(rewardsData?.claimableRewards)

    const fathomTokenAddresses = stakingConfigs.fathomTokenAddress
    const fathomTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(fathomTokenAddresses)
    const claimableTokenValue = parseFloat(fathomTokenPriceInUSD) * rewardTokenBalance
    setUserClaimableRewardsValueInUSD(claimableTokenValue)
    setIsUserRewardsLoading(false)
  }

  useEffect(() => {
    if (account) {
      fetchUserClaimableRewardsAmount()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const handleClaimRewards = async () => {
    setIsClaimingRewardsLoading(true)
    try {
      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        })
        setIsClaimingRewardsLoading(false)
        return
      }
      if (!(userClaimableRewards > 0)) {
        setHaveEnoughBalance(false)
        notification['info']({
          message: 'Input Validation Error',
          description:
            'You don\'t have enough fathom tokens to claim',
        })
        setIsClaimingRewardsLoading(false)
        return
      }
      setHaveEnoughBalance(true)
      const result = await claimStakingRewards(signer)
      fetchUserClaimableRewardsAmount()
      console.log("CLAIM REWARDS SUCCESS ", result)
      notification['success']({
        message: 'Claim rewards success',
        description:
          'Rewards has been claimed',
      })
      setIsClaimingRewardsLoading(false)

    } catch (error) {
      console.log("ERROR while claiming rewards : ", error)
      notification['error']({
        message: 'Transaction Execute Error',
        description: error
      })
      setIsClaimingRewardsLoading(false)
    }
  }

  const handleCompoundStaking = async () => {
    try {
      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        })
        setIsRewardsCompoundingLoading(false)
        return
      }
      if (!(userClaimableRewards > 0)) {
        setHaveEnoughBalance(false)
        notification['info']({
          message: 'Input Validation Error',
          description:
            'You don\'t have enough fathom tokens to compound',
        })
        setIsRewardsCompoundingLoading(false)
        return
      }

      setHaveEnoughBalance(true)
      const result = await compoundStakingRewards(signer)
      fetchUserClaimableRewardsAmount()
      console.log("COMPOUND REWARDS SUCCESS ", result)
      notification['success']({
        message: 'Compound rewards success',
        description:
          'Rewards  has been Compounded',
      })
      setIsRewardsCompoundingLoading(false)

    } catch (error) {
      console.log("ERROR while compounding rewards : ", error)
      notification['error']({
        message: 'Transaction Execute Error',
        description: error
      })
      setIsRewardsCompoundingLoading(false)
    }
  }

  return (
    <Card className='fathom-finance-card'>
      <h5 className='text-center primary-text'>Chest</h5>
      <hr />

      <Row>
        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='text-center mt-2'>
          <Card title={<span className='card-title'>Treasure</span>} className='fathom-finance-card'>
            {
              isUserRewardsLoading ? (
                <div className='d-flex justify-content-center'>
                  <Spin />
                </div>) : (
                <NumberFormat
                  style={{ fontSize: '18px' }}
                  value={userClaimableRewards}
                  thousandSeparator={true}
                  decimalScale={3}
                  suffix=" Fathom"
                  displayType={'text'}
                />
              )
            }
            <div className='card-icon'>
              <Package size="60" color='rgba(67, 67, 67, 0.5)' />
            </div>
          </Card>
        </Col>

        <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12" className='text-center mt-2'>
          <Card title={<span className='card-title'>Value</span>} className='fathom-finance-card'>
            {
              isUserRewardsLoading ? (
                <div className='d-flex justify-content-center'>
                  <Spin />
                </div>) : (
                <NumberFormat
                  style={{ fontSize: '18px' }}
                  value={userClaimableRewardsValueInUSD}
                  thousandSeparator={true}
                  decimalScale={3}
                  suffix=" USD"
                  displayType={'text'}
                />
              )
            }
            <div className='card-icon'>
              <Coffee size="60" color='rgba(67, 67, 67, 0.5)' />
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6" className='text-center mt-2'>
          <Button
            disabled={isUserRewardsLoading}
            onClick={handleClaimRewards}
            loading={isClaimingRewardsLoading}
            className='nutgain-primary-button mt-4 col-12'
            type='primary'>Claim</Button>
        </Col>

        <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6" className='text-center mt-2'>
          <Button
            disabled={isUserRewardsLoading}
            onClick={handleCompoundStaking}
            loading={isRewardsCompoundingLoading}
            className='nutgain-primary-button mt-4 col-12'
            type='primary'>Compound</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default Chest